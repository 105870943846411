import React, { useContext, useState, useEffect } from 'react'
import { AgencyContext } from '../../../Context/Agency/Index'
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Decrypt_Id_Name, DecryptedList, EncryptedList, base64ToString, stringToBase64 } from '../../Common/Utility';
import { get_LocalStoreData } from '../../../redux/actions/Agency';
import { GetData_MissingPerson } from '../../../redux/actions/MissingPersonAction';
import { get_FieldInterview_Data } from '../../../redux/actions/FieldInterviewAction';

const IncSidebar = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const incidentId = useSelector((state) => state.Agency.incidentId);
    const incidentNum = useSelector((state) => state.Agency.incidentNum);
    const missingPerData = useSelector((state) => state.MissingPerson.MissingPersonAllData);
    const FieldInterviewData = useSelector((state) => state.FieldInterview.FieldInterviewData);


    const { changesStatus, incidentCount, arrestData, updateCount, setUpdateCount, setIncStatus, get_Incident_Count, setActiveArrest, activeArrest } = useContext(AgencyContext);
    const [plusMinus, setPlusMinus] = useState(false)
    const [expandList, setExpandList] = useState()
    const [plusMinus1, setPlusMinus1] = useState(false)
    const [plusMinus2, setPlusMinus2] = useState(false)
    const [plusMinus3, setPlusMinus3] = useState(false)
    const [plusMinus4, setPlusMinus4] = useState(false)
    const [plusMinus5, setPlusMinus5] = useState(false)
    const [incidentID, setIncidentID] = useState('');
    const [agencyName, setAgencyName] = useState('');
    const [incidentNumber, setIncidentNumber] = useState('');
    // const [activeArrest, setActiveArrest] = useState(null);
    const useQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return {
            get: (param) => params.get(param)
        };
    };

    let DecEIncID = 0;
    const query = useQuery();
    let openPage = query?.get('page');
    var ModNo = query?.get('ModNo');
    var ArrNo = query?.get("ArrNo");
    var IncID = query?.get("IncId");
    var ArrestId = query?.get("ArrestId");
    var ArrestSta = query?.get('ArrestSta');
    var IncNo = query?.get("IncNo");
    var IncSta = query?.get("IncSta");
    var ArrNo = query?.get("ArrNo");
    var Name = query?.get("Name");
    var MissPerId = query?.get("MissPerID");
    var MissPerSta = query?.get('MissPerSta');
    var FiID = query?.get("FiID");
    var ChargeSta = query?.get("ChargeSta");

    if (!IncID) { IncID = 0; }
    else { IncID = IncID; DecEIncID = parseInt(base64ToString(IncID)); }

    if (!IncNo) IncNo = '';
    else IncNo = IncNo;

    if (!ArrestId) ArrestId = 0;
    else ArrestId = ArrestId;

    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);

    useEffect(() => {
        if (IncID && IncID != 0) {
            setIncidentID(IncID);
            if (DecEIncID) {
                get_Incident_Count(DecEIncID)
            }
        }
        else {
            setIncidentID('');
            // if (missingPerData?.length > 0) { dispatch(GetData_MissingPerson('')) };
            setExpandList('')
        }
    }, [IncID]);

    useEffect(() => {
        if (localStoreData) {
            setAgencyName(localStoreData?.Agency_Name);
        }
    }, [localStoreData]);

    const callUtilityModules = (type, val) => {
        if (type === 'List') {
            setPlusMinus1(!plusMinus1)
            setExpandList(expandList === val ? '' : val)
        }
        // if (type === 'Arrest') {
        //     setPlusMinus2(!plusMinus2)
        //     setExpandList(expandList === val ? '' : val)
        // }
        if (type === 'Warrant') {
            setPlusMinus5(!plusMinus5)
            setExpandList(expandList === val ? '' : val)
        }
        if (type === 'Vehicle') {
            setPlusMinus3(!plusMinus3)
            setExpandList(expandList === val ? '' : val)

        }
        if (type === 'Property') {
            setPlusMinus4(!plusMinus4)
            setExpandList(expandList === val ? '' : val)
        }
        if (type === 'Table') {
            setPlusMinus(!plusMinus); setExpandList(expandList === val ? '' : val);
        }
    }

    return (
        <>
            {
                openPage ?
                    <>
                        <span className='agency-sidebar'>
                            <i className="fa fa-chevron-right " style={{ fontSize: '14px' }}></i>
                            <span className="ml-2" data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''}>
                                {
                                    openPage === "MST-Property-Dash" ? `PRO-NO-${ModNo ? ModNo : ""}`
                                        :
                                        openPage === "MST-Vehicle-Dash" ? `VIC-NO-${ModNo ? ModNo : ""}`
                                            :
                                            openPage === "MST-Arrest-Dash" ? `ARST-NO-${ArrNo ? ArrNo : ""}`
                                                :
                                                openPage === "MST-Name-Dash" ? `NAME-NO-${ModNo ? ModNo : ""}` : ''
                                }
                                <p className='agency-name-sidebar'>{agencyName ? agencyName : ''}</p>
                            </span>
                        </span>
                    </>
                    :
                    <>
                        <Link to={changesStatus ? `/Inc-Home?IncId=${IncID ? IncID : ''}&IncNo=${IncNo}&IncSta=${IncSta}` : `/Inc-Home?IncId=${IncID ? IncID : ''}&IncNo=${IncNo}&IncSta=${IncSta}`} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} className='agency-sidebar' onClick={() => {
                            setActiveArrest(false);
                        }}>
                            <i className="fa fa-chevron-right " style={{ fontSize: '14px' }}></i>
                            <span className="ml-2" data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''}>
                                INC-{IncNo ? IncNo : ""}
                                {/* INC-{!IncNo ? " " : IncNo} */}
                                <p className='agency-name-sidebar'>{agencyName ? agencyName : ''}</p>
                            </span>
                        </Link>
                        {/* Arrest */}
                        <li>
                            <div className="col-12 " style={{ pointerEvents: (IncSta === true || IncSta === 'true') ? '' : 'none', opacity: (IncSta === true || IncSta === 'true') ? '' : '0.5' }}>
                                <div className="row">
                                    <div className="col-10">
                                        <Link
                                            to={changesStatus ? `/Arrest-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&ArrestId=${''}&ArrestSta=${false}` : `/Arrest-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&ArrestId=${''}&ArrestSta=${false}`}
                                            data-toggle={changesStatus ? "modal" : ""}
                                            data-target={changesStatus ? "#SaveModal" : ''}
                                            className="" aria-expanded={plusMinus2}
                                            onClick={() => {
                                                setActiveArrest(false);
                                                if (IncID) {
                                                    callUtilityModules('List', 'Master Table2');
                                                }
                                            }}

                                        >
                                            {
                                                expandList === 'Master Table2' ?
                                                    <i className="fa fa-caret-down arrow-change"></i>
                                                    :
                                                    <i className="fa fa-caret-right arrow-change"></i>
                                            }
                                            <span>
                                                Arrest {`${incidentCount[0]?.ArrestCount > 0 ? '(' + incidentCount[0]?.ArrestCount + ')' : ''}`}
                                            </span>
                                        </Link>
                                    </div>
                                    <div className="col-2">
                                        <Link
                                            to={changesStatus ? `/Arrest-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&ArrestId=${''}&ArrestSta=${false}` : `/Arrest-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&ArrestId=${''}&ArrestSta=${false}`}>

                                            <span

                                                className='inc-plus '
                                                to={changesStatus ? '#' : `/Arrest-Home?IncId=${IncID}&ArrestId=${''}&ArrestSta=${false}`}
                                                data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''}
                                                style={{ marginLeft: '-10px', cursor: 'pointer' }}
                                                onKeyDown={''}
                                            >
                                                <i
                                                    onClick={() => {
                                                        navigate(`/Arrest-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&ArrestId=${('')}&ArrestSta=${false}&ChargeSta=${false}`)
                                                        setActiveArrest(false);
                                                    }}
                                                    className="fa fa-plus btn btn-sm bg-line text-white" style={{ fontSize: '10px' }}>
                                                </i>
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <ul id="menu" role="menu" aria-expanded={expandList === 'Master Table2'} className={`${expandList === 'Master Table2' ? 'collapse in' : 'collapse'}`} style={{ marginLeft: '-23px', maxHeight: 'auto' }}>
                                {
                                    arrestData?.map((val) => (
                                        // <li className="ml-3 p-0" key={val?.ArrestID}>
                                        //     <Link
                                        //         to={`/Arrest-Home?IncId=${IncID}&IncNo=${IncNo}&ArrNo=${val?.ArrestNumber}&Name=${val?.Arrestee_Name}&IncSta=${IncSta}&ArrestId=${stringToBase64(val?.ArrestID)}&ArrestSta=${true}`}
                                        //         onClick={() => {
                                        //             navigate(`/Arrest-Home?IncId=${IncID}&IncNo=${IncNo}&ArrNo=${val?.ArrestNumber}&Name=${val?.Arrestee_Name}&IncSta=${IncSta}&ArrestId=${stringToBase64(val?.ArrestID)}&ArrestSta=${true}`)
                                        //             setIncStatus(true);
                                        //             setUpdateCount(updateCount + 1)
                                        //         }}
                                        //     >
                                        //         <i className=" fa fa-arrow-right" ></i>
                                        //         <span className="m-0 p-0">{val?.ArrestNumber}</span>
                                        //     </Link>
                                        // </li>
                                        <li className={`ml-3 p-0 ${activeArrest === val?.ArrestID ? 'activeSide' : ''}`} key={val?.ArrestID}>
                                            <Link
                                                to={`/Arrest-Home?IncId=${IncID}&IncNo=${IncNo}&ArrNo=${val?.ArrestNumber}&Name=${val?.Arrestee_Name}&IncSta=${IncSta}&ArrestId=${stringToBase64(val?.ArrestID)}&ArrestSta=${true}&ChargeSta=${false}`}
                                                onClick={() => {
                                                    setActiveArrest(val?.ArrestID);
                                                    navigate(`/Arrest-Home?IncId=${IncID}&IncNo=${IncNo}&ArrNo=${val?.ArrestNumber}&Name=${val?.Arrestee_Name}&IncSta=${IncSta}&ArrestId=${stringToBase64(val?.ArrestID)}&ArrestSta=${true}`);
                                                    setIncStatus(true);
                                                    setUpdateCount(updateCount + 1);
                                                }}
                                            >
                                                <i className=" fa fa-arrow-right" ></i>
                                                <span className="m-0 p-0">{val?.ArrestNumber}</span>
                                            </Link>
                                        </li>
                                    ))
                                }
                            </ul>
                        </li>
                        {/* Missing Person */}
                        <li>
                            <div className="col-12 " style={{ pointerEvents: (IncSta === true || IncSta === 'true') ? '' : 'none', opacity: (IncSta === true || IncSta === 'true') ? '' : '0.5' }}>
                                <div className="row">
                                    <div className="col-10">
                                        <Link
                                            to={changesStatus ? `/Missing-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&MissPerID=${''}&MissPerSta=${false}&MissPerPg=home` : `/Missing-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&MissPerID=${''}&MissPerSta=${false}&MissPerPg=home`}
                                            data-toggle={changesStatus ? "modal" : ""}
                                            data-target={changesStatus ? "#SaveModal" : ''}
                                            className="" aria-expanded={plusMinus2}
                                            // onClick={() =>
                                            //     IncID ? callUtilityModules('List', 'Master Table4') : ''}
                                            onClick={() => {
                                                setActiveArrest(false);
                                                if (IncID) {
                                                    callUtilityModules('List', 'Master Table4');
                                                }
                                            }}
                                        >
                                            {
                                                expandList === 'Master Table4' ?
                                                    <i className="fa fa-caret-down arrow-change"></i>
                                                    :
                                                    <i className="fa fa-caret-right arrow-change"></i>
                                            }
                                            <span>
                                                Missing Person{`${incidentCount[0]?.MissingPersonCount > 0 ? '(' + incidentCount[0]?.MissingPersonCount + ')' : ''}`}
                                            </span>
                                        </Link>
                                    </div>
                                    <div className="col-2">
                                        <Link
                                            to={changesStatus ? `/Missing-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&MissPerID=${''}&MissPerSta=${false}` : `/Missing-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&MissPerID=${''}&MissPerSta=${false}`}>

                                            <span
                                                className='inc-plus '
                                                to={changesStatus ? '#' : `/Missing-Home?IncId=${IncID}&MissPerID=${''}&MissPerSta=${false}`}
                                                data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''}
                                                style={{ marginLeft: '-10px', cursor: 'pointer' }}
                                                onKeyDown={''}

                                            >
                                                <i
                                                    onClick={() => {
                                                        setActiveArrest(false);
                                                        navigate(`/Missing-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&MissPerID=${('')}&MissPerSta=${false}&ChargeSta=${false}`)
                                                    }}
                                                    className="fa fa-plus btn btn-sm bg-line text-white" style={{ fontSize: '10px' }}>
                                                </i>
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <ul id="menu" role="menu" aria-expanded={expandList === 'Master Table4'} className={`${expandList === 'Master Table4' ? 'collapse in' : 'collapse'}`} style={{ marginLeft: '-23px', maxHeight: 'auto' }}>
                                {
                                    missingPerData?.map((val) => (
                                        <li className={`ml-3 p-0 ${activeArrest === val?.MissingPersonID ? 'activeSide' : ''}`} key={val?.MissingPersonID}>
                                            <Link
                                                to={`/Missing-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&MissPerID=${stringToBase64(val?.MissingPersonID)}&MissPerSta=${true}&MissPerPg=home`}
                                                onClick={() => {
                                                    navigate(`/Missing-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&MissPerID=${stringToBase64(val?.MissingPersonID)}&MissPerSta=${true}&MissPerPg=home`)
                                                    setIncStatus(true);
                                                    setUpdateCount(updateCount + 1)
                                                    setActiveArrest(val?.MissingPersonID);
                                                }}
                                            >
                                                <i className=" fa fa-arrow-right" ></i>
                                                <span className="m-0 p-0">{val?.MissingPersonNumber}</span>
                                            </Link>
                                        </li>
                                    ))
                                }
                            </ul>
                        </li>

                        {/* { /field-interview'}} */}
                        {/* <li>
                            <div className="col-12 " style={{ pointerEvents: (IncSta === true || IncSta === 'true') ? '' : 'none', opacity: (IncSta === true || IncSta === 'true') ? '' : '0.5' }}>
                                <div className="row">
                                    <div className="col-10">
                                        <Link
                                            to={changesStatus ? `/field-interview?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&FieldID=${''}&Fieldst=${false}` : `/field-interview?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&FieldID=${''}&Fieldst=${false}`}
                                            data-toggle={changesStatus ? "modal" : ""}
                                            data-target={changesStatus ? "#SaveModal" : ''}
                                            className="" aria-expanded={plusMinus3}
                                            onClick={() => {
                                                setActiveArrest(false);
                                                if (IncID) {
                                                    callUtilityModules('List', 'Master Table5');
                                                }
                                            }}
                                        >
                                            {
                                                expandList === 'Master Table5' ?
                                                    <i className="fa fa-caret-down arrow-change"></i>
                                                    :
                                                    <i className="fa fa-caret-right arrow-change"></i>
                                            }
                                            <span>
                                                Field Interview
                                            </span>
                                        </Link>
                                    </div>
                                    <div className="col-2">
                                        <Link
                                            to={changesStatus ? `/field-interview?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&FieldID=${''}&Fieldst=${false}` : `/field-interview?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&FieldID=${''}&Fieldst=${false}`}
                                        >
                                            <span
                                                className='inc-plus '
                                                // to={'/field-interview'}
                                                to={changesStatus ? '#' : `/field-interview?IncId=${IncID}&FiID=${''}&FiSta=${false}`}
                                                data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''}
                                                style={{ marginLeft: '-10px', cursor: 'pointer' }}
                                                onKeyDown={''}
                                            >
                                                <i
                                                    onClick={() => {
                                                        // setActiveArrest(false);
                                                        // navigate(`/field-interview?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&MissPerID=${('')}&MissPerSta=${false}&ChargeSta=${false}`)
                                                    }}
                                                    className="fa fa-plus btn btn-sm bg-line text-white" style={{ fontSize: '10px' }}>
                                                </i>
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <ul id="menu" role="menu" aria-expanded={expandList === 'Master Table5'} className={`${expandList === 'Master Table5' ? 'collapse in' : 'collapse'}`} style={{ marginLeft: '-23px', maxHeight: 'auto' }}>
                                {
                                    FieldInterviewData?.map((val) => (
                                        <li className={`ml-3 p-0 ${activeArrest === val?.FieldInterviewID ? 'activeSide' : ''}`} key={val?.FieldInterviewID}>
                                            <Link
                                                // to={'/field-interview'}
                                                to={`/field-interview?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&FieldID=${stringToBase64(val?.FieldInterviewID)}&Fieldst=${true}`}
                                                onClick={() => {
                                                    navigate(`/field-interview?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&FieldID=${stringToBase64(val?.FieldInterviewID)}&Fieldst=${true}`)
                                                    setIncStatus(true);
                                                    setUpdateCount(updateCount + 1);
                                                    setActiveArrest(val?.FieldInterviewID);
                                                }}
                                            >
                                                <i className=" fa fa-arrow-right" ></i>
                                                <span className="m-0 p-0">{val?.FINumber}</span>
                                            </Link>
                                        </li>
                                    ))
                                }
                            </ul>
                        </li> */}
                        {/* Search */}
                        <li>
                            <div className="col-12 " style={{ pointerEvents: (IncSta === true || IncSta === 'true') ? '' : 'none', opacity: (IncSta === true || IncSta === 'true') ? '' : '0.5' }}>
                                <div className="row">
                                    <div className="col-10">
                                        <Link
                                            to={changesStatus ? `/incident-advanceSearch?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}` : `/incident-advanceSearch?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}`}
                                            // to={changesStatus ? "/incident-advanceSearch?Inc-Home?IncId=0&IncNo=&IncSta=false" : "/incident-advanceSearch?Inc-Home?IncId=0&IncNo=&IncSta=false"}
                                            data-toggle={changesStatus ? "modal" : ""}
                                            data-target={changesStatus ? "#SaveModal" : ''}
                                            className=""
                                            aria-expanded={plusMinus2}
                                            onClick={() => incidentID ? callUtilityModules('List', 'Master Table3') : ''}
                                        >
                                            {expandList === 'Master Table3' ? <i className="fa fa-caret-down arrow-change"></i> : <i className="fa fa-caret-right arrow-change"></i>}
                                            <span>Search </span>
                                        </Link>
                                    </div>
                                    <div className="col-2">
                                        <Link
                                            to={changesStatus ? `/incident-advanceSearch?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}` : `/incident-advanceSearch?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}`}
                                        // to={changesStatus ? "/incident-advanceSearch?Inc-Home?IncId=0&IncNo=&IncSta=false" : "/incident-advanceSearch?Inc-Home?IncId=0&IncNo=&IncSta=false"}
                                        >
                                            <span
                                                className='inc-plus'
                                                data-toggle={changesStatus ? "modal" : ""}
                                                data-target={changesStatus ? "#SaveModal" : ''}
                                                style={{ marginLeft: '-10px', cursor: 'pointer' }}
                                                onKeyDown={''}
                                            >
                                                <i className="fa fa-search btn btn-sm bg-line text-white" style={{ fontSize: '10px' }}></i>
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </>
            }
        </>
    )
}

export default IncSidebar