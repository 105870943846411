import React, { memo, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { getShowingDateText, tableCustomStyles } from "../../Components/Common/Utility";
import Select from "react-select";
import { colorLessStyle_Select } from "../Utility/CustomStylesForReact";
import useObjState from "../../CADHook/useObjState";
import MasterTableListServices from "../../CADServices/APIs/masterTableList";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { compareStrings, dropDownDataModel } from "../../CADUtils/functions/common";
import BoloServices from "../../CADServices/APIs/bolo";
import Tooltip from "../Common/Tooltip";
import DatePicker from "react-datepicker";
import ViewImageModal from "../ViewImageModal/ViewImageModal";


const BoloSearchModal = (props) => {
    const { openBoloSearchModal, setOpenBoloSearchModal } = props;
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const [loginAgencyID, setLoginAgencyID] = useState('');
    const [boloDisposition, setBoloDisposition] = useState([]);
    const [typeOFBOLO, setTypeOFBOLO] = useState([]);
    const [prioritiesDropDown, setPrioritiesDropDown] = useState([]);
    const [boloSearchList, setBoloSearchList] = useState([]);
    const [primaryOfficerDropDown, setPrimaryOfficerDropDown] = useState([])
    const [zoneDropDown, setZoneDropDown] = useState([])
    const [imageModalStatus, setImageModalStatus] = useState(false);
    const [viewImg, setViewImg] = useState({})

    const [
        boloSearchState,
        setBoloSearchState,
        handleBoloSearchState,
        clearBoloSearchState,
    ] = useObjState({
        TypeOfBolo: "",
        BOLODisposition: "",
        Zone: "",
        Priority: "",
        startDate: "",
        endDate: "",
        dispatcher: "",
        Message: "",
        isClosed: false,
    });

    const getBoloDispositionKey = `/CAD/Monitor/MasterBoloDispositionGet/${parseInt(1)}`;
    const { data: getBoloDispositionData, isSuccess: isFetchBoloDisposition } = useQuery(
        [getBoloDispositionKey, {
            "IsActive": 1,
        },],
        MasterTableListServices.getBoloDisposition,
        {
            refetchOnWindowFocus: false,
            enabled: openBoloSearchModal,
        }
    )

    const getBoloTypeKey = `/CAD/MasterBoloType/GetBoloType/${parseInt(1)}`;
    const { data: getBoloTypeData, isSuccess: isFetchBoloType } = useQuery(
        [getBoloTypeKey, {
            "Action": "GET",
            "IsActive": 1,
            "AgencyID": loginAgencyID

        },],
        MasterTableListServices.getBoloType,
        {
            refetchOnWindowFocus: false,
            enabled: openBoloSearchModal,
        }
    );
    const getPriorityKey = `/CAD/MasterPriority/GetMasterPriority`;
    const { data: prioritiesData, isSuccess: isFetchPrioritiesData } = useQuery(
        [getPriorityKey, {
            IsActive: 1,
            AgencyID: loginAgencyID,
        }],
        MasterTableListServices.getMasterPriority,
        {
            refetchOnWindowFocus: false,
            retry: 0,
            enabled: !!loginAgencyID,
        }
    );

    const getZoneKey = `/CAD/GeoPetrolZone/GetData_Zone`;
    const { data: getZoneData, isSuccess: isFetchGeoZoneData } = useQuery(
        [getZoneKey, { IsActive: 1 },],
        MasterTableListServices.getDataZone,
        {
            refetchOnWindowFocus: false,

        }
    );
    const GetPrimaryOfficerKey = `CAD/MasterPrimaryOfficer/PrimaryOfficer`;
    const { data: getPrimaryOfficerData, isSuccess: isFetchPrimaryOfficers } = useQuery(
        [GetPrimaryOfficerKey, { AgencyID: loginAgencyID },],
        MasterTableListServices.getPrimaryOfficer,
        {
            refetchOnWindowFocus: false,
            enabled: !!loginAgencyID
        }
    );

    useEffect(() => {
        if (getPrimaryOfficerData && isFetchPrimaryOfficers) {
            const data = JSON.parse(getPrimaryOfficerData?.data?.data);
            setPrimaryOfficerDropDown(data?.Table || [])
        }
    }, [getPrimaryOfficerData, isFetchPrimaryOfficers])

    useEffect(() => {
        if (localStoreData) {
            setLoginAgencyID(localStoreData?.AgencyID);

        }
    }, [localStoreData]);

    useEffect(() => {
        if (isFetchPrioritiesData && prioritiesData) {
            const data = JSON.parse(prioritiesData?.data?.data);
            setPrioritiesDropDown(data?.Table);
        }
    }, [isFetchPrioritiesData, prioritiesData])

    useEffect(() => {
        if (isFetchGeoZoneData && getZoneData) {
            const data = JSON.parse(getZoneData?.data?.data || []);
            setZoneDropDown(dropDownDataModel(data?.Table, "ZoneID", "ZoneDescription"));
        }
    }, [isFetchGeoZoneData, getZoneData]);

    useEffect(() => {
        if (getBoloTypeData && isFetchBoloType) {
            const data = JSON.parse(getBoloTypeData?.data?.data);
            setTypeOFBOLO(data?.Table)
        } else {
            setTypeOFBOLO([])
        }
    }, [getBoloTypeData, isFetchBoloType])

    useEffect(() => {
        if (getBoloDispositionData && isFetchBoloDisposition) {
            const data = JSON.parse(getBoloDispositionData?.data?.data);
            setBoloDisposition(data?.Table)
        } else {
            setBoloDisposition([])
        }
    }, [getBoloDispositionData, isFetchBoloDisposition])

    const handleActionClick = (boloID) => {
        const baseUrl = window.location.href.split('?')[0];
        const params = new URLSearchParams({
            boloID: boloID,
        }).toString();
        const newUrl = `${baseUrl}?${params}`;
        window.history.pushState(null, '', newUrl);
        setOpenBoloSearchModal(false);
        clearBoloSearchState();
        setBoloSearchList();
    };

    async function viewBoloImage(data) {
        setImageModalStatus(true);
        if (data?.BoloID) {
            const payload = {
                "IsActive": true,
                "BoloId": data?.BoloID
            }
            const response = await BoloServices.getBoloDoc(payload);
            if (response?.data?.success) {
                const parsedData = JSON.parse(response?.data?.data);
                const viewImgData = parsedData?.Table;
                setViewImg(viewImgData)
            }
        }
    }

    const columns = [
        {
            name: "View",
            selector: (row) => {
                return <div data-toggle="modal" data-target="#ViewImageModal" onClick={() => { viewBoloImage(row) }} style={{ cursor: "pointer" }}>
                    <i className="fa fa-eye"></i>
                </div>;
            },
            width: "68px",
        },
        {
            name: "Action",
            cell: (row) => (
                <i
                    className="fa fa-edit"
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleActionClick(row.BoloID)}
                ></i>
            ),
            sortable: true,
            width: '100px',
        },
        {
            name: "Date/Time",
            selector: (row) => (row.CreatedDtTm ? getShowingDateText(row.CreatedDtTm) : ""),
            sortable: true,
            sortFunction: (rowA, rowB) => compareStrings(rowA.CreatedDtTm, rowB.CreatedDtTm),
            width: "160px",
        },
        {
            name: "Created By",
            selector: (row) => (row.FullName ? row.FullName : ""),
            sortable: true,
            sortFunction: (rowA, rowB) => compareStrings(rowA.FullName, rowB.FullName),
            width: "160px",
        },
        {
            name: "Type",
            selector: (row) => (row.DispositionCode ? row.DispositionCode : ""),
            sortable: true,
            sortFunction: (rowA, rowB) => compareStrings(rowA.DispositionCode, rowB.DispositionCode),
            width: "80px",
        },
        {
            name: "Disposition",
            selector: (row) => (row.Description ? row.Description : ""),
            sortable: true,
            sortFunction: (rowA, rowB) => compareStrings(rowA.Description, rowB.Description),
            width: "140px",
        },
        {
            name: "Priority",
            selector: (row) => (row.Priority ? row.Priority : ""),
            sortable: true,
            sortFunction: (rowA, rowB) => compareStrings(rowA.Priority, rowB.Priority),
            width: "120px",
        },
        {
            name: "CAD Event#",
            selector: (row) => (row.IncidentNumber ? row.IncidentNumber : ""),
            sortable: true,
            sortFunction: (rowA, rowB) => compareStrings(rowA.IncidentNumber, rowB.IncidentNumber),
            width: "120px",
        },
        {
            name: "Message",
            selector: (row) => (row.Message ? row.Message : ""),
            sortable: true,
            sortFunction: (rowA, rowB) => compareStrings(rowA.Message, rowB.Message),
            cell: (row) => (
                <Tooltip text={row?.Message || ''} maxLength={30} />
            ),
            width: "300px",
        },

    ];


    const contactList = [];

    async function handleSearch() {
        const data = {
            BoloTypeId: boloSearchState?.TypeOfBolo,
            BoloDispositionId: boloSearchState?.BOLODisposition,
            ZoneId: boloSearchState?.Zone,
            PriorityId: boloSearchState?.Priority,
            StartDate: boloSearchState?.startDate,
            EndDate: boloSearchState?.endDate,
            DispatcherId: boloSearchState?.dispatcher,
            Message: boloSearchState?.Message,
            IsArchived: boloSearchState?.isClosed ? 1 : 0,
            AgencyID: loginAgencyID
        }
        const response = await BoloServices.searchBolo(data);
        if (response?.status === 200) {
            // refetch();
            const data = JSON.parse(response?.data?.data);
            setBoloSearchList(data?.Table)
        }
    }

    const isBoloSearchStateEmpty = (state) => {
        return Object.values(state).every(
            (value) => value === "" || value === null || value === undefined || value === false
        );
    };

    return (
        <>
            <>
                {openBoloSearchModal ? (
                    <dialog
                        className="modal fade modal-cad"
                        style={{ background: "rgba(0,0,0, 0.5)", zIndex: "200" }}
                        id="BoloSearchModal"
                        tabIndex="-1"
                        aria-hidden="true"
                        data-backdrop="false"
                    >
                        <div className="modal-dialog modal-dialog-centered modal-xl">
                            <div className="modal-content modal-content-cad">
                                <div className="modal-body">
                                    {/* Modal Header */}
                                    <div className="row pb-2">
                                        <div className="col-12 ">
                                            <div className="py-0 px-2 d-flex justify-content-between align-items-center">
                                                <p
                                                    className="p-0 m-0 font-weight-medium"
                                                    style={{
                                                        fontSize: 18,
                                                        fontWeight: 500,
                                                        letterSpacing: 0.5,
                                                    }}
                                                >
                                                    Search
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Form Area */}
                                    <div className="m-1">
                                        <fieldset style={{ border: "1px solid gray" }}>
                                            <div className="tab-form-container">
                                                <div className="tab-form-row">
                                                    <div className="col-1 d-flex justify-content-end">
                                                        <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">Type of BOLO</label>
                                                    </div>
                                                    <div className="col-4">
                                                        <Select
                                                            styles={colorLessStyle_Select}
                                                            placeholder="Select"
                                                            isSearchable
                                                            options={typeOFBOLO}
                                                            isClearable
                                                            value={boloSearchState?.TypeOfBolo ? typeOFBOLO?.find((i) => i?.BoloTypeID == boloSearchState?.TypeOfBolo) : ""}
                                                            getOptionLabel={(v) => v?.Description}
                                                            getOptionValue={(v) => v?.BoloTypeID}
                                                            onChange={(e) => handleBoloSearchState("TypeOfBolo", e?.BoloTypeID)}
                                                        />
                                                    </div>
                                                    <div className="col-1 d-flex justify-content-end">
                                                        <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">Bolo Disposition</label>
                                                    </div>
                                                    <div className="col-4">
                                                        <Select
                                                            styles={colorLessStyle_Select}
                                                            placeholder="Select"
                                                            isSearchable
                                                            getOptionLabel={(v) => `${v?.DispositionCode} | ${v?.Description}`}
                                                            getOptionValue={(v) => v?.DispositionCode}
                                                            formatOptionLabel={(option, { context }) => {
                                                                return context === 'menu'
                                                                    ? `${option?.DispositionCode} | ${option?.Description}`
                                                                    : option?.DispositionCode;
                                                            }}
                                                            isClearable
                                                            maxMenuHeight={180}
                                                            onInputChange={(inputValue, actionMeta) => {
                                                                if (inputValue.length > 12) {
                                                                    return inputValue.slice(0, 12);
                                                                }
                                                                return inputValue;
                                                            }}
                                                            value={boloSearchState?.BOLODisposition ? boloDisposition?.find((i) => i?.BoloDispositionID === boloSearchState?.BOLODisposition) : ""}
                                                            onChange={(e) => handleBoloSearchState("BOLODisposition", e?.BoloDispositionID)}
                                                            options={boloDisposition}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="tab-form-row">
                                                    <div className="col-1 d-flex justify-content-end">
                                                        <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">Zone</label>
                                                    </div>
                                                    <div className="col-4">
                                                        <Select
                                                            styles={colorLessStyle_Select}
                                                            placeholder="Select"
                                                            isSearchable
                                                            options={zoneDropDown}
                                                            isClearable
                                                            value={zoneDropDown?.find((i) => i?.value === boloSearchState?.Zone)}
                                                            onChange={(e) => handleBoloSearchState("Zone", e?.value)}
                                                        />
                                                    </div>
                                                    <div className="col-1 d-flex justify-content-end">
                                                        <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">Priority</label>
                                                    </div>
                                                    <div className="col-4">
                                                        <Select
                                                            styles={colorLessStyle_Select}
                                                            placeholder="Select"
                                                            isSearchable
                                                            isClearable
                                                            options={prioritiesDropDown}
                                                            getOptionLabel={(v) => `${v?.PriorityCode} | ${v?.Description}`}
                                                            getOptionValue={(v) => v?.PriorityCode}
                                                            formatOptionLabel={(option, { context }) => {
                                                                return context === 'menu'
                                                                    ? `${option?.PriorityCode} | ${option?.Description}`
                                                                    : option?.PriorityCode;
                                                            }}
                                                            className="w-100"
                                                            name="Priority"
                                                            value={boloSearchState.Priority ? prioritiesDropDown?.find((i) => i?.PriorityID === boloSearchState.Priority) : ""}
                                                            onChange={(e) => {
                                                                handleBoloSearchState("Priority", e?.PriorityID)
                                                            }}
                                                            onInputChange={(inputValue, actionMeta) => {
                                                                if (inputValue.length > 12) {
                                                                    return inputValue.slice(0, 12);
                                                                }
                                                                return inputValue;
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="tab-form-row">
                                                    <div className="col-1 d-flex justify-content-end">
                                                        <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">Start Date/Time</label>
                                                    </div>
                                                    <div className="col-4 w-100 cad-DatePicker">
                                                        <DatePicker
                                                            name='startDate'
                                                            id='startDate'
                                                            onChange={(v) => handleBoloSearchState("startDate", v)}
                                                            selected={boloSearchState?.startDate || ""}
                                                            dateFormat="MM/dd/yyyy"
                                                            isClearable={!!boloSearchState?.startDate}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            autoComplete="off"
                                                            placeholderText="Select Start Date..."
                                                            maxDate={boloSearchState?.endDate || null}
                                                        />
                                                    </div>
                                                    <div className="col-1 d-flex justify-content-end">
                                                        <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">End Date/Time</label>
                                                    </div>
                                                    <div className="col-4 w-100 cad-DatePicker">
                                                        <DatePicker
                                                            name='endDate'
                                                            id='endDate'
                                                            onChange={(v) => handleBoloSearchState("endDate", v)}
                                                            selected={boloSearchState?.endDate || ""}
                                                            dateFormat="MM/dd/yyyy"
                                                            isClearable={!!boloSearchState?.endDate}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            autoComplete="off"
                                                            placeholderText="Select End Date..."
                                                            minDate={boloSearchState?.startDate || null}
                                                        />
                                                    </div>
                                                </div>


                                                <div className="tab-form-row">
                                                    <div className="col-1 d-flex justify-content-end">
                                                        <label className="tab-form-label d-flex justify-content-end mr-1 mt-2 text-nowrap">Dispatcher</label>
                                                    </div>
                                                    <div className="col-4">
                                                        <Select
                                                            styles={colorLessStyle_Select}
                                                            placeholder="Select"
                                                            isSearchable
                                                            options={primaryOfficerDropDown}
                                                            getOptionLabel={(v) => v?.FirstName + " " + v?.LastName + " | " + v?.PIN}
                                                            getOptionValue={(v) => v?.PINID}
                                                            value={boloSearchState?.dispatcher ? primaryOfficerDropDown?.find((i) => i?.PINID === boloSearchState?.dispatcher) : ""}
                                                            formatOptionLabel={(option, { context }) => {
                                                                return context === 'menu'
                                                                    ? `${option?.FirstName}  ${option?.LastName} | ${option?.PIN}`
                                                                    : option?.PIN;
                                                            }}
                                                            isClearable
                                                            onChange={(e) => { handleBoloSearchState("dispatcher", e?.PINID) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="tab-form-row">
                                                    <div className="col-1 d-flex justify-content-end">
                                                        <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">Message</label>
                                                    </div>
                                                    <div className="col-11">
                                                        <textarea
                                                            name="comments"
                                                            placeholder="Enter Message"
                                                            rows='4'
                                                            value={boloSearchState?.Message}
                                                            onChange={(e) => {
                                                                handleBoloSearchState("Message", e?.target.value);
                                                                e.target.style.height = "auto";
                                                                const maxHeight = 3 * parseFloat(getComputedStyle(e.target).lineHeight);
                                                                e.target.style.height = `${Math.min(e.target.scrollHeight, maxHeight)}px`;
                                                            }}
                                                            className="form-control py-1 new-input"
                                                            style={{ height: '60px' }}
                                                        />
                                                    </div>
                                                </div>
                                                {/* Additional Form Fields */}
                                                <div className="tab-form-row">
                                                    <div className="col-3 d-flex justify-content-start offset-1">
                                                        <input type="checkbox" name="question" value="14" class="clickable mr-2" id="2" checked={boloSearchState?.isClosed} onChange={(e) => { handleBoloSearchState("isClosed", e?.target.checked) }} />
                                                        <label for="2" className="mt-2">Closed</label>
                                                    </div>

                                                </div>

                                                {/* Action Buttons */}
                                                <div className="row">
                                                    <div className="col-12 p-0">
                                                        <div className="py-0 px-2 d-flex justify-content-end align-items-center">
                                                            <div className="d-flex justify-content-end tab-form-row-gap mt-1">
                                                                <button type="button" className="save-button ml-2" onClick={() => handleSearch()} disabled={isBoloSearchStateEmpty(boloSearchState)}>Search</button>
                                                                <button type="button" data-dismiss="modal" className="cancel-button" onClick={() => { setOpenBoloSearchModal(false); clearBoloSearchState(); setBoloSearchList(); }}>Close</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                    {/* <div className="row">
                                    <div className="col-12 p-0">
                                        <div className="py-0 px-2 d-flex justify-content-end align-items-center">
                                            <div className="d-flex justify-content-end tab-form-row-gap my-1">
                                                <button type="button" className="save-button ml-2"> <i className="fa fa-print" /> Print Preview</button>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                    {/* Data Table */}
                                    <div className="table-responsive" style={{ position: "sticky" }}>
                                        <DataTable
                                            dense
                                            columns={columns}
                                            data={boloSearchList}
                                            customStyles={tableCustomStyles}
                                            pagination
                                            responsive
                                            striped
                                            highlightOnHover
                                            fixedHeader
                                            selectableRowsHighlight
                                            fixedHeaderScrollHeight="190px"
                                            persistTableHead={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </dialog>
                ) : null}
            </>
            <ViewImageModal imageModalStatus={imageModalStatus} setImageModalStatus={setImageModalStatus} viewImg={viewImg} />

        </>
    );
};

export default memo(BoloSearchModal);
