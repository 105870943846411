import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import Dropdown from 'react-bootstrap/Dropdown';
import { Filter, SendIcon } from '../../../Components/Filter/Filter';
import { getShowingDateText, tableCustomStyles } from '../../../Components/Common/Utility';
import { compareStrings } from '../../../CADUtils/functions/common';
import { toastifySuccess } from '../../../Components/Common/AlertMsg';
import MasterTableListServices from "../../../CADServices/APIs/masterTableList";
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { faChessKing } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import useObjState from '../../../CADHook/useObjState';

function OnOffDutyConfiguration() {
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const [filterTypeIdOption, setFilterTypeIdOption] = useState('Contains');
  const [pageStatus, setPageStatus] = useState("1");
  const [loginPinID, setLoginPinID] = useState(1);
  const [isChange, setIsChange] = useState(false);
  const [filterTypeDescOption, setFilterTypeDescOption] = useState('Contains');
  const [loginAgencyID, setLoginAgencyID] = useState();
  const [searchValue1, setSearchValue1] = useState('');
  const [searchValue2, setSearchValue2] = useState('');
  const [filterListData, setFilterListData] = useState([]);
  const [listData, setListData] = useState([]);
  const [
    onOffDutyState,
    setOnOffDutyState,
    handleOnOffDutyState,
    clearOnOffDutyState,
  ] = useObjState({
    rowsOfResource: "",
  })

  const getMasterOnOfDutyConfigurationKey = `/CAD/MasterOnOfDutyConfiguration/GetMasterOnOfDutyConfiguration`;
  const { data: getOnOffDutyConfData, isSuccess: isFetchGetOnOffDutyConf, refetch } = useQuery(
    [getMasterOnOfDutyConfigurationKey, { AgencyID: loginAgencyID },],
    MasterTableListServices.getMasterOnOfDutyConfiguration,
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (getOnOffDutyConfData && isFetchGetOnOffDutyConf) {
      const data = JSON.parse(getOnOffDutyConfData?.data?.data);
      setFilterListData(data?.Table)
      setListData(data?.Table)
      setOnOffDutyState({
        rowsOfResource: data?.Table[0]?.OnOfDutyconfigurationNO,
        id: data?.Table[0]?.OnOfDutyconfigurationID
      })
      setIsChange(false);
    } else {
      setFilterListData([])
      setListData([])
    }
  }, [getOnOffDutyConfData, isFetchGetOnOffDutyConf])


  useEffect(() => {
    if (localStoreData) {
      setLoginAgencyID(localStoreData?.AgencyID)
      setLoginPinID(localStoreData?.PINID);
    }
  }, [localStoreData]);

  const columns = [
    {
      name: 'Operator Name',
      selector: row => row.OperatorName,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.OperatorName, rowB.OperatorName),
      style: {
        position: "static",
      },
    },
    {
      name: 'No. of Rows For Resource',
      selector: row => row.OnOfDutyconfigurationNO,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.OnOfDutyconfigurationNO, rowB.OnOfDutyconfigurationNO),
      style: {
        position: "static",
      },
    },
    {
      name: 'Created Date/Time',
      selector: row => row.ModifiedDtTm ? getShowingDateText(row.ModifiedDtTm) : getShowingDateText(row.CreatedDtTm) || "",
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.CreatedDtTm, rowB.CreatedDtTm),
      width: "20%",
      style: {
        position: "static",
      },
    },
  ]

  function handelCancel() {
    clearOnOffDutyState()
  }

  const handelSave = async () => {
    // if (!validateCFSCodeForm()) return

    const isUpdate = Boolean(onOffDutyState?.id);
    const payload = {
      AgencyID: loginAgencyID,
      OnOfDutyconfigurationNO: onOffDutyState?.rowsOfResource,
      PINID: loginPinID,
      CreatedByUserFK: isUpdate ? undefined : loginPinID,
      ModifiedByUserFK: isUpdate ? loginPinID : undefined,
      OnOfDutyconfigurationID: isUpdate ? onOffDutyState?.id : undefined
    };

    const response = isUpdate
      ? await MasterTableListServices.updateMasterOnOfDutyConfiguration(payload)
      : await MasterTableListServices.insertMasterOnOfDutyConfiguration(payload);

    if (response?.status === 200) {
      toastifySuccess(isUpdate ? "Data Updated Successfully" : "Data Saved Successfully");
      handelCancel();
      refetch();
      setIsChange(false);
    }
  };


  function handelSetEditData(data) {
    setOnOffDutyState({
      rowsOfResource: data?.OnOfDutyconfigurationNO,
      id: data?.OnOfDutyconfigurationID
    })
  }

  return (
    <>
      <div className='utilities-tab-content-main-container'>
        <div className='utilities-tab-content-form-container'>
          <div className="row">
            <div className='utilities-tab-content-form-main'>
              <div className="row">
                <div className="col-2 d-flex align-self-center justify-content-end">
                  <label for="" className="tab-form-label">
                    No. Of Rows For Resource
                    {/* {errorHospitalStatusCodeState.hospitalCode && isEmpty(hospitalStatusCodeState?.hospitalCode) && (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Enter Hospital Status Code"}</p>
                          )} */}
                  </label>
                </div>
                <div className="col-2 d-flex align-self-center justify-content-end">
                  <input
                    name="rowsOfResource"
                    type="number"
                    className="form-control py-1 new-input requiredColor"
                    placeholder='No. Of Rows For Resource'
                    value={onOffDutyState?.rowsOfResource}
                    onChange={(e) => { handleOnOffDutyState("rowsOfResource", e.target.value); setIsChange(true) }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='utilities-tab-content-table-container'>
            <div className="row mb-2">
              <div className="col-5 d-flex align-self-center justify-content-end">
                <input
                  type="text"
                  className="form-control py-1 new-input"
                  placeholder='Search By Code...'
                  value={searchValue1}
                  onChange={(e) => {
                    setSearchValue1(e.target.value);
                    const result = Filter(listData, e.target.value, searchValue2, filterTypeIdOption, 'hospitalstatuscode', 'codedescription')
                    setFilterListData(result)
                  }}
                />
              </div>
              <div className="col-1 d-flex align-self-center justify-content-end" style={{ zIndex: '2', }}>
                <Dropdown className='w-100'>
                  <Dropdown.Toggle id="dropdown-basic" className='cad-sort-dropdown'>
                    <img src={SendIcon(filterTypeIdOption)} alt="" className='filter-icon mr-1' />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setFilterTypeIdOption('Contains')}>Contains</Dropdown.Item>
                    <Dropdown.Item onClick={() => setFilterTypeIdOption('is equal to')}>is equal to</Dropdown.Item>
                    <Dropdown.Item onClick={() => setFilterTypeIdOption('is not equal to')}>is not equal to </Dropdown.Item>
                    <Dropdown.Item onClick={() => setFilterTypeIdOption('Starts With')}>Starts With</Dropdown.Item>
                    <Dropdown.Item onClick={() => setFilterTypeIdOption('End with')}>End with</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="col-5 d-flex align-self-center justify-content-end">
                <input
                  type="text"
                  className="form-control py-1 new-input"
                  placeholder='Search By Description...'
                  value={searchValue2}
                  onChange={(e) => {
                    setSearchValue2(e.target.value);
                    const result = Filter(listData, searchValue1, e.target.value, filterTypeDescOption, 'hospitalstatuscode', 'codedescription')
                    setFilterListData(result)
                  }}
                />
              </div>

              <div className="col-1 d-flex align-self-center justify-content-end" style={{ zIndex: '1', }}>
                <Dropdown className='w-100'>
                  <Dropdown.Toggle id="dropdown-basic" className='cad-sort-dropdown'>
                    <img src={SendIcon(filterTypeDescOption)} alt="" className='filter-icon mr-1' />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setFilterTypeDescOption('Contains')}>Contains</Dropdown.Item>
                    <Dropdown.Item onClick={() => setFilterTypeDescOption('is equal to')}>is equal to</Dropdown.Item>
                    <Dropdown.Item onClick={() => setFilterTypeDescOption('is not equal to')}>is not equal to </Dropdown.Item>
                    <Dropdown.Item onClick={() => setFilterTypeDescOption('Starts With')}>Starts With</Dropdown.Item>
                    <Dropdown.Item onClick={() => setFilterTypeDescOption('End with')}>End with</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            <div className="table-responsive">
              <DataTable
                dense
                columns={columns}
                data={filterListData}
                customStyles={tableCustomStyles}
                pagination
                responsive
                striped
                persistTableHead={true}
                highlightOnHover
                fixedHeader
              // onRowClicked={(row) => {
              //   handelSetEditData(row);
              // }}
              />
            </div>
            {parseInt(pageStatus) === 1 &&
              <div className="utilities-tab-content-button-container" >
                {/* <button type="button" className="btn btn-sm btn-success" >New</button> */}
                <button type="button" className="btn btn-sm btn-success" disabled={!isChange} onClick={() => isChange ? handelSave() : () => { }}>{Boolean(onOffDutyState?.id) ? "Update" : "Save"}</button>
              </div>}
          </div>
        </div>
      </div>
      {/* <CADConfirmModal showModal={showModal} setShowModal={setShowModal} handleConfirm={handelActiveInactive} confirmType={confirmType} /> */}
    </>
  )
}

export default OnOffDutyConfiguration