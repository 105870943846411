import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { IncidentContext } from '../../../CADContext/Incident';

const DateTimeCounter = ({ data }) => {
    const { offset } = useContext(IncidentContext);
    const [time, setTime] = useState("");

    const dateTimer = (date) => {
        const pastDate = moment(date);
        const currentDate = moment().utc().add(offset + (new Date().getTimezoneOffset()), 'm');
        const duration = moment.duration(currentDate.diff(pastDate));

        const hours = Math.floor(duration.asHours()).toString().padStart(2, '0');
        const minutes = Math.floor(duration.asMinutes() % 60).toString().padStart(2, '0');
        const seconds = Math.floor(duration.asSeconds() % 60).toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };

    useEffect(() => {
        const interval = setInterval(() => {
            const newTime = data ? dateTimer(data) : "";
            setTime(newTime);
        }, 1000);

        return () => clearInterval(interval);
    }, [data]);

    return (
        <div>
            {time}
        </div>
    );
};

export default DateTimeCounter;
