import React, { useState, useEffect, useContext, useCallback } from 'react'
import { Decrypt_Id_Name, tableCustomStyles } from '../../../../Common/Utility';
import { AddDeleteUpadate, fetchPostData } from '../../../../hooks/Api';
import DataTable from 'react-data-table-component';
import { toastifyError, toastifySuccess } from '../../../../Common/AlertMsg';
import DeletePopUpModal from '../../../../Common/DeleteModal';
import Loader from '../../../../Common/Loader';
import { AgencyContext } from '../../../../../Context/Agency/Index';
import Select from "react-select";
import { RequiredFieldIncident } from '../../../Utility/Personnel/Validation';
import { Comman_changeArrayFormat } from '../../../../Common/ChangeArrayFormat';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { get_LocalStoreData } from '../../../../../redux/actions/Agency';
import ListModal from '../../../Utility/ListManagementModel/ListModal';
import { get_ScreenPermissions_Data } from '../../../../../redux/actions/IncidentAction';
import ChangesModal from '../../../../Common/ChangesModal';

const PoliceForce = (props) => {
  const { DecArrestId } = props
  const dispatch = useDispatch();
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
  const effectiveScreenPermission = useSelector((state) => state.Incident.effectiveScreenPermission);
  const agencyOfficerDrpData = useSelector((state) => state.DropDown.agencyOfficerDrpData);

  const { get_Arrest_Count, setChangesStatus } = useContext(AgencyContext);
  const [clickedRow, setClickedRow] = useState(null);
  const [policeForceData, setPoliceForceData] = useState();
  const [arrsetPoliceForceID, setArrsetPoliceForceID] = useState();
  const [status, setStatus] = useState(false);
  const [modal, setModal] = useState(false);
  const [loder, setLoder] = useState(false)
  const [updateStatus, setUpdateStatus] = useState(0)

  const [loginAgencyID, setLoginAgencyID] = useState('')
  const [arrestID, setArrestID] = useState('')
  const [loginPinID, setLoginPinID] = useState('');
  const [headOfAgency, setHeadOfAgency] = useState([]);
  const [policeForceDrp, setPoliceForceDrp] = useState([])
  const [editval, setEditval] = useState();
  const [openPage, setOpenPage] = useState('');
  const [statesChangeStatus, setStatesChangeStatus] = useState(false);

  const [value, setValue] = useState({
    'ArrestID': '', 'ArrPoliceForceID': '', 'OfficerID': '', 'CreatedByUserFK': '', 'ModifiedByUserFK': '',
  })

  useEffect(() => {
    if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
      if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
    }
  }, []);

  useEffect(() => {
    if (localStoreData) {
      setLoginPinID(parseInt(localStoreData?.PINID)); setLoginAgencyID(parseInt(localStoreData?.AgencyID));
      dispatch(get_ScreenPermissions_Data("A079", localStoreData?.AgencyID, localStoreData?.PINID));
    }
  }, [localStoreData]);
  useEffect(() => {
    if (loginPinID) {
      setValue({ ...value, 'ArrestID': DecArrestId, 'ArrPoliceForceID': '', 'OfficerID': '', 'CreatedByUserFK': loginPinID, 'ModifiedByUserFK': loginPinID, })
    }
  }, [loginPinID]);

  useEffect(() => {
    if (DecArrestId) {
      get_Data_PoliceForce(DecArrestId); setArrestID(DecArrestId);
    }
  }, [DecArrestId]);

  const [errors, setErrors] = useState({
    'ArrPoliceForceIDErrors': '', 'OfficerIDErrors': '',
  })
  const reset = () => {
    setValue({ ...value, 'OfficerID': '', 'ArrPoliceForceID': '', }); setStatesChangeStatus(false);
    setErrors({ 'ArrPoliceForceIDErrors': '', 'OfficerIDErrors': '', });
  }

  const check_Validation_Error = (e) => {
    if (RequiredFieldIncident(value.ArrPoliceForceID)) {
      setErrors(prevValues => { return { ...prevValues, ['ArrPoliceForceIDErrors']: RequiredFieldIncident(value.ArrPoliceForceID) } })
    }
    if (RequiredFieldIncident(value.OfficerID)) {
      setErrors(prevValues => { return { ...prevValues, ['OfficerIDErrors']: RequiredFieldIncident(value.OfficerID) } })
    }
  }

  // Check All Field Format is True Then Submit 
  const { ArrPoliceForceIDErrors, OfficerIDErrors } = errors
  useEffect(() => {
    if (ArrPoliceForceIDErrors === 'true' && OfficerIDErrors === 'true') {
      if (status) { Update_PoliceForce() }
      else { Add_PoliceForce() }
    }
  }, [ArrPoliceForceIDErrors, OfficerIDErrors])

  // useEffect(() => {
  //   if (status) {
  //     GetSingleData()
  //   }
  // }, [arrsetPoliceForceID, updateStatus])

  const GetSingleData = (arrsetPoliceForceID) => {
    const val = { 'ArrsetPoliceForceID': arrsetPoliceForceID, }
    fetchPostData('ArrsetPoliceForce/GetSingleData_ArrsetPoliceForce', val)
      .then((res) => {
        if (res) {
          setEditval(res);
        } else { setEditval([]) }
      })
  }

  useEffect(() => {
    if (status) {
      setValue({
        ...value, 'ArrsetPoliceForceID': arrsetPoliceForceID, 'ArrPoliceForceID': editval[0]?.ArrPoliceForceID, 'OfficerID': editval[0]?.OfficerID, 'ModifiedByUserFK': loginPinID,
      })
    } else {
      setValue({ ...value, 'ArrPoliceForceID': '', 'OfficerID': '', })
    }
  }, [editval])

  useEffect(() => {
    if (loginAgencyID) {
      get_Head_Of_Agency(loginAgencyID); get_Polic_Force(loginAgencyID);
    }
  }, [loginAgencyID])

  const get_Head_Of_Agency = (loginAgencyID) => {
    const val = { AgencyID: loginAgencyID }
    fetchPostData('DropDown/GetData_HeadOfAgency', val).then((data) => {
      if (data) { setHeadOfAgency(Comman_changeArrayFormat(data, 'PINID', 'HeadOfAgency')); }
      else { setHeadOfAgency([]) }
    })
  };

  const get_Polic_Force = (loginAgencyID) => {
    const val = { AgencyID: loginAgencyID }
    fetchPostData('PoliceForce/GetDataDropDown_PoliceForce', val).then((data) => {
      if (data) {
        setPoliceForceDrp(Comman_changeArrayFormat(data, 'PoliceForceID', 'Description'));
      }
      else { setPoliceForceDrp([]) }
    })
  };

  const ChangeDropDown = (e, name) => {
    if (e) {
      setValue({ ...value, [name]: e.value })
      setChangesStatus(true); setStatesChangeStatus(true)
    } else {
      setValue({ ...value, [name]: null })
      setChangesStatus(true); setStatesChangeStatus(true)
    }
  }

  const Add_PoliceForce = () => {
    const { ArrestID, ArrPoliceForceID, OfficerID, CreatedByUserFK, } = value;
    const val = { 'ArrestID': DecArrestId, 'ArrPoliceForceID': ArrPoliceForceID, 'OfficerID': OfficerID, 'CreatedByUserFK': loginPinID, }
    const result = policeForceData?.find(item => {
      if (item.ArrPoliceForceID === value.ArrPoliceForceID && item.OfficerID === value.OfficerID) {
        return item.ArrPoliceForceID === value.ArrPoliceForceID && item.OfficerID === value.OfficerID
      } else return item.ArrPoliceForceID === value.ArrPoliceForceID && item.OfficerID === value.OfficerID
    });
    if (result) {

      toastifyError('Officer And Description Already Exists')
      setErrors({ ...errors, ['OfficerIDErrors']: '' })
    } else {
      AddDeleteUpadate('ArrsetPoliceForce/Insert_ArrsetPoliceForce', val).then((res) => {
        const parsedData = JSON.parse(res.data); setStatesChangeStatus(false)
        const message = parsedData.Table[0].Message;
        get_Data_PoliceForce(arrestID); setModal(false); setStatus(false); setChangesStatus(false);
        get_Arrest_Count(arrestID); reset(); toastifySuccess(message);
      })
    }
  }

  const Update_PoliceForce = () => {
    const result = policeForceData?.find(item => {
      if (item?.ArrsetPoliceForceID != value['ArrsetPoliceForceID']) {
        if (item.ArrPoliceForceID === value.ArrPoliceForceID && item.OfficerID === value.OfficerID) {
          return item.ArrPoliceForceID === value.ArrPoliceForceID && item.OfficerID === value.OfficerID
        } else return item.ArrPoliceForceID === value.ArrPoliceForceID && item.OfficerID === value.OfficerID
      }
    });
    if (result) {
      toastifyError('Officer And Description Already Exists')
      setErrors({ ...errors, ['OfficerIDErrors']: '' })
    } else {
      AddDeleteUpadate('ArrsetPoliceForce/Update_ArrsetPoliceForce', value).then((res) => {
        const parsedData = JSON.parse(res.data); const message = parsedData.Table[0].Message;
        toastifySuccess(message); setStatesChangeStatus(false); get_Data_PoliceForce(arrestID); setChangesStatus(false);
        setModal(false); setStatus(false); setStatusFalse(); get_Arrest_Count(arrestID); reset();
      })
    }
  }

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      reset()
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);


  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "#fce9bf", height: 20, minHeight: 35, fontSize: 14, margintop: 2, boxShadow: 0, }),
  }

  const get_Data_PoliceForce = (ArrestID) => {
    const val = { 'ArrestID': ArrestID }
    fetchPostData('ArrsetPoliceForce/GetData_ArrsetPoliceForce', val).then((res) => {
      if (res) {
        setPoliceForceData(res); setLoder(true)
      } else {
        setPoliceForceData(); setLoder(true)
      }
    })
  }

  const columns = [
    {
      name: 'Officer Name', selector: (row) => row.Officer_Name, sortable: true
    },
    {
      name: 'Description', selector: (row) => row.ArrPoliceForce_Description, sortable: true
    },
    {
      name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 10 }}>Delete</p>,
      cell: row =>
        <div className="div" style={{ position: 'absolute', top: 4, right: 10 }}>
          {
            effectiveScreenPermission ? effectiveScreenPermission[0]?.DeleteOK ?
              <span to={`#`} onClick={() => { setArrsetPoliceForceID(row.ArrsetPoliceForceID); }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                <i className="fa fa-trash"></i>
              </span>
              : <></>
              : <span to={`#`} onClick={() => { setArrsetPoliceForceID(row.ArrsetPoliceForceID); }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                <i className="fa fa-trash"></i>
              </span>
          }
        </div>
    }
  ]

  const set_Edit_Value = (row) => {
    get_Arrest_Count(arrestID); setStatus(true); setStatesChangeStatus(false); setModal(true); setErrors('')
    setUpdateStatus(updateStatus + 1); setArrsetPoliceForceID(row.ArrsetPoliceForceID);
    GetSingleData(row.ArrsetPoliceForceID)

  }

  const DeletePoliceForce = () => {
    const val = { 'ArrsetPoliceForceID': arrsetPoliceForceID, 'DeletedByUserFK': loginPinID }
    AddDeleteUpadate('ArrsetPoliceForce/Delete_ArrsetPoliceForce', val).then((res) => {
      if (res) {
        const parsedData = JSON.parse(res.data); const message = parsedData.Table[0].Message;
        toastifySuccess(message); get_Data_PoliceForce(arrestID); reset()
        get_Arrest_Count(arrestID); setStatus(false);
      } else { console.log("Somthing Wrong"); }
    })
  }

  const setStatusFalse = (e) => {
    setModal(true); setStatus(false); setUpdateStatus(updateStatus + 1); setErrors(''); reset(); setClickedRow('')
  }
  const conditionalRowStyles = [
    {
      when: row => row === clickedRow,
      style: {
        backgroundColor: '#001f3fbd', color: 'white', cursor: 'pointer',
      },
    },
  ];
  return (
    <>
      <div className="col-12 col-md-12 pt-2 p-0" >
        <div className="col-12">
          <div className="row">

            <div className="col-2 col-md-2 col-lg-1 mt-3">
              <label htmlFor="" className='new-label'>Officer{errors.OfficerIDErrors !== 'true' ? (
                <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.OfficerIDErrors}</p>
              ) : null}</label>
            </div>
            <div className="col-4 col-md-4 col-lg-4 mt-2 ">
              {/* <Select
                name='OfficerID'
                value={headOfAgency?.filter((obj) => obj.value === value?.OfficerID)}
                options={headOfAgency}
                styles={colourStyles}
                isClearable
                placeholder="Select..."
                onChange={(e) => { ChangeDropDown(e, 'OfficerID') }}
              /> */}
              <Select
                name='OfficerID'
                styles={colourStyles}
                value={agencyOfficerDrpData?.filter((obj) => obj.value === value?.OfficerID)}
                isClearable
                options={agencyOfficerDrpData}
                onChange={(e) => ChangeDropDown(e, 'OfficerID')}
                placeholder="Select..."
              />
            </div>
            <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
              <label htmlFor="" className='new-label'>Description{errors.ArrPoliceForceIDErrors !== 'true' ? (
                <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.ArrPoliceForceIDErrors}</p>
              ) : null}</label>
            </div>
            <div className="col-4 col-md-4 col-lg-4 mt-2 ">
              <Select
                name='ArrPoliceForceID'
                value={policeForceDrp?.filter((obj) => obj.value === value?.ArrPoliceForceID)}
                styles={colourStyles}
                options={policeForceDrp}
                isClearable
                onChange={(e) => { ChangeDropDown(e, 'ArrPoliceForceID') }}
                placeholder="Select..."
              />
            </div>
          </div>
        </div>
        <div className="btn-box text-right  mr-1 mb-2">
          <button type="button" className="btn btn-sm btn-success mr-1 " onClick={() => { setStatusFalse(); }}>New</button>
          {
            status ?
              effectiveScreenPermission ? effectiveScreenPermission[0]?.Changeok ?
                <button type="button" onClick={() => check_Validation_Error()} disabled={!statesChangeStatus} className="btn btn-sm btn-success mr-1">Update</button>
                : <></> :
                <button type="button" onClick={() => check_Validation_Error()} disabled={!statesChangeStatus} className="btn btn-sm btn-success mr-1">Update</button>
              :
              effectiveScreenPermission ? effectiveScreenPermission[0]?.AddOK ?
                <button type="button" onClick={() => check_Validation_Error()} className="btn btn-sm btn-success mr-1">Save</button>
                : <></> :
                <button type="button" onClick={() => check_Validation_Error()} className="btn btn-sm btn-success mr-1">Save</button>
          }
        </div>
        <div className="col-12 mt-2">
          {
            loder ?
              <DataTable
                dense
                data={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? policeForceData : '' : policeForceData}
                columns={columns}
                highlightOnHover
                pagination
                onRowClicked={(row) => {
                  setClickedRow(row);
                  set_Edit_Value(row);
                }}
                fixedHeaderScrollHeight='500px'
                conditionalRowStyles={conditionalRowStyles}
                fixedHeader
                persistTableHead={true}
                customStyles={tableCustomStyles}
                noDataComponent={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? "There are no data to display" : "You don’t have permission to view data" : 'There are no data to display'}

              />
              :
              <Loader />

          }
        </div>
      </div>
      <DeletePopUpModal func={DeletePoliceForce} />
      <ListModal {...{ openPage, setOpenPage }} />
      <ChangesModal func={check_Validation_Error} setToReset={setStatusFalse} />

    </>
  )
}

export default PoliceForce