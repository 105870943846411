import { toastifyError } from "../../../Common/AlertMsg";

export const PinValidator = (PIN) => {
	if (PIN === '' || PIN === null) {
		return 'Required *';
	} else if (PIN.match(`(?:11|33|22|44|55|66|77|88|99|112233|123456|123|1234|12345|67890|7890|890)`)) {
		return 'Please enter a valid code (eg:895471)';
	} else if (PIN.match(`(^[0-9]{6}$)`)) {
		return 'true'
	}
	else {
		return 'Please enter a valid code (eg:895471)';
	}
};

export const HeightComparision = (HeightFrom, HeightTo) => {
	if (HeightFrom || HeightTo) {
		if (HeightFrom.length >= 5 && HeightTo.length >= 5) {
			if (parseFloat(HeightFrom) > parseFloat(HeightTo)) {
				toastifyError('HeightTo is not less then HeightFrom')
			} else {
				return 'true';
			}
		} else {
			toastifyError('Invalid Format *');
		}
	} else {
		return 'true';
	}
};

// new function  start //

// export const Heights = (HeightFrom, HeightTo) => {
// 	const globalname_Fromarray = HeightFrom?.replace("\"", "").replace("'", "");
// 	const globalname_Toarray = HeightTo?.replace("\"", "").replace("'", "");
// 	if (globalname_Fromarray || globalname_Toarray) {
// 		if (globalname_Fromarray > globalname_Toarray) {
// 			toastifyError('Height To should be greater than Height From')
// 		} else {
// 			return 'true';
// 		}
// 	} else {
// 		return 'true';
// 	}
// };


export const Heights = (HeightFrom, HeightTo) => {
	const globalname_Fromarray = HeightFrom?.replace("\"", "").replace("'", "");
	const globalname_Toarray = HeightTo?.replace("\"", "").replace("'", "");


	if (!globalname_Fromarray || !globalname_Toarray) {
		return 'true';
	}

	if (globalname_Fromarray > globalname_Toarray) {
		toastifyError('Height To should be greater than Height From');
		return 'false';
	} else {
		return 'true';
	}
};






// new function end //
// export const Comparision = (ValueFrom, ValueTo, Name) => {
// 	if (ValueFrom || ValueTo) {
// 		if (parseFloat(ValueFrom) > parseFloat(ValueTo)) {
// 			toastifyError(`${Name}To not less then ${Name}From`);
// 			return (`${Name}To not less then ${Name}From`)
// 		}
// 		else {
// 			return 'true'
// 		}
// 	} else {
// 		return 'true'
// 	}
// }
export const Comparision = (ValueFrom, ValueTo, Name) => {
	if (ValueFrom || ValueTo) {
	  if (parseFloat(ValueFrom) < 0 || parseFloat(ValueFrom) > 99 || parseFloat(ValueTo) < 0 || parseFloat(ValueTo) > 99) {
		toastifyError(`${Name} should be between 0 to 99.`);
		return (`${Name} should be between 0 to 99.`);
	  }
	  if (parseFloat(ValueFrom) > parseFloat(ValueTo)) {
		toastifyError(`${Name}To should not be less than ${Name}From.`);
		return (`${Name}To should not be less than ${Name}From.`);
	  } else {
		return 'true';
	  }
	} else {
	  return 'true';
	}
  }
  

// new function comparision dd //

export const Comparisionweight = (ValueFrom, ValueTo, Name) => {
	if (parseFloat(ValueFrom) <= 0) {
		toastifyError(`${Name}From should be greater than zero`);
		return `${Name}From should be greater than zero`;
	}

	if (ValueFrom || ValueTo) {
		if (parseFloat(ValueFrom) > parseFloat(ValueTo)) {
			toastifyError(`${Name}To cannot be less than ${Name}From`);
			return `${Name}To cannot be less than ${Name}From`;
		} else {
			return 'true';
		}
	} else {
		return 'true';
	}
}

export const RequiredField = (field) => {
	if (field.trim() === '' || field.trim() === null) {
		return 'Required *';
	} else if (field.match(/^\S.*[a-zA-Z\s]*$/)) {
		return 'true';
	} else {
		return 'Space Not Allow';
	}
};

export const RequiredFieldUser = (field, min, max) => {
	if (!field || field.trim().length === 0) {
		return 'Required *';
	} else if (field.includes(' ')) {
		return 'Spaces not allowed';
	} else if (field.length < 5) {
		return `Username must be 5 characters or longer`;
	} else {
		return 'true'
	}
};

export const RequiredFieldSelectBox = (field) => {
	if (field === '' || field === null) {
		return 'Required *';
	} else {
		return 'true';
	}
};

export const PasswordField = (passwordSettingVal, field, UserName) => {
	console.log(field);
	console.log(!hasKeyboardSequence(field))
	if (field === '' || field === null) {
		return 'true'
	} else if (field === UserName) {
		return "Password can't be same as Login UserID"
	}
	else {
		const val = field
		let Color1
		if (
			val?.length >= passwordSettingVal?.MinPasswordLength &&
			val.match(`(?=(.*[A-Z]){${passwordSettingVal?.MinUpperCaseInPassword}})`) &&
			val.match(`(?=(.*[a-z]){${passwordSettingVal?.MinLowerCaseInPassword}})`) &&
			val.match(`(?=(.*[0-9]){${passwordSettingVal?.MinNumericDigitsInPassword}})`) &&
			val.match(`(?=(.*[-\#\$\.\%\&\@\*]){${passwordSettingVal?.MinSpecialCharsInPassword}})`) &&
			!hasKeyboardSequence(field)
		) {
			if (val.match(/^\S.*[a-zA-Z\s]*$/)) {
				Color1 = 'true'
			} else {
				Color1 = 'false'
			}
		} else {
			Color1 = 'false'
		}
		return Color1
	}
};


function hasKeyboardSequence(input) {
	const regex = /(.)\1/;
	const res = regex.test(input.toLowerCase());

	for (let i = 1; i < input.length; i++) {
		if ((Math.abs(input.charCodeAt(i) - input.charCodeAt(i - 1)) === 1) || res) {
			return true; // Found a sequential pair
		}
	}
	return false; // No sequential pair found
}

export const ReEnterPasswordVal = (password, confirmPass) => {
	if (password === '' || password === null && confirmPass === '' || confirmPass === null) {
		return 'true';
	} else if (password === confirmPass) {
		return 'true';
	} else {
		return "password doesn't match"
	}
}

export const PhoneField = (field) => {
	if (field === '' || field === null) {
		return 'true';
	} else if (field.length === 12) {
		return 'true'
	} else {
		return 'Please enter a valid Phone number (eg: 876-987-8940)';
	}
}

export const CellPhoneField = (field) => {
	if (field === '' || field === null) {
		return 'Required *';
	} else if (field.length === 12) {
		return 'true'
	} else {
		return 'Please enter a valid Phone number (eg: 876-987-8940)';
	}
}

export const WorkPhone_Ext_Field = (field) => {
	if (field === '' || field === null) {
		return 'true';
	} else if (String(field).match(`(^[0-9]{3}$)`)) {
		return 'true';
	} else {
		return 'Please enter a valid Ext. (eg: 876)';
	}
}

export const SSN_Field = (field) => {
	if (field === '' || field === null) {
		return 'true';
	} else if (field.length === 11) {
		return 'true'
	} else {
		return 'Please enter a valid SSN number (eg: 876-97-8940)'
	}
}

export const SSN_FieldModel = (type ,field) => {

	if (field === '' || field === null) {
		if(type === "ArrestMod"){
			return 'Required';
		}
		else{
			return 'true';
		}
		
	} else if (field.length === 11) {
		console.log(field)
		return 'true'
	} else {
		return 'Please enter a valid SSN number (eg: 876-97-8940)'
	}
}

// export const SSN_FieldName = (field) => {
// 	if (field === '' || field === null) {
// 		return 'true';
// 	} else if (field.length === 11) {
// 		return 'true'
// 	} else {
// 		return 'Please enter a valid SSN number (eg: 876-97-8940)'
// 	}
// }

export const Deactivate_Date_Field = (DeactivateDate, HiredDate) => {
	if (DeactivateDate === '' || DeactivateDate === null) {
		return 'true';
	} else if (new Date(DeactivateDate).getTime() >= new Date(HiredDate).getTime()) {
		return 'true'
	} else {
		return 'Date not valid'
	}
}

export const Deceased_Date_Field = (DeceasedDate, HiredDate) => {
	if (DeceasedDate === '' || DeceasedDate === null) {
		return 'true';
	} else if (new Date(DeceasedDate).getTime() >= new Date(HiredDate).getTime()) {
		return 'true'
	} else {
		return 'Date not valid'
	}
}

export const Email_Field = (email) => {
	if (!email?.trim()) {
		return 'true';
	} else if (email?.toLowerCase()?.match(/^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/)) {
		return 'true'
	} else {
		return 'Email not valid'
	}
}





