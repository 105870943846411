import React, { useContext, useRef } from 'react'
import img from '../../../../../src/img/images1.jpg'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { Decrypt_Id_Name, colourStyles, customStylesWithOutColor, getShowingDateText, getShowingWithOutTime } from '../../../Common/Utility';
import Select from "react-select";
import { useEffect } from 'react';
import { fetchData, fetchPostData } from '../../../hooks/Api';
import { useReactToPrint } from 'react-to-print';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { get_LocalStoreData } from '../../../../redux/actions/Agency';
import { toastifyError } from '../../../Common/AlertMsg';
import { Comman_changeArrayFormat } from '../../../Common/ChangeArrayFormat';
import { get_Incident_Drp_Data } from '../../../../redux/actions/DropDownsData';
import Loader from '../../../Common/Loader';
import ReportAddress from '../../ReportAddress/ReportAddress';
import { AgencyContext } from '../../../../Context/Agency/Index';


const ArrestSummary = () => {

    const dispatch = useDispatch();
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const fbiCodesDrpData = useSelector((state) => state.DropDown.fbiCodesDrpData);

    const { GetDataTimeZone, datezone } = useContext(AgencyContext);

    const [reportData, setReportData] = useState([]);
    const [verifyArrestMaster, setverifyArrestMaster] = useState(false);
    const [Arrestfromdate, setArrestfromdate] = useState('')
    const [ArrestTodate, setArrestTodate] = useState('')
    const [masterReportData, setMasterReportData] = useState([]);
    const [LoginAgencyID, setLoginAgencyID] = useState('');
    const [LoginPinID, setLoginPinID,] = useState('');
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [zipList, setZipList] = useState([]);
    const [multiImage, setMultiImage] = useState([]);
    const [headOfAgency, setHeadOfAgency] = useState([]);
    const [rmsCfsID, setRmsCfsID] = useState([]);
    const [sexIdDrp, setSexIdDrp] = useState([]);
    const [loder, setLoder] = useState(false);

    const [value, setValue] = useState({
        AgencyID: '', ArrestNumber: '', ArrestNumberTo: '', ArrestDtTmTo: '', ArrestDtTm: '', NameIDNumber: '', PrimaryOfficerID: '',
        LastName: '', FirstName: '', MiddleName: '', SexID: '', RMSCFSCodeList: '', FBIID: '', AgeFrom: '', AgeTo: '',
    });
    const [searchValue, setSearchValue] = useState({
        ArrestNumber: '',
        ArrestNumberTo: '',
        ArrestDtTmTo: '',
        ArrestDtTm: '',
        NameIDNumber: '',
        PrimaryOfficerID: null,
        LastName: '',
        FirstName: '',
        MiddleName: '',
        SexID: null,
        RMSCFSCodeList: null,
        FBIID: null,
        AgeFrom: '',
        AgeTo: '',
    });

    const [showFields, setShowFields] = useState({
        showArrestNumber: false,
        showArrestNumberTo: false,
        showArrestDtTm: false,
        showArrestDtTmTo: false,
        showNameIDNumber: false,
        showPrimaryOfficerID: false,
        showLastName: false,
        showFirstName: false,
        showMiddleName: false,
        showSexID: false,
        showRMSCFSCodeList: false,
        showFBIID: false,
        showAgeFrom: false,
        showAgeTo: false,
    });


    useEffect(() => {
        setShowFields({
            showArrestNumber: searchValue.ArrestNumber,
            showArrestNumberTo: searchValue.ArrestNumberTo,
            showArrestDtTm: searchValue.ArrestDtTm,
            showArrestDtTmTo: searchValue.ArrestDtTmTo,
            showNameIDNumber: searchValue.NameIDNumber,
            showPrimaryOfficerID: searchValue.PrimaryOfficerID !== null,
            showLastName: searchValue.LastName,
            showFirstName: searchValue.FirstName,
            showMiddleName: searchValue.MiddleName,
            showSexID: searchValue.SexID !== null,
            showRMSCFSCodeList: searchValue.RMSCFSCodeList !== null,
            showFBIID: searchValue.FBIID !== null,
            showAgeFrom: searchValue.AgeFrom,
            showAgeTo: searchValue.AgeTo,
        });
    }, [searchValue]);

    const get_Head_Of_Agency = (LoginAgencyID) => {
        const val = { AgencyID: LoginAgencyID }
        fetchPostData('DropDown/GetData_HeadOfAgency', val).then((data) => {
            if (data) {
                setHeadOfAgency(Comman_changeArrayFormat(data, 'PINID', 'HeadOfAgency'));
            } else {
                setHeadOfAgency([])
            }
        })
    };

    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);

    useEffect(() => {
        if (localStoreData) {
            setLoginAgencyID(localStoreData?.AgencyID);
            GetDataTimeZone(localStoreData?.AgencyID);
        }
    }, [localStoreData]);

    useEffect(() => {
        if (LoginAgencyID) {
            get_Head_Of_Agency(LoginAgencyID); GetSexIDDrp(LoginAgencyID); getAgencyImg(LoginAgencyID);
            if (fbiCodesDrpData?.length === 0) { dispatch(get_Incident_Drp_Data(LoginAgencyID)) }
        }
    }, [LoginAgencyID])

    const GetSexIDDrp = (LoginAgencyID) => {
        const val = { AgencyID: LoginAgencyID }
        fetchPostData('DropDown/GetData_SexType', val).then((data) => {
            if (data) {
                setSexIdDrp(Comman_changeArrayFormat(data, 'SexCodeID', 'Description'))
            } else {
                setSexIdDrp([]);
            }
        })
    }


    const get_ArrestSearchData = async () => {
        const {
            ArrestNumber, ArrestNumberTo, ArrestDtTm, ArrestDtTmTo, NameIDNumber, LastName, FirstName,
            MiddleName, SexID, RMSCFSCodeList, FBIID, AgeFrom, AgeTo, PrimaryOfficerID
        } = value
        const val = {
            'AgencyID': LoginAgencyID,
            'ArrestNumber': ArrestNumber?.trim(), 'ArrestNumberTo': ArrestNumberTo?.trim(), 'ArrestDtTm': ArrestDtTm, 'ArrestDtTmTo': ArrestDtTmTo, 'NameIDNumber': NameIDNumber?.trim(), 'LastName': LastName?.trim(), 'FirstName': FirstName?.trim(),
            'MiddleName': MiddleName?.trim(), 'SexID': SexID, 'RMSCFSCodeList': RMSCFSCodeList, 'FBIID': FBIID, 'AgeFrom': AgeFrom?.trim(), 'AgeTo': AgeTo?.trim(), 'PrimaryOfficerID': PrimaryOfficerID,
        }
        console.log(hasValues(val))
        if (hasValues(val)) {
            fetchPostData('ArrestReport/ArrestSummaryReport', val).then((res) => {
                if (res.length > 0) {
                    setMasterReportData(res[0])
                    setReportData(res[0]);
                    setverifyArrestMaster(true)
                    getAgencyImg(LoginAgencyID);
                    setSearchValue(value);
                    setLoder(false);
                } else {
                    toastifyError("Data Not Available");
                    setReportData([]);
                    setverifyArrestMaster(false);
                }
            });
        }
        else {
            toastifyError("Please Enter Details");
        }
    }

    function hasValues(obj) {
        for (let key in obj) {
            // console.log(key)
            if (key != 'AgencyID' && key != 'PINID') {
                // console.log(obj[key])
                if (obj[key]) {
                    return true;
                }
            }
        }
        return false;
    }


    const getAgencyImg = (LoginAgencyID) => {
        const val = { 'AgencyID': LoginAgencyID }
        fetchPostData('Agency/GetDataAgencyPhoto', val).then((res) => {
            if (res) {
                let imgUrl = `data:image/png;base64,${res[0]?.Agency_Photo}`;
                setMultiImage(imgUrl);
            }
            else { console.log("errror") }
        })
    }


    const resetFields = () => {
        setValue({
            ...value,
            AgencyID: '', ArrestNumber: '', ArrestNumberTo: '', ArrestDtTmTo: '', ArrestDtTm: '', NameIDNumber: '',
            LastName: '', FirstName: '', MiddleName: '', SexID: '', RMSCFSCodeList: '', FBIID: '', AgeFrom: '', AgeTo: '', PrimaryOfficerID: '',
        })
        setverifyArrestMaster(false)
        setMasterReportData([]);
    }

    const componentRef = useRef();
    const printForm = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Data',
        onBeforeGetContent: () => {
            setLoder(true);
        },
        onAfterPrint: () => {
            setLoder(false);
        }
    });

    const getRMSCFSCodeList = (LoginAgencyID, FBIID) => {
        const val = { 'FBIID': FBIID, 'AgencyID': LoginAgencyID, }
        fetchPostData('ChargeCodes/GetDataDropDown_ChargeCodes', val).then((data) => {
            if (data) {
                setRmsCfsID(Comman_changeArrayFormat(data, 'ChargeCodeID', 'Description'))
            } else {
                setRmsCfsID([]);
            }
        })
    }

    const ChangeDropDown = (e, name) => {
        if (e) {
            if (name === 'FBIID') {
                getRMSCFSCodeList(LoginAgencyID, e.value)
                setValue({
                    ...value,
                    [name]: e.value,
                    ['RMSCFSCodeList']: "",
                })
            } else {
                setValue({
                    ...value,
                    [name]: e.value
                })
            }
        } else {
            if (name === 'FBIID') {
                setRmsCfsID([]);
                setValue({
                    ...value,
                    ['FBIID']: "",
                    ['RMSCFSCodeList']: "",
                })
            } else {
                setValue({
                    ...value,
                    [name]: null
                })
            }
        }
    }


    const Handlechange = (e) => {
        if (e.target.name === 'SSN') {
            let ele = e.target.value.replace(/\D/g, '');
            if (ele.length === 9) {
                const cleaned = ('' + ele).replace(/\D/g, '');
                const match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);
                if (match) {
                    setValue({
                        ...value,
                        [e.target.name]: match[1] + '-' + match[2] + '-' + match[3]
                    })
                }
            } else {
                ele = e.target.value.split('-').join('').replace(/\D/g, '');
                setValue({
                    ...value,
                    [e.target.name]: ele
                })
            }
            if (e.target.name === 'SSN') {
                return 'true';
            }
            if (e.target.name.length === 11) {
                return 'true'
            }
        }
        else if (e.target.name === 'IncidentNumber') {
            let ele = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
            if (ele.length === 8) {
                const cleaned = ('' + ele).replace(/[^a-zA-Z0-9\s]/g, '');
                const match = cleaned.match(/^(\d{2})(\d{6})$/);
                if (match) {
                    setValue({
                        ...value,
                        [e.target.name]: match[1] + '-' + match[2]
                    })
                }
            } else {
                ele = e.target.value.split("'").join('').replace(/[^0-9\s]/g, '');
                setValue({
                    ...value,
                    [e.target.name]: ele
                })
            }
        }
        else if (e.target.name === 'ArrestNumber' || e.target.name === 'ArrestNumberTo') {
            let ele = e.target.value.trim();
            setValue({
                ...value,
                [e.target.name]: ele
            });
            if (ele.length === 0) {
                e.target.name === 'ArrestNumber' && setValue({
                    ...value,
                    ['ArrestNumberTo']: "",
                    [e.target.name]: ele
                });
            }
        }
        else if (e.target.name === 'AgeFrom' || e.target.name === 'AgeTo') {
            const checkNumber = e.target.value.replace(/[^0-9]/g, "");
            setValue({ ...value, ['Years']: 0, ['DateOfBirth']: 0 })
            setValue({ ...value, [e.target.name]: checkNumber })
            if (checkNumber?.length == 0) { e.target.name == 'AgeFrom' && setValue({ ...value, ['AgeTo']: "", [e.target.name]: checkNumber }) }
        }

        else if (e.target.name === 'LastName' || e.target.name === 'FirstName' || e.target.name === 'MiddleName') {
            // const checkInput = e.target.value.replace(/[^a-zA-Z0-9@#$%&*!.,]/g, "");
            const inputValue = e.target.value;
            const checkInput = inputValue.replace(/[^a-zA-Z0-9@#$%&*!.,\s]/g, "");
            const finalInput = inputValue.trim() === "" ? checkInput.replace(/\s/g, "") : checkInput;
            setValue({
                ...value,
                [e.target.name]: finalInput
            });
        }
        else {
            setValue({
                ...value,
                [e.target.name]: e.target.value
            })
        }
    }

    const onChangeNameIDNum = (e) => {
        if (e) {
            if (e.target.name === 'NameIDNumber') {
                // let ele = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
                let ele = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
                if (ele.length === 10) {
                    const cleaned = ('' + ele).replace(/[^a-zA-Z0-9\s]/g, '');
                    // const match = cleaned.match(/^(\w{1})(\d{9})$/);
                    const match = cleaned.match(/^([AJ]{1})(\d{9})$/);
                    // console.log(match)
                    if (match) {
                        setValue({
                            ...value,
                            [e.target.name]: match[1] + '-' + match[2]
                        })
                    }
                } else {
                    // ele = e.target.value.split("'").join('').replace(/[^a-zA-Z0-9\s]/g, '');
                    ele = e.target.value.split("'").join('').replace(/[^a-zA-Z0-9]/g, '');
                    setValue({
                        ...value,
                        [e.target.name]: ele
                    })
                }
            } else {
                setValue({
                    ...value,
                    [e.target.name]: e.target.value
                })
            }
        } else {
            setValue({
                ...value,
                [e.target.name]: e.target.value
            })
        }
    }


    return (
        <>
            <div class="section-body view_page_design pt-3">
                <div className="row clearfix">
                    <div className="col-12 col-sm-12">
                        <div className="card Agency">
                            <div className="card-body">
                                <fieldset>
                                    <legend>Arrest Summary Report</legend>
                                    <div className="row">

                                        <div className="col-3 col-md-3 col-lg-2  mt-2 ">
                                            <label htmlFor="" className='new-label'>Arrest # From</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3 text-field mt-1">
                                            <input type="text" id='ArrestNumber' name='ArrestNumber' value={value?.ArrestNumber} onChange={Handlechange}
                                                autoComplete='off'
                                            // maxLength={9}
                                            />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-4  mt-2 ">
                                            <label htmlFor="" className='new-label'>Arrest # To</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3 text-field mt-1">
                                            <input type="text" id='ArrestNumberTo' name='ArrestNumberTo' value={value?.ArrestNumberTo}
                                                disabled={!value?.ArrestNumber}
                                                className={!value?.ArrestNumber ? 'readonlyColor' : ''}
                                                autoComplete='off'
                                                onChange={Handlechange}
                                            // maxLength={9}

                                            />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                            <label htmlFor="" className='new-label'>Arrest From Date</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3">
                                            <DatePicker
                                                id='ArrestDtTm'
                                                name='ArrestDtTm'
                                                dateFormat="MM/dd/yyyy"
                                                isClearable={value?.ArrestDtTm ? true : false}
                                                // selected={ArrestDtTm}
                                                // peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                autoComplete='Off'
                                                maxDate={new Date(datezone)}
                                                selected={value?.ArrestDtTm && new Date(value?.ArrestDtTm)}
                                                // ref={startRef}
                                                // onKeyDown={onKeyDown}
                                                onChange={(date) => { setValue({ ...value, ['ArrestDtTm']: date ? getShowingDateText(date) : null, ['ArrestDtTmTo']: null }) }}
                                                timeInputLabel
                                                placeholderText='Select...'
                                            />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-4 mt-2 ">
                                            <label htmlFor="" className='new-label'>Arrest To Date</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3">
                                            <DatePicker
                                                name='ArrestDtTmTo'
                                                id='ArrestDtTmTo'
                                                onChange={(date) => { setValue({ ...value, ['ArrestDtTmTo']: date ? getShowingDateText(date) : null }) }}
                                                selected={value?.ArrestDtTmTo && new Date(value?.ArrestDtTmTo)}
                                                dateFormat="MM/dd/yyyy"
                                                timeInputLabel
                                                // ref={startRef1}
                                                // onKeyDown={onKeyDown}
                                                isClearable={value?.ArrestDtTmTo ? true : false}
                                                // peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                autoComplete='Off'
                                                maxDate={new Date(datezone)}
                                                placeholderText='Select...'
                                                minDate={new Date(value?.ArrestDtTm)}
                                                disabled={value?.ArrestDtTm ? false : true}
                                                className={!value?.ArrestDtTm && 'readonlyColor'}
                                            />
                                        </div>

                                        {/* <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                            <label htmlFor="" className='new-label'>Incident Number</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                            <input type="text" name='IncidentNumber' id='IncidentNumber' maxLength={9} className='' value={value?.IncidentNumber} onChange={Handlechange} />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                            <label htmlFor="" className='new-label'>Name Number</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                            <input type="text" name='NameIDNumber' id='NameIDNumber' maxLength={11} className='' value={value?.NameIDNumber} onChange={Handlechange} />
                                        </div> */}
                                        <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                            <label htmlFor="" className='new-label'>Officer</label>
                                        </div>
                                        <div className="col-9 col-md-9 col-lg-10  mt-1 ">
                                            <Select
                                                styles={customStylesWithOutColor}
                                                name='PrimaryOfficerID'
                                                value={headOfAgency?.filter((obj) => obj.value === value?.PrimaryOfficerID)}
                                                isClearable
                                                options={headOfAgency}
                                                onChange={(e) => ChangeDropDown(e, 'PrimaryOfficerID')}
                                                placeholder="Select..."
                                            />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                            <label htmlFor="" className='new-label'> NIBRS Code/Name</label>
                                        </div>
                                        <div className="col-9 col-md-9 col-lg-3 mt-1">
                                            <Select
                                                name='FBIID'
                                                value={fbiCodesDrpData?.filter((obj) => obj.value === value?.FBIID)}
                                                isClearable
                                                options={fbiCodesDrpData}
                                                onChange={(e) => ChangeDropDown(e, 'FBIID')}
                                                placeholder="Select..."
                                                styles={customStylesWithOutColor}
                                            />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-4 mt-2 ">
                                            <label htmlFor="" className='new-label'>Offense Code</label>
                                        </div>
                                        <div className="col-9 col-md-9 col-lg-3  mt-1 ">
                                            <Select
                                                name='RMSCFSCodeList'
                                                styles={customStylesWithOutColor}
                                                value={rmsCfsID?.filter((obj) => obj.value === value?.RMSCFSCodeList)}
                                                isClearable
                                                options={rmsCfsID}
                                                onChange={(e) => ChangeDropDown(e, 'RMSCFSCodeList')}
                                                placeholder="Select..."
                                                isDisabled={!value?.FBIID}
                                                className={!value?.FBIID ? 'readonlyColor' : ''}
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <legend>Name Information</legend>
                                    <div className="row">
                                        <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                            <label htmlFor="" className='new-label'>MNI</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3 text-field mt-1 ">
                                            <input type="text" name='NameIDNumber' maxLength={11} value={value?.NameIDNumber} onChange={onChangeNameIDNum} id='NameIDNumber' className='' autoComplete='off' />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                            <label htmlFor="" className='new-label'>Last Name</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3 text-field mt-2">
                                            <input type="text" name='LastName' id='LastName' value={value?.LastName}
                                                onChange={Handlechange} autoComplete='off' />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-1 mt-2 ">
                                            <label htmlFor="" className='new-label'>First Name</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                            <input type="text" name='FirstName' id='FirstName' value={value?.FirstName}
                                                onChange={Handlechange} autoComplete='off' />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-1 mt-2 ">
                                            <label htmlFor="" className='new-label'>Middle&nbsp;Name</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3 text-field mt-1">
                                            <input type="text" name='MiddleName' id='MiddleName' value={value?.MiddleName} onChange={Handlechange} autoComplete='off' />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                            <label htmlFor="" className='new-label'>Gender</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3  mt-1 ">
                                            <Select
                                                name='SexID'
                                                value={sexIdDrp?.filter((obj) => obj.value === value?.SexID)}
                                                options={sexIdDrp}
                                                onChange={(e) => ChangeDropDown(e, 'SexID')}
                                                isClearable
                                                placeholder="Select..."
                                                styles={customStylesWithOutColor}
                                            />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-1 mt-2 ">
                                            <label htmlFor="" className='new-label'>Age From</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                            <input type="text" name='AgeFrom' id='AgeFrom' value={value?.AgeFrom} onChange={Handlechange} autoComplete='off' />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-1 mt-2 ">
                                            <label htmlFor="" className='new-label'>Age To</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                            <input type="text" name='AgeTo' id='AgeTo' value={value?.AgeTo} onChange={Handlechange}
                                                disabled={!value?.AgeFrom?.trim()}
                                                className={!value?.AgeFrom?.trim() ? 'readonlyColor' : ''} autoComplete='off'
                                            />
                                        </div>
                                    </div>

                                </fieldset>
                                <div className="col-12 col-md-12 col-lg-12 mt-2 pt-1 text-right">
                                    <button className="btn btn-sm bg-green text-white px-2 py-1" onClick={() => { get_ArrestSearchData(); }} >Show Report</button>
                                    <button className="btn btn-sm bg-green text-white px-2 py-1 ml-2" onClick={() => { setverifyArrestMaster(false); resetFields(); }}>Clear</button>
                                    <Link to={'/Reports'}>
                                        <button className="btn btn-sm bg-green text-white px-2 py-1 ml-2" >Close</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                verifyArrestMaster &&
                <>
                    <div className="col-12 col-md-12 col-lg-12 pt-2  px-2" >
                        <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                            <p className="p-0 m-0 d-flex align-items-center">Arrest Summary Report</p>
                            <div style={{ marginLeft: 'auto' }}>
                                <Link to={''} className="btn btn-sm bg-green  mr-2 text-white px-2 py-0"  >
                                    <i className="fa fa-print" onClick={printForm}></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="container mt-1" ref={componentRef}>
                        <div className="row" style={{ border: '1px solid #80808085' }}>
                            <>
                                <ReportAddress {...{ multiImage, masterReportData }} />

                                {/* <div className="col-4 col-md-3 col-lg-2 pt-1 ml-3">
                                    <div className="main">
                                        <img src={multiImage} className="" alt='Agency_Photo' style={{ marginTop: '4px', width: '150px', height: '150px' }} />
                                    </div>
                                </div> */}
                                {/* <div className="col-7  col-md-7 col-lg-9 mt-2 pt-1 ml-5">
                                    <div className="main">
                                        <h5 className='text-dark text-bold'>{masterReportData?.Agency_Name} Test Test Test</h5>
                                        <p className='text-p'>Address: <span className=''>{masterReportData?.Agency_Address1}Test</span></p>
                                        <div className='d-flex '>
                                            <p className='text-p'>State: <span className='new-span '>{masterReportData?.StateName}</span>
                                            </p>
                                            <p className='text-p ml-5 pl-1'>City: <span className='new-span  '>{masterReportData?.CityName}</span>
                                            </p>
                                            <p className='text-p ml-2'>Zip: <span className='new-span  '>{masterReportData?.Agency_ZipId}</span>
                                            </p>
                                        </div>
                                        <div className='d-flex'>
                                            <p className='text-p'>Phone: <span className='new-span  '>{masterReportData?.Agency_Phone}</span></p>
                                            <p className='text-p ml-3 '>Fax: <span className='new-span  '> {masterReportData?.Agency_Fax}</span></p>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="col-7 col-md-7 col-lg-9 mt-2">
                                    <div className="main">
                                        <h5 className='text-dark font-weight-bold'>{masterReportData?.Agency_Name}</h5>
                                        <p className='text-p'>Address: <span className='text-address'>{masterReportData?.Agency_Address1}</span></p>
                                        <div className='d-flex justify-content-start flex-wrap'>
                                            <p className='text-p'>City: <span className='text-gray ml-2'>{masterReportData?.CityName}</span></p>
                                            <p className='text-p mb-1 ml-3'>State: <span className='text-gray'>{masterReportData?.StateName}</span></p>
                                            <p className='text-p mb-1 ml-3'>Zip: <span className='text-gray'>{masterReportData?.Zipcode}</span></p>
                                        </div>
                                        <div className='d-flex justify-content-start flex-wrap'>
                                            <p className='text-p mb-1'>Phone: <span className='text-gray ml-1'>{masterReportData?.Agency_Phone}</span></p>
                                            <p className='text-p mb-1 ml-4'>Fax: <span className='text-gray'>{masterReportData?.Agency_Fax}</span></p>
                                        </div>
                                    </div>
                                </div> */}
                            </>
                            <div className="col-12">
                                <hr style={{ border: '1px solid rgb(3, 105, 184)' }} />
                                <h5 className=" text-white text-bold bg-green py-1 px-3 text-center">Arrest Summary Report</h5>
                            </div>
                            <div className="col-12">
                                <fieldset>
                                    <legend>Search Criteria</legend>
                                    <div className="row">
                                        {showFields.showArrestNumber && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>Arrest Number From</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor' value={searchValue.ArrestNumber || ''} readOnly />
                                                </div>
                                            </>
                                        )}

                                        {showFields.showArrestNumberTo && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>Arrest Number To</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor' value={searchValue.ArrestNumberTo || ''} readOnly />
                                                </div>
                                            </>
                                        )}
                                        {showFields.showArrestDtTm && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>Arrest Date From</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor' value={searchValue.ArrestDtTm || ''} readOnly />
                                                </div>
                                            </>
                                        )}
                                        {showFields.showArrestDtTmTo && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>Arrest Date To</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor' value={searchValue.ArrestDtTmTo || ''} readOnly />
                                                </div>
                                            </>
                                        )}
                                        {showFields.showNameIDNumber && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>MNI</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor' value={searchValue.NameIDNumber || ''} readOnly />
                                                </div>
                                            </>
                                        )}

                                        {showFields.showPrimaryOfficerID && searchValue.PrimaryOfficerID && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>Officer</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor' value={headOfAgency.find((obj) => obj.value === searchValue.PrimaryOfficerID)?.label || ''} readOnly />
                                                </div>
                                            </>
                                        )}

                                        {showFields.showLastName && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>Last Name</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor' value={searchValue.LastName || ''} readOnly />
                                                </div>
                                            </>
                                        )}
                                        {showFields.showFirstName && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>First Name</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor' value={searchValue.FirstName || ''} readOnly />
                                                </div>
                                            </>
                                        )}
                                        {showFields.showMiddleName && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>Middle Name</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor' value={searchValue.MiddleName || ''} readOnly />
                                                </div>
                                            </>
                                        )}

                                        {
                                            showFields.showSexID && searchValue.SexID && (

                                                <>
                                                    <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                        <label className='new-label'>Gender</label>
                                                    </div>
                                                    <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                        <input type="text" className='readonlyColor' value={sexIdDrp.find((obj) => obj.value === searchValue.SexID)?.label || ''} readOnly />
                                                    </div>
                                                </>
                                            )
                                        }
                                        {
                                            showFields.showFBIID && searchValue.FBIID && (
                                                <>
                                                    <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                        <label className='new-label'>NIBRS</label>
                                                    </div>

                                                    <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                        <input type="text" className='readonlyColor' value={fbiCodesDrpData.find((obj) => obj.value === searchValue.FBIID)?.label || ''} readOnly />
                                                    </div>
                                                </>
                                            )}
                                        {
                                            showFields.showRMSCFSCodeList && searchValue.RMSCFSCodeList && (
                                                <>
                                                    <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                        <label className='new-label'>Offense Code</label>
                                                    </div>
                                                    <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                        <input type="text" className='readonlyColor' value={rmsCfsID.find((obj) => obj.value === searchValue.RMSCFSCodeList)?.label || ''} readOnly />
                                                    </div>
                                                </>
                                            )}
                                        {showFields.showAgeFrom && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>Age From </label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor' value={searchValue.AgeFrom || ''} readOnly />
                                                </div>
                                            </>
                                        )}
                                        {showFields.showAgeTo && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>Age To </label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor' value={searchValue.AgeTo || ''} readOnly />
                                                </div>
                                            </>
                                        )}

                                    </div>
                                </fieldset>
                            </div>
                            <div className="container mt-1" style={{ pageBreakAfter: 'always' }}>
                                <div className="col-12">
                                    {
                                        masterReportData?.Incident?.map((obj) =>
                                            <>
                                                <div className="container">

                                                    <h5 className=" text-white text-bold bg-green py-1 px-3"> Incident Number:- {obj.IncidentNumber}</h5>
                                                    <table className="table table-bordered">
                                                        <thead className='text-dark master-table'>
                                                            <tr>
                                                                <th className='' style={{ width: '150px' }}>Incident Number:</th>
                                                                <th className='' style={{ width: '150px' }}>Reported Date/Time:</th>
                                                                <th className='' style={{ width: '150px' }}>RMS Disposition:</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody className='master-tbody'>
                                                            <tr>
                                                                <td className='text-list' style={{ width: '150px' }}>{obj?.IncidentNumber}</td>
                                                                <td className='text-list' style={{ width: '150px' }}>{obj?.ReportedDate && getShowingDateText(obj?.ReportedDate)}</td>
                                                                <td className='text-list' style={{ width: '150px' }}>{obj?.RMSDisposition}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                    {
                                                        JSON.parse(obj?.Arrest)?.length > 0 ?
                                                            <>
                                                                <div className="table-responsive" >
                                                                    <table className="table table-bordered" >
                                                                        <hr />
                                                                        <tbody className='master-tbody'>
                                                                            {
                                                                                JSON.parse(obj?.Arrest)?.map((item, key) => (
                                                                                    <>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <h6 className='text-dark text-bold'>Arrest Number:</h6>
                                                                                                <p className='text-list'>{item.ArrestNumber}</p>
                                                                                            </td>
                                                                                            <td>
                                                                                                <h6 className='text-dark text-bold'>Arrest Date/Time</h6>
                                                                                                <p className='text-list'>{item?.ArrestDtTm && getShowingDateText(item?.ArrestDtTm)}</p>
                                                                                            </td>
                                                                                            <td >
                                                                                                <h6 className='text-dark text-bold'>Officer:</h6>
                                                                                                <p className='text-list'>{item.PrimaryOfficer_Name}</p>
                                                                                            </td>

                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <h6 className='text-dark text-bold'>Arrestee:</h6>
                                                                                                <p className='text-list'>{item.Arrestee_Name}</p>
                                                                                            </td>
                                                                                            <td>
                                                                                                <h6 className='text-dark text-bold'>Gender:</h6>
                                                                                                <p className='text-list'>{item.Gender}</p>
                                                                                            </td>
                                                                                            <td>
                                                                                                <h6 className='text-dark text-bold'>Age</h6>
                                                                                                <p className='text-list'>{item.Years ? `${item.Years} years` : 'N/A'}</p>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td colSpan={12}>
                                                                                                <h6 className='text-dark text-bold'>Charge</h6>
                                                                                                {
                                                                                                    (JSON.parse(obj?.Arrest || "[]") || []).map((arrest, arrestKey) => (
                                                                                                        (JSON.parse(arrest?.Charge || "[]") || []).map((charge, chargeKey) => (
                                                                                                            <p className='text-list' key={`${arrestKey}-${chargeKey}`} >
                                                                                                                {charge.ChargeCode_Description}
                                                                                                            </p>
                                                                                                        ))
                                                                                                    ))
                                                                                                }
                                                                                            </td>
                                                                                        </tr>

                                                                                    </>
                                                                                ))
                                                                            }
                                                                        </tbody>


                                                                        {/* <table className="table table-bordered">
                                                                            <thead className='text-dark master-table'>
                                                                                <tr>
                                                                                    <th className=''>Charge</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className='master-tbody'>
                                                                                {
                                                                                    (JSON.parse(obj?.Arrest || "[]") || []).map((arrest, arrestKey) => (
                                                                                        (JSON.parse(arrest?.Charge || "[]") || []).map((charge, chargeKey) => (
                                                                                            <tr key={`${arrestKey}-${chargeKey}`} style={{ borderBottom: '0.2px solid gray' }}>
                                                                                                <td>{charge.ChargeCode_Description}</td>
                                                                                            </tr>
                                                                                        ))
                                                                                    ))
                                                                                }

                                                                            </tbody>
                                                                        </table> */}
                                                                    </table>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                            </>
                                                    }


                                                    <div className="table-responsive" >
                                                        {/* {
                                                            JSON.parse(obj?.Arrest).length > 0 ?
                                                                <>
                                                                    <table className="table table-bordered">
                                                                        <thead className='text-dark master-table'>
                                                                            <tr>
                                                                                <th className='' style={{ width: '150px' }}>Arrest Number</th>
                                                                                <th className='' style={{ width: '150px' }}>Arrest Date/Time</th>
                                                                                <th className='' style={{ width: '150px' }}>Officer</th>
                                                                                <th className='' style={{ width: '150px' }}>Arrestee</th>
                                                                                <th className='' style={{ width: '150px' }}>Gender</th>
                                                                                <th className='' style={{ width: '150px' }}>Age</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className='master-tbody'>
                                                                            {
                                                                                JSON.parse(obj?.Arrest || obj?.Charge)?.map((item, key) => (
                                                                                    <>
                                                                                        <tr key={key} style={{ borderBottom: '0.2px solid gray' }}>
                                                                                            <td style={{ width: '150px' }}>{item.ArrestNumber}</td>
                                                                                            <td style={{ width: '150px' }}>{item?.ArrestDtTm && getShowingDateText(item?.ArrestDtTm)}</td>
                                                                                            <td style={{ width: '150px' }}>{item.PrimaryOfficer_Name}</td>
                                                                                            <td style={{ width: '150px' }}>{item.Arrestee_Name}</td>
                                                                                            <td style={{ width: '150px' }}>{item.Gender}</td>
                                                                                            <td style={{ width: '150px' }}>{item.Years ? `${item.Years} years` : 'N/A'}</td>
                                                                                        </tr>
                                                                                    </>
                                                                                ))
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                    <table className="table table-bordered">
                                                                        <thead className='text-dark master-table'>
                                                                            <tr>
                                                                                <th className=''>Charge</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className='master-tbody'>
                                                                            {
                                                                                (JSON.parse(obj?.Arrest || "[]") || []).map((arrest, arrestKey) => (
                                                                                    (JSON.parse(arrest?.Charge || "[]") || []).map((charge, chargeKey) => (
                                                                                        <tr key={`${arrestKey}-${chargeKey}`} style={{ borderBottom: '0.2px solid gray' }}>
                                                                                            <td>{charge.ChargeCode_Description}</td>
                                                                                        </tr>
                                                                                    ))
                                                                                ))
                                                                            }

                                                                        </tbody>
                                                                    </table>

                                                                </>
                                                                :
                                                                <></>
                                                        } */}
                                                    </div>

                                                </div >
                                            </>
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                    </div >
                </>
            }
            {loder && (
                <div className="loader-overlay">
                    <Loader />
                </div>
            )}
        </>
    )
}

export default ArrestSummary

export const changeArrayFormat = (data, type) => {
    if (type === 'zip') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.zipId, label: sponsor.Zipcode })
        )
        return result
    }
    if (type === 'state') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.StateID, label: sponsor.StateName })
        )
        return result
    }
    if (type === 'city') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.CityID, label: sponsor.CityName })
        );
        return result
    }
}
