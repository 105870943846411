import React, { useContext, useEffect, useRef, useState } from 'react'
import Select, { components } from "react-select";
import DatePicker from "react-datepicker";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Decrypt_Id_Name, DecryptedList, filterPassedTime, EncryptedList, getShowingDateText, base64ToString, getShowingMonthDateYear, stringToBase64, getShowingWithOutTime, tableCustomStyles, Aes256Encrypt } from '../../../../Common/Utility';
import { AddDeleteUpadate, AddDelete_Img, fetchData, fetchPostData } from '../../../../hooks/Api';
import { Comman_changeArrayFormat, changeArray, fourColArray, sixColArray, threeColArray } from '../../../../Common/ChangeArrayFormat';
import { toastifyError, toastifySuccess } from '../../../../Common/AlertMsg';
import { AgencyContext } from '../../../../../Context/Agency/Index';
import { Email_Field, PhoneFieldNotReq, RequiredField, NameValidationCharacter, PhoneField, FaxField } from '../../../Agency/AgencyValidation/validators';
import { RequiredFieldIncident, Space_Allow_with_Trim, Space_Not_Allow, Space_NotAllow } from '../../../Utility/Personnel/Validation';
import SelectBox from '../../../../Common/SelectBox';
import makeAnimated from "react-select/animated";
import Location from '../../../../Location/Location';
import NameSearchModal from '../../../NameSearch/NameSearchModal';
import { Comparision, SSN_Field, Heights, SSN_FieldName, Comparisionweight } from '../../../PersonnelCom/Validation/PersonnelValidation';
import DeletePopUpModal from '../../../../Common/DeleteModal';
import { Carousel } from 'react-responsive-carousel';
import defualtImage from '../../../../../img/uploadImage.png';
import VerifyLocation from './VerifyLocation';
import ChangesModal from '../../../../Common/ChangesModal';
import IdentifyFieldColor from '../../../../Common/IdentifyFieldColor';
import DataTable from 'react-data-table-component';
import MasterNameModel from '../../../MasterNameModel/MasterNameModel';
import ImageModel from '../../../ImageModel/ImageModel';
import { useDispatch, useSelector } from 'react-redux';
import { get_Inc_ReportedDate, get_LocalStoreData, get_NameTypeData } from '../../../../../redux/actions/Agency';
import { Name_ID, Master_NameID, Name_Status } from '../../../../../redux/actionTypes';
import { get_AgencyOfficer_Data, get_Masters_Name_Drp_Data } from '../../../../../redux/actions/DropDownsData';
import { get_ScreenPermissions_Data } from '../../../../../redux/actions/IncidentAction';
import AlertMasterModel from '../../../AlertMaster/AlertMasterModel';
import AlertTable from '../../../AlertMaster/AlertTable';
import ListModal from '../../../Utility/ListManagementModel/ListModal';

const MultiValue = props => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);

const Home = ({ get_Name, setNameListData, showVictim, setShowVictim, setshowWarrant, setShowOffender, setIsBusinessName, get_List, isCad = false }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const agencyOfficerDrpData = useSelector((state) => state.DropDown.agencyOfficerDrpData);
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const nameTypeData = useSelector((state) => state.Agency.nameTypeData);
  const mastersNameDrpData = useSelector((state) => state.DropDown.mastersNameDrpData);
  const effectiveScreenPermission = useSelector((state) => state.Incident.effectiveScreenPermission);
  const incReportedDate = useSelector((state) => state.Agency.incReportedDate);

  const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';

  const { get_Incident_Count, get_Name_Count, nameSearchStatus, get_Data_Name, setcountAppear, setAuditCount, setNameSearchStatus, setcountStatus, setChangesStatus, setNameSingleData, changesStatus, nameFilterData, } = useContext(AgencyContext);


  const useQuery = () => {
    const params = new URLSearchParams(useLocation().search);
    return {
      get: (param) => params.get(param)
    };
  };

  const saveButtonRef = useRef(null);
  const closeButtonRef = useRef(null);
  const firstNameInputRef = useRef(null);

  let DeNameID = 0, DeMasterNameID = 0

  const query = useQuery();
  var IncID = query?.get("IncId");
  var IncNo = query?.get("IncNo");
  var IncSta = query?.get("IncSta");
  // let openPage = query?.get('page');
  let openPageName = query?.get('page');
  var NameID = query?.get("NameID");
  var NameStatus = query?.get("NameStatus");
  var MasterNameID = query?.get("MasterNameID");
  let MstPage = query?.get('page');
  let ModNo = query?.get('ModNo');


  if (!IncID) IncID = 0;
  else IncID = parseInt(base64ToString(IncID));
  if (!NameID) NameID = 0;
  else DeNameID = parseInt(base64ToString(NameID));
  if (!MasterNameID) MasterNameID = 0;
  else DeMasterNameID = parseInt(base64ToString(MasterNameID));

  const [clickedRow, setClickedRow] = useState(null);
  const [editval, setEditval] = useState([]);
  //Datepicker
  const [dobDate, setDobDate] = useState();
  const [yearsVal, setYearsVal] = useState();
  const [juvinile, setJuvinile] = useState();
  // DropDown
  const [ethinicityDrpData, setEthinicityDrpData] = useState([]);
  const [ageUnitDrpData, setAgeUnitDrpData] = useState([]);
  const [nameTypeIdDrp, setNameTypeIdDrp] = useState([]);
  const [suffixIdDrp, setSuffixIdDrp] = useState([]);
  const [verifyIdDrp, setVerifyIdDrp] = useState([]);
  const [sexIdDrp, setSexIdDrp] = useState([]);
  const [raceIdDrp, setRaceIdDrp] = useState([]);
  const [phoneTypeIdDrp, setPhoneTypeIdDrp] = useState([]);
  const [reasonIdDrp, setReasonIdDrp] = useState([]);
  const [certifiedByIdDrp, setCertifiedByIdDrp] = useState([]);
  const [nameTypeCode, setNameTypeCode] = useState();
  const [businessTypeDrp, setBusinessTypeDrp] = useState([]);
  const [phoneTypeCode, setPhoneTypeCode] = useState('');
  const [nameSearchValue, setNameSearchValue] = useState([]);
  const [isAdult, setIsAdult] = useState(false);
  const [ownerNameData, setOwnerNameData] = useState([]);
  const [nameModalStatus, setNameModalStatus] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(0);
  const [stateList, setStateList] = useState([]);
  const [dlNumber, setDlNumber] = useState(false);
  const [globalname, setglobalname] = useState('')
  const [globalnameto, setglobalnameto] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const [called, setcalled] = useState(false);
  const [openPage, setOpenPage] = useState('');
  const crossButtonRef = useRef(null);
  const [isModalActive, setIsModalActive] = useState(false);


  // new states 
  // const [masterNameID, setMasterNameID] = useState(null);
  const [nameIDNumber, setNameIDNumber] = useState(null);


  // Image 
  const [nameMultiImg, setNameMultiImg] = useState([]);
  const [imageId, setImageId] = useState('');
  // Verify Location 
  const [modalStatus, setModalStatus] = useState(false);
  const [addVerifySingleData, setAddVerifySingleData] = useState([]);
  const [locationStatus, setLocationStatus] = useState(false);
  const [agencyName, setAgencyName] = useState('');
  const [mainIncidentID, setMainIncidentID] = useState('');
  const [masterNameID, setMasterNameID] = useState();
  const [nameID, setNameID] = useState();
  const [loginAgencyID, setLoginAgencyID] = useState(19);
  const [loginPinID, setLoginPinID] = useState(1);
  const [possessionID, setPossessionID] = useState('');
  const [type, setType] = useState("NameBusiness");
  const [uploadImgFiles, setuploadImgFiles] = useState([])
  const [imageModalStatus, setImageModalStatus] = useState(false)
  const [onSelectLocation, setOnSelectLocation] = useState(true);
  const [possenSinglData, setPossenSinglData] = useState([]);
  const [availableAlert, setAvailableAlert] = useState([]);
  const [statesChangeStatus, setStatesChangeStatus] = useState(false);
  const [saveValue, setsaveValue] = useState(false);


  const [multiSelected, setMultiSelected] = useState({
    optionSelected: null
  })

  const [value, setValue] = useState({
    'NameIDNumber': 'Auto Generated', 'NameTypeID': '', 'BusinessTypeID': '', 'SuffixID': '', 'VerifyID': '', 'SexID': '',
    'RaceID': '', 'PhoneTypeID': '', 'NameReasonCodeID': '', 'CertifiedByID': '', 'EthnicityID': '', 'AgeUnitID': '',
    'IsJuvenile': '', 'IsCurrentPh': true, 'IsVerify': true, 'IsUnListedPhNo': '', 'LastName': '', 'FirstName': '', 'MiddleName': '',
    'SSN': '', 'WeightFrom': '', 'WeightTo': '', 'HeightFrom': '', 'HeightTo': '', 'Address': '', 'Contact': '',
    'OwnerNameID': '', 'OwnerPhoneNumber': '', 'OwnerFaxNumber': '', 'DateOfBirth': '', 'CertifiedDtTm': '', 'AgeFrom': '',
    'AgeTo': '', 'Years': '', 'EventType': 'I', 'ModifiedByUserFK': '', 'MasterNameID': '', 'NameID': '',
    'ArrestID': "", 'WarrantID': "", 'TicketID': "", 'checkVictem': 0, 'checkOffender': 0, 'checkArrest': 0,
    'CreatedByUserFK': '', 'AgencyID': '', 'IncidentID': '', 'NameLocationID': '',
    'DLNumber': "",
    'DLStateID': '',
    'IsUnknown': false,
    'IsMaster': MstPage === "MST-Name-Dash" ? true : false,
  })

  const [errors, setErrors] = useState({
    'NameTypeIDError': '', 'LastNameError': '', 'FirstNameError': '', 'MiddleNameError': '', 'NameReasonCodeIDError': '', 'CertifiedByIDError': '', 'ContactError': 'true', 'WeightError': 'true',
    'HeightError': 'true', 'AgeError': 'true', 'DateOfBirthError': '', 'RaceIDError': '', 'DLError': 'true', 'SexIDError': '', 'AddressError': 'true', 'CrimeLocationError': '',
  })

  const [imgData, setImgData] = useState({
    "PictureTypeID": '',
    "ImageViewID": '',
    "ImgDtTm": '',
    "OfficerID": '',
    "Comments": '',
    "DocumentID": ''
  })

  useEffect(() => {
    if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
      if (uniqueId) { dispatch(get_LocalStoreData(uniqueId)); }
      get_Incident_Count(IncID);
      setMainIncidentID(IncID);
    }
  }, []);

  useEffect(() => {
    if (localStoreData) {
      setLoginAgencyID(localStoreData?.AgencyID); setLoginPinID(localStoreData?.PINID);
      //  getScreenPermision(localStoreData?.AgencyID, localStoreData?.PINID);
      dispatch(get_ScreenPermissions_Data("N046", localStoreData?.AgencyID, localStoreData?.PINID));
    }
  }, [localStoreData]);



  useEffect(() => {
    setMasterNameID(value.MasterNameID);
  }, [value.MasterNameID])

  useEffect(() => {
    if (DeNameID || DeMasterNameID) {
      get_Name_Count(DeNameID, DeMasterNameID, MstPage === "MST-Name-Dash" ? true : false);
    }
  }, [DeNameID, DeMasterNameID])


  console.log(DeNameID ,DeMasterNameID)

  useEffect(() => {
    if (IncID) {
      setMainIncidentID(IncID);
      dispatch(get_Inc_ReportedDate(IncID))
    }
    else {
      Reset();
    }
  }, [IncID]);

  useEffect(() => {
    if (IncID) {
      setMainIncidentID(IncID);
      get_Arrestee_Drp_Data(IncID);
      get_Data_Name(IncID);
    }
  }, [IncID, nameModalStatus, possessionID]);

  useEffect(() => {
    if (MstPage === "MST-Name-Dash" && possessionID) {
      dispatch(get_Masters_Name_Drp_Data(possessionID, DeNameID, DeMasterNameID));
    }
    if (possessionID) { setValue({ ...value, ['OwnerNameID']: parseInt(possessionID) }) }
  }, [possessionID, ownerNameData]);

  useEffect(() => {
    if (DeNameID || DeMasterNameID) {
      setNameID(DeNameID); GetSingleData(DeNameID, DeMasterNameID); setMasterNameID(DeMasterNameID)
    } else {
      Reset()
    }
    if (DeNameID || DeMasterNameID || MstPage === "MST-Name-Dash") {
      setNameID(DeNameID); GetSingleData(DeNameID, DeMasterNameID); setMasterNameID(DeMasterNameID)
    }
    else {
      Reset()
    }
  }, [DeNameID, DeMasterNameID]);

  useEffect(() => {
    if (mainIncidentID) {
      setValue({ ...value, 'AgencyID': loginAgencyID, 'IncidentID': mainIncidentID, 'CreatedByUserFK': loginPinID, });
      // get_Data_Name(mainIncidentID);
    }
  }, [mainIncidentID]);

  useEffect(() => {
    if (nameTypeData?.length > 0) {
      const Id = nameTypeData?.filter((val) => { if (val.id === "I") return val })
      if (Id.length > 0 && (NameStatus == null || NameStatus == 'null' || NameStatus == false || NameStatus == 'false')) {
        setValue({ ...value, ['NameTypeID']: Id[0]?.value, })
        setNameTypeCode(Id[0].id);
        setIsBusinessName(false);
      }
    }
  }, [nameTypeData, mainIncidentID])

  const check_Validation_Error = (e) => {
    const { LastName, FirstName, MiddleName, NameTypeID, NameReasonCodeID, SSN, DLStateID, DLNumber, Contact, HeightFrom, HeightTo, WeightFrom, WeightTo, AgeFrom, AgeTo, SexID, RaceID, DateOfBirth, IsUnknown } = value;
    if (isAdult) {
      const SexIDError = RequiredField(value.SexID);
      const RaceIDErr = RequiredField(value.RaceID);
      const DateOfBirthErr = isAdult && value?.IsUnknown ? 'true' : RequiredField(value.DateOfBirth);
      // const LastNameErr = NameValidationCharacter(value.LastName, 'LastName');
      // const FirstNameErr = NameValidationCharacter(value.FirstName);
      // const MiddleNameErr = NameValidationCharacter(value.MiddleName);
      const CrimeLocationErr = onSelectLocation || value.Address === '' ? 'true' : 'true';
      const LastNameErr = NameValidationCharacter(LastName, 'LastName', FirstName, MiddleName, LastName);
      const FirstNameErr = NameValidationCharacter(FirstName, 'FirstName', FirstName, MiddleName, LastName);
      const MiddleNameErr = NameValidationCharacter(MiddleName, 'MiddleName', FirstName, MiddleName, LastName);
      const NameTypeIDErr = RequiredFieldIncident(value.NameTypeID);
      const NameReasonCodeIDErr = MstPage === "MST-Name-Dash" ? 'true' : RequiredFieldIncident(value.NameReasonCodeID);
      const SSNErr = SSN_Field(value.SSN);
      const DLError = value.DLStateID ? RequiredFieldIncident(value.DLNumber) : 'true'
      const OwnerPhoneNumberError = value.OwnerPhoneNumber ? PhoneField(value.OwnerPhoneNumber) : 'true'
      const OwnerFaxNumberError = value.OwnerFaxNumber ? FaxField(value.OwnerFaxNumber) : 'true'

      // const HeightError = value.HeightFrom && value.HeightTo ? Heights(value.HeightFrom, value.HeightTo) : 'true'
      setErrors(prevValues => {
        return {
          ...prevValues,
          ['SexIDError']: SexIDError || prevValues['SexIDError'],
          ['RaceIDError']: RaceIDErr || prevValues['RaceIDError'],
          ['DateOfBirthError']: DateOfBirthErr || prevValues['DateOfBirthError'],
          ['LastNameError']: LastNameErr || prevValues['LastNameError'],
          ['FirstNameError']: FirstNameErr || prevValues['FirstNameError'],
          ['MiddleNameError']: MiddleNameErr || prevValues['MiddleNameError'],
          ['NameTypeIDError']: NameTypeIDErr || prevValues['NameTypeIDError'],
          ['NameReasonCodeIDError']: NameReasonCodeIDErr || prevValues['NameReasonCodeIDError'],
          ['SSN']: SSNErr || prevValues['SSN'],
          ['DLError']: DLError || prevValues['DLError'],
          ['OwnerPhoneNumberError']: OwnerPhoneNumberError || prevValues['OwnerPhoneNumberError'],
          ['OwnerFaxNumberError']: OwnerFaxNumberError || prevValues['OwnerFaxNumberError'],
          ['CrimeLocationError']: CrimeLocationErr || prevValues['CrimeLocationError'],

          // ['HeightError']: HeightError || prevValues['HeightError'],
        }
      })

      // Phone Validation
      if (phoneTypeCode === 'E') {
        Email_Field(value.Contact) && setErrors(prevValues => { return { ...prevValues, ['ContactError']: Email_Field(value.Contact) } })
      } else if (phoneTypeCode) {
        PhoneFieldNotReq(value.Contact) && setErrors(prevValues => { return { ...prevValues, ['ContactError']: PhoneFieldNotReq(value.Contact) } })
      }
      // height validation
      if (Heights(value.HeightFrom, value.HeightTo, 'Height') === 'true') {
        setErrors(prevValues => { return { ...prevValues, ['HeightError']: 'true' } })
      } else {
        setErrors(prevValues => { return { ...prevValues, ['HeightError']: 'error' } })
      }


      // Phone Validation
      // if (phoneTypeCode === 'E') {
      //   Email_Field(value.Contact) && setErrors(prevValues => { return { ...prevValues, ['ContactError']: Email_Field(value.Contact) } })
      // } else if (phoneTypeCode) {
      //   PhoneFieldNotReq(value.Contact) && setErrors(prevValues => { return { ...prevValues, ['ContactError']: PhoneFieldNotReq(value.Contact) } })
      // }

      // height validation
      // if (Heights(value.HeightFrom, value.HeightTo, 'Height') === 'true') {
      //   setErrors(prevValues => { return { ...prevValues, ['HeightError']: 'true' } })
      // } else {
      //   setErrors(prevValues => { return { ...prevValues, ['HeightError']: 'error' } })
      // }

      //Weight Validation
      if (Comparisionweight(value.WeightFrom, value.WeightTo, 'Weight') === 'true') {
        setErrors(prevValues => { return { ...prevValues, ['WeightError']: 'true' } })
      } else {
        setErrors(prevValues => { return { ...prevValues, ['WeightError']: 'error' } })
      }
      // //Age Validation
      if (Comparision(value.AgeFrom, value.AgeTo, 'Age') === 'true') {
        setErrors(prevValues => { return { ...prevValues, ['AgeError']: 'true' } })
      } else {
        setErrors(prevValues => { return { ...prevValues, ['AgeError']: 'error' } })
      }

    } else {
      // const LastNameErr = NameValidationCharacter(value.LastName, 'LastName');
      // const FirstNameErr = NameValidationCharacter(value.FirstName);
      // const MiddleNameErr = NameValidationCharacter(value.MiddleName);
      const LastNameErr = NameValidationCharacter(LastName, 'LastName', FirstName, MiddleName, LastName);
      const FirstNameErr = NameValidationCharacter(FirstName, 'FirstName', FirstName, MiddleName, LastName);
      const MiddleNameErr = NameValidationCharacter(MiddleName, 'MiddleName', FirstName, MiddleName, LastName);
      const CrimeLocationErr = onSelectLocation || value.Address === '' ? 'true' : 'true';
      const NameTypeIDErr = RequiredFieldIncident(value.NameTypeID);
      const NameReasonCodeIDErr = MstPage === "MST-Name-Dash" ? 'true' : RequiredFieldIncident(value.NameReasonCodeID);
      const SSNErr = SSN_Field(value.SSN);
      const DLError = value.DLStateID ? RequiredFieldIncident(value.DLNumber) : 'true'
      const ContactErr = phoneTypeCode ? phoneTypeCode === 'E' ? Email_Field(value.Contact) : PhoneFieldNotReq(value.Contact) : 'true';
      const OwnerPhoneNumberError = value.OwnerPhoneNumber ? PhoneField(value.OwnerPhoneNumber) : 'true'
      const OwnerFaxNumberError = value.OwnerFaxNumber ? FaxField(value.OwnerFaxNumber) : 'true'


      // const HeightError = value.HeightFrom && value.HeightTo ? Heights(value.HeightFrom, value.HeightTo) : 'true'

      setErrors(prevValues => {
        return {
          ...prevValues,
          ['LastNameError']: LastNameErr || prevValues['LastNameError'],
          ['FirstNameError']: FirstNameErr || prevValues['FirstNameError'],
          ['MiddleNameError']: MiddleNameErr || prevValues['MiddleNameError'],
          ['NameTypeIDError']: NameTypeIDErr || prevValues['NameTypeIDError'],
          ['CrimeLocationError']: CrimeLocationErr || prevValues['CrimeLocationError'],
          ['NameReasonCodeIDError']: NameReasonCodeIDErr || prevValues['NameReasonCodeIDError'],
          ['SSN']: SSNErr || prevValues['SSN'],
          ['ContactError']: ContactErr || prevValues['ContactError'],
          ['DLError']: DLError || prevValues['DLError'],
          ['OwnerPhoneNumberError']: OwnerPhoneNumberError || prevValues['OwnerPhoneNumberError'],
          ['OwnerFaxNumberError']: OwnerFaxNumberError || prevValues['OwnerFaxNumberError'],


          // ['HeightError']: HeightError || prevValues['HeightError'],
        }
      })

      // Phone Validation
      if (phoneTypeCode === 'E') {
        Email_Field(value.Contact) && setErrors(prevValues => { return { ...prevValues, ['ContactError']: Email_Field(value.Contact) } })
      } else if (phoneTypeCode) {
        PhoneFieldNotReq(value.Contact) && setErrors(prevValues => { return { ...prevValues, ['ContactError']: PhoneFieldNotReq(value.Contact) } })
      }

      // height validation
      if (Heights(value.HeightFrom, value.HeightTo, 'Height') === 'true') {
        setErrors(prevValues => { return { ...prevValues, ['HeightError']: 'true' } })
      } else {
        setErrors(prevValues => { return { ...prevValues, ['HeightError']: 'error' } })
      }

      // Weight Validation
      if (Comparisionweight(value.WeightFrom, value.WeightTo, 'Weight') === 'true') {
        setErrors(prevValues => { return { ...prevValues, ['WeightError']: 'true' } })
      } else {
        setErrors(prevValues => { return { ...prevValues, ['WeightError']: 'error' } })
      }
      //Age Validation
      if (Comparision(value.AgeFrom, value.AgeTo, 'Age') === 'true') {
        setErrors(prevValues => { return { ...prevValues, ['AgeError']: 'true' } })
      } else {
        setErrors(prevValues => { return { ...prevValues, ['AgeError']: 'error' } })
      }
      // Phone Validation
    }
  };


  // new changes for count
  // useEffect(() => {
  //   get_Name_Count(nameID, masterNameID, MstPage === "MST-Name-Dash" ? true : false);
  // }, [nameModalStatus])

  // const handleKeyDown = (e) => {
  //   const charCode = e.keyCode || e.which;
  //   const charStr = String.fromCharCode(charCode);
  //   const controlKeys = [8, 9, 13, 27, 37, 38, 39, 40, 46];
  //   const numpadKeys = [96, 97, 98, 99, 100, 101, 102, 103, 104, 105];
  //   const numpadSpecialKeys = [106, 107, 109, 110, 111];
  //   if (!charStr.match(/^[a-zA-Z]+$/) && !controlKeys.includes(charCode)) {
  //     e.preventDefault();
  //   }
  //   if ((charCode >= 48 && charCode <= 57) || numpadKeys.includes(charCode) || numpadSpecialKeys.includes(charCode)) {
  //     e.preventDefault();
  //   }
  // };

  const handleKeyDown = (e) => {
    const charCode = e.keyCode || e.which;
    const controlKeys = [8, 9, 13, 27, 37, 38, 39, 40, 46];
    const numKeys = Array.from({ length: 10 }, (_, i) => i + 48);
    const numpadKeys = Array.from({ length: 10 }, (_, i) => i + 96);

    if (!controlKeys.includes(charCode) && !numKeys.includes(charCode) && !numpadKeys.includes(charCode)) {
      e.preventDefault();
    }
  };



  // Check All Field Format is True Then Submit 
  const { LastNameError, OwnerPhoneNumberError, CrimeLocationError, OwnerFaxNumberError, FirstNameError, MiddleNameError, NameTypeIDError, CertifiedByIDError, NameReasonCodeIDError, ContactError, DLError, SSN, WeightError, HeightError, AgeError, DateOfBirthError, RaceIDError, SexIDError, } = errors

  useEffect(() => {

    if (nameTypeCode === 'B') {
      if (LastNameError === 'true' && FirstNameError === 'true' && CrimeLocationError === 'true' && OwnerPhoneNumberError === 'true' && OwnerFaxNumberError === 'true' && MiddleNameError === 'true' && NameTypeIDError === 'true' && NameReasonCodeIDError === 'true' && ContactError === 'true' && DLError === 'true' && SSN === 'true' && HeightError === 'true' && WeightError === 'true' && AgeError === 'true') {

        if (MstPage === "MST-Name-Dash") {
          if (masterNameID) {
            Update_Name();
          } else {
            InsertName();
          }
        }
        else {
          if (nameID && masterNameID) {
            Update_Name();
          } else {
            InsertName();
          }
        }
      }
    } else if (isAdult) {
      if (LastNameError === 'true' && OwnerPhoneNumberError === 'true' && CrimeLocationError === 'true' && FirstNameError === 'true' && OwnerFaxNumberError === 'true' && MiddleNameError === 'true' && NameTypeIDError === 'true' && NameReasonCodeIDError === 'true' && ContactError === 'true' && DLError === 'true' && SSN === 'true' && HeightError === 'true' && WeightError === 'true' && AgeError === 'true' && DateOfBirthError === 'true' && RaceIDError === 'true' && SexIDError === 'true') {
        if (MstPage === "MST-Name-Dash") {
          if (masterNameID) {
            Update_Name();
          } else {
            InsertName();
          }
        }
        else {
          if (nameID && masterNameID) {
            Update_Name();
          } else {
            InsertName();
          }
        }
      }
    } else if (LastNameError === 'true' && OwnerPhoneNumberError === 'true' && CrimeLocationError === 'true' && FirstNameError === 'true' && OwnerFaxNumberError === 'true' && MiddleNameError === 'true' && NameTypeIDError === 'true' && NameReasonCodeIDError === 'true' && ContactError === 'true' && DLError === 'true' && SSN === 'true' && HeightError === 'true' && WeightError === 'true' && AgeError === 'true') {
      if (MstPage === "MST-Name-Dash") {
        if (masterNameID) {
          Update_Name();
        } else {
          InsertName();
        }
      }
      else {
        if (nameID && masterNameID) {
          Update_Name();
        } else {
          InsertName();
        }
      }
    }
  }, [LastNameError, FirstNameError, OwnerPhoneNumberError, MiddleNameError, CrimeLocationError, OwnerFaxNumberError, DLError, NameTypeIDError, NameReasonCodeIDError, ContactError, SSN, WeightError, HeightError, AgeError, DateOfBirthError, RaceIDError, SexIDError])

  useEffect(() => {
    if (loginAgencyID) {
      if (nameTypeData.length === 0 || MstPage === "MST-Name-Dash") { dispatch(get_NameTypeData(loginAgencyID)); }
      get_Name_Drp_Data(loginAgencyID)
    }
    if (agencyOfficerDrpData?.length === 0) dispatch(get_AgencyOfficer_Data(loginAgencyID, IncID));

  }, [loginAgencyID])

  useEffect(() => {
    if (loginAgencyID || IncID) dispatch(get_AgencyOfficer_Data(loginAgencyID, IncID));
  }, [loginAgencyID, IncID]);


  const get_Name_Drp_Data = (loginAgencyID) => {
    const val = { AgencyID: loginAgencyID, }
    fetchPostData('MasterName/GetNameDropDown', val).then((data) => {
      if (data) {
        setAgeUnitDrpData(threeColArray(data[0]?.AgeUnit, 'AgeUnitID', 'Description', 'AgeUnitCode'));
        setEthinicityDrpData(Comman_changeArrayFormat(data[0]?.Ethnicity, 'EthnicityID', 'Description'));
        setSexIdDrp(Comman_changeArrayFormat(data[0]?.Gender, 'SexCodeID', 'Description'));
        setVerifyIdDrp(Comman_changeArrayFormat(data[0]?.HowVerify, 'VerifyID', 'Description'));
        setRaceIdDrp(Comman_changeArrayFormat(data[0]?.Race, 'RaceTypeID', 'Description'));
        setStateList(Comman_changeArrayFormat(data[0]?.State, "StateID", "State"));
        setSuffixIdDrp(Comman_changeArrayFormat(data[0]?.Suffix, 'SuffixID', 'Description'));
        setPhoneTypeIdDrp(threeColArray(data[0]?.ContactType, 'ContactPhoneTypeID', 'Description', 'ContactPhoneTypeCode'))

      } else {
        setAgeUnitDrpData([]); setEthinicityDrpData([]); setSexIdDrp([]); setVerifyIdDrp([]); setRaceIdDrp([]); setStateList([]); setSuffixIdDrp([]);
        setPhoneTypeIdDrp([]);
      }
    })
  };

  const get_Arrestee_Drp_Data = (IncidentID) => {
    const val = { 'MasterNameID': 0, 'IncidentID': IncidentID, 'IsOwnerName': true }
    fetchPostData('Arrest/GetDataDropDown_Arrestee', val).then((data) => {
      if (data) {
        setOwnerNameData(sixColArray(data, 'NameID', 'Arrestee_Name', 'LastName', 'DateOfBirth', 'Gendre_Description', 'Race_Description', 'NameID', 'MasterNameID'));
      } else {
        setOwnerNameData([])
      }
    })
  };

  useEffect(() => {
    if (loginAgencyID && value.NameTypeID) {
      GetReasonIdDrp(loginAgencyID, value.NameTypeID);
    }
  }, [value.NameTypeID])

  const GetSingleData = (nameID, masterNameID) => {
    const val = { 'NameID': nameID, 'MasterNameID': masterNameID, 'IsMaster': MstPage === "MST-Name-Dash" ? true : false, }
    const val2 = { 'MasterNameID': masterNameID, 'NameID': 0, 'IsMaster': MstPage === "MST-Name-Dash" ? true : false, }
    fetchPostData('MasterName/GetSingleData_MasterName', MstPage === "MST-Property-Dash" ? val2 : val).then((res) => {
      if (res) {

        setEditval(res); setNameSingleData(res);
      } else { setEditval([]); setNameSingleData([]) }
    })
  }




  const GetMasterSingleData = () => {

    const val = { 'MasterNameID': masterNameID, 'NameID': 0, }
    fetchPostData('MasterName/GetSingleData_MasterName', val).then((res) => {
      if (res) {
        setEditval(res);
      } else { setEditval() }
    })
  }

  const GetSingleDataPassion = (nameID, masterNameID) => {
    const val = { 'NameID': nameID, 'MasterNameID': masterNameID }
    fetchPostData('MasterName/GetSingleData_MasterName', val).then((res) => {
      if (res) {
        setPossenSinglData(res);
      } else { setPossenSinglData([]); }
    })
  }

  useEffect(() => {
    if (nameID || masterNameID) {
      if (editval.length > 0) {
        setAuditCount(true)
        get_Arrestee_Drp_Data(mainIncidentID);
        get_Name_MultiImage(nameID, masterNameID);
        if (editval[0]?.DLCountryID || editval[0]?.DLStateID || editval[0]?.DLNumber || editval[0]?.MaritalStatusID || editval[0]?.HairColorID || editval[0]?.BIVerifyID
          || editval[0]?.BICountryID || editval[0]?.BIStateID || editval[0]?.BICityID || editval[0]?.DLVerifyID || editval[0]?.ResidentID || editval[0]?.EyeColorID ||
          editval[0]?.BINationality || editval[0]?.BirthPlace || editval[0]?.IsUSCitizen
        ) {

          setcountStatus(true);
        }
        else {
          setcountStatus(false);
        }
        if (editval[0]?.FaceShapeID || editval[0]?.ComplexionID || editval[0]?.HairStyleID || editval[0]?.FacialHairID1 || editval[0]?.FacialHairID2 || editval[0]?.DistinctFeatureID1 || editval[0]?.DistinctFeatureID2 || editval[0]?.HairLengthID || editval[0]?.HairShadeID || editval[0]?.FacialOddityID1
          || editval[0]?.FacialOddityID2 || editval[0]?.FacialOddityID3 || editval[0]?.BodyBuildID || editval[0]?.SpeechID || editval[0]?.TeethID || editval[0]?.GlassesID || editval[0]?.Clothing || editval[0]?.HandednessID
        ) { setcountAppear(true); }
        else { setcountAppear(false); }
        if (editval[0]?.NameTypeID === 2) { GetBusinessTypeDrp(loginAgencyID) }
        setValue({
          ...value,
          'MasterNameID': editval[0]?.MasterNameID,
          'NameID': editval[0]?.NameID,
          'IsUnknown': editval[0]?.IsUnknown,
          'NameIDNumber': editval[0]?.NameIDNumber ? editval[0]?.NameIDNumber : 'Auto Generated',
          'checkVictem': editval[0]?.NewVictimID ? editval[0]?.NewVictimID[0]?.NewVictimID : "",
          'checkOffender': editval[0]?.NewOffenderID ? editval[0]?.NewOffenderID[0]?.NewOffenderID : "",
          'checkArrest': editval[0]?.ArrestID ? editval[0]?.ArrestID[0]?.ArrestID : "",
          // DropDown
          'NameTypeID': editval[0]?.NameTypeID, 'BusinessTypeID': editval[0]?.BusinessTypeID, 'SuffixID': editval[0]?.SuffixID, 'VerifyID': editval[0]?.DLVerifyID,
          'SexID': editval[0]?.SexID, 'RaceID': editval[0]?.RaceID, 'PhoneTypeID': editval[0]?.PhoneTypeID, 'EthnicityID': editval[0]?.EthnicityID, 'AgeUnitID': editval[0]?.AgeUnitID,
          'NameReasonCodeID': editval[0]?.ReasonCode ? changeArray(editval[0]?.ReasonCode, 'NameReasonCodeID') : '', 'CertifiedByID': editval[0]?.CertifiedByID,
          // checkbox
          'IsJuvenile': editval[0]?.IsJuvenile,
          'IsVerify': editval[0]?.IsVerify,
          'IsUnListedPhNo': editval[0]?.IsUnListedPhNo,
          //textbox
          'OwnerFaxNumber': editval[0]?.OwnerFaxNumber, 'OwnerPhoneNumber': editval[0]?.OwnerPhoneNumber, 'OwnerNameID': editval[0]?.OwnerNameID,
          'LastName': editval[0]?.LastName ? editval[0]?.LastName?.trim() : editval[0]?.LastName, 'FirstName': editval[0]?.FirstName, 'MiddleName': editval[0]?.MiddleName,
          'SSN': editval[0]?.SSN, 'WeightFrom': editval[0]?.WeightFrom, 'WeightTo': editval[0]?.WeightTo,
          'HeightFrom': editval[0]?.HeightFrom, 'HeightTo': editval[0]?.HeightTo, 'Address': editval[0]?.Address,
          'Contact': editval[0]?.Contact,
          //  'AgeFrom': editval[0]?.AgeFrom ? editval[0]?.AgeFrom : '0',
          'AgeFrom': editval[0]?.AgeFrom === null ? null : editval[0]?.AgeFrom ?? '0',

          'AgeTo': editval[0]?.AgeTo ? editval[0]?.AgeTo : '',
          //Datepicker
          'DateOfBirth': editval[0]?.DateOfBirth ? getShowingWithOutTime(editval[0]?.DateOfBirth) : '',
          'CertifiedDtTm': editval[0]?.CertifiedDtTm ? getShowingDateText(editval[0]?.CertifiedDtTm) : null,
          'Years': editval[0]?.Years,
          'NameLocationID': editval[0]?.NameLocationID,
          'ModifiedByUserFK': loginPinID, 'AgencyID': loginAgencyID,
          'DLNumber': editval[0]?.DLNumber,
          'DLStateID': editval[0]?.DLStateID,
        })


        setPossessionID(editval[0]?.OwnerNameID)
        // ---------------------Name_Non_Verify_Add--------------
        GetReasonIdDrp(loginAgencyID, editval[0]?.NameTypeID);
        setPhoneTypeCode(Get_PhoneType_Code(editval, phoneTypeIdDrp));
        setDobDate(editval[0]?.DateOfBirth ? new Date(editval[0]?.DateOfBirth) : '');
        setIsAdult(editval[0]?.ReasonCode?.some(function (item) { return item.ReasonCode_Description === "Adult Arrest" }));
        // setOnSelectLocation(false);
        //--------------get_Non_Verify_Add-------------------
        if (!editval[0]?.IsVerify && editval[0]?.NameLocationID) {
          get_Add_Single_Data(editval[0]?.NameLocationID);
        }

        // NameTypeCode
        setNameTypeCode(editval[0]?.NameTypeCode);
        // Dev0001 -----------  for tabs 
        setIsBusinessName(editval[0]?.NameTypeCode === 'B' ? true : false)
        if (editval[0]?.Years) {
          const Years = editval[0]?.Years.split(' ');
          setYearsVal(Years[1])
        }
        setMultiSelected({
          optionSelected: editval[0]?.ReasonCode ? fourColArray(editval[0]?.ReasonCode, 'NameReasonCodeID', 'ReasonCode_Description', 'IsVictimName', 'IsOffenderName'
          ) : '',
        });
        // setShowOffender(editval[0]?.ReasonCode?.some(function (item) { return item.ReasonCode_Description === "Offender" }))
        // setShowVictim(editval[0]?.ReasonCode?.some(function (item) { return item.ReasonCode_Description === "Victim" }));
        setShowOffender(editval[0]?.ReasonCode?.some(function (item) { return item.IsOffenderName }))
        setShowVictim(editval[0]?.ReasonCode?.some(function (item) { return item.IsVictimName }));
        setshowWarrant(editval[0]?.ReasonCode?.some(function (item) { return item.ReasonCode === "WAR" }));

      }
    } else {
      if (!changesStatus) {
        setAuditCount(false);
        setShowOffender(false); setShowVictim(false);
        setshowWarrant(false);
        setValue({
          ...value,
          'MasterNameID': '',
          'NameID': '',
          'NameIDNumber': 'Auto Generated',
          // DropDown
          'BusinessTypeID': '', 'SuffixID': '', 'VerifyID': '', 'SexID': '', 'EthnicityID': '',
          'RaceID': '', 'PhoneTypeID': '', 'NameReasonCodeID': '', 'CertifiedByID': '', 'AgeUnitID': '',
          // checkbox
          // 'IsJuvenile': '', 
          'IsVerify': true, 'IsUnListedPhNo': '',
          //textbox
          'LastName': '', 'FirstName': '', 'MiddleName': '', 'SSN': '',
          'WeightFrom': '', 'WeightTo': '', 'HeightFrom': '',
          'HeightTo': '', 'Address': '', 'Contact': '',
          //Datepicker
          'DateOfBirth': '', 'CertifiedDtTm': null,
          'AgeFrom': '', 'AgeTo': '', 'Years': '', 'checkVictem': 0, 'checkOffender': 0, 'checkArrest': 0,
          'DLNumber': '',
          'DLStateID': '',
        }); setPhoneTypeCode('')
      }
      const id = nameTypeData?.filter((val) => { if (val.id === "I") return val })
      if (id.length > 0) {
        setValue(prevValues => { return { ...prevValues, ['NameTypeID']: id[0].value } })

        setNameTypeCode(id[0].NameTypeCode);
        // Dev0001 --------------------------------------- for tabs in BusinessName Type
        setIsBusinessName(false);
      }
      setMultiSelected({ optionSelected: [], });
    }
  }, [editval])

  const LastFirstNameOnBlur = (e) => {
    if (!called) {
      if (e.target.name === 'LastName') {
        if (value?.LastName && value?.FirstName) {
          getNameSearchPopup(loginAgencyID, value?.NameTypeID, value?.LastName, value?.FirstName, null, null)
        }
      } else if (e.target.name === 'FirstName') {
        if (value?.LastName && value?.FirstName) {
          getNameSearchPopup(loginAgencyID, value?.NameTypeID, value?.LastName, value?.FirstName, null, null)
        }
      }
    }

  }

  const getNameSearchPopup = async (loginAgencyID, NameTypeID, LastName, FirstName, MiddleName, DateOfBirth, SSN, type) => {
    if (LastName && DateOfBirth || FirstName || MiddleName || SSN) {
      fetchPostData("MasterName/Search_Name", {
        "NameTypeID": NameTypeID, "LastName": LastName, "FirstName": FirstName ? FirstName : null, "MiddleName": MiddleName ? MiddleName : null, "DateOfBirth": DateOfBirth ? DateOfBirth : null, "SSN": SSN ? SSN : null, 'AgencyID': loginAgencyID ? loginAgencyID : null,
      }).then((data) => {
        if (data.length > 0) {
          setNameSearchValue(data); setNameSearchStatus(true)
        } else {
          setNameSearchValue([]);
          if (type) toastifyError('No Name Available');
          setNameSearchStatus(false)
        }
      })
    } else {
      setNameSearchStatus(false);
      toastifyError('Empty Feild');
    }
  }



  const getNameSearch = async (loginAgencyID, NameTypeID, LastName, FirstName, MiddleName, DateOfBirth, SSN, HeightFrom, HeightTo, WeightFrom, WeightTo, EthnicityID, RaceID, SexID, PhoneTypeID, Contact, type) => {
    if (LastName || DateOfBirth || FirstName || MiddleName || SSN || SexID || HeightFrom || HeightTo || DateOfBirth || WeightFrom || WeightTo || EthnicityID || RaceID || PhoneTypeID || Contact || value.NameReasonCodeID || value.Address || value.AgeFrom || value.AgeTo || value.AgeUnitID || value.DLNumber || value.DLStateID || value.SuffixID) {
      setIsLoading(true);
      setChangesStatus(false);
      fetchPostData("MasterName/Search_Name", {
        "NameTypeID": NameTypeID, "LastName": LastName, "FirstName": FirstName ? FirstName : null, "MiddleName": MiddleName ? MiddleName : null, "SSN": SSN ? SSN : null, 'AgencyID': loginAgencyID ? loginAgencyID : null,
        NameIDNumber: "", 'ReasonCodeList': value.NameReasonCodeID ? JSON.stringify(value.NameReasonCodeID) : '', SuffixID: value.SuffixID, 'DateOfBirth': DateOfBirth, 'SexID': SexID, 'RaceID': RaceID, 'EthnicityID': EthnicityID, HairColorID: "", EyeColorID: "", 'WeightFrom': WeightFrom, 'WeightTo': WeightTo, SMTTypeID: "", SMTLocationID: "", SMT_Description: "",
        IncidentNumber: "", IncidentNumberTo: "", ReportedDate: "", ReportedDateTo: "", 'HeightFrom': HeightFrom, 'HeightTo': HeightTo, 'PhoneTypeID': PhoneTypeID, 'Contact': Contact, 'Address': value.Address ? value.Address : '', 'AgeFrom': value.AgeFrom ? value.AgeFrom : '', 'AgeTo': value.AgeTo ? value.AgeTo : '', 'AgeUnitID': value.AgeUnitID ? value.AgeUnitID : '',
        'DLNumber': value.DLNumber ? value.DLNumber : '',
        'DLStateID': value.DLStateID ? value.DLStateID : '',

      }).then((data) => {
        if (data.length > 0) {
          setIsLoading(false);

          const [{ MasterNameID, NameIDNumber }] = data;
          // setMasterNameID(MasterNameID);
          setNameIDNumber(NameIDNumber);
          setNameSearchValue(data); setNameSearchStatus(true)
        } else {
          setNameSearchValue([]);
          setIsLoading(false);
          if (type) toastifyError('No Name Available');
          setNameSearchStatus(false)
        }
      })
    } else {
      setNameSearchStatus(false);
      toastifyError('No data available');
    }
  }

  const set_Edit_Value = (row) => {
    if (row.NameID || row.MasterNameID) {
      Reset();
      GetSingleData(row.NameID, row.MasterNameID);
      if (isCad) {
        navigate(`/cad/dispatcher?IncId=${stringToBase64(IncID)}&IncNo=${IncNo}&IncSta=${IncSta}&NameID=${stringToBase64(row?.NameID)}&MasterNameID=${stringToBase64(row?.MasterNameID)}&NameStatus=${true}`)
      } else {
        navigate(`/Name-Home?IncId=${stringToBase64(IncID)}&IncNo=${IncNo}&IncSta=${IncSta}&NameID=${stringToBase64(row?.NameID)}&MasterNameID=${stringToBase64(row?.MasterNameID)}&NameStatus=${true}`)
      }
      get_Name_Count(row.NameID, row.MasterNameID, MstPage === "MST-Name-Dash" ? true : false);
      setNameID(row.NameID)
      setMasterNameID(row?.MasterNameID)
      setUpdateStatus(updateStatus + 1);
      setuploadImgFiles('')
      // setNameStatus(true);
      setErrors({
        ...value, 'NameTypeIDError': '', 'LastNameError': '', 'FirstNameError': '', 'MiddleNameError': '', 'NameReasonCodeIDError': '', 'CertifiedByIDError': '', 'ContactError': 'true', 'WeightError': 'true', 'HeightError': 'true', 'AgeError': 'true', 'DateOfBirthError': '', 'RaceIDError': '', 'SexIDError': '', 'AddressError': 'true', 'SSN': '', 'DLError': 'true',
      })
    }
  }

  const setStatusFalse = () => {
    if (MstPage === "MST-Name-Dash") {
      navigate(`/Name-Home?page=MST-Name-Dash&IncId=${0}&IncNo=${0}&IncSta=${IncSta}&NameID=${0}&MasterNameID=${0}&NameStatus=${false}`)
      setMasterNameID('');
      setNameID('');
      setClickedRow(null); Reset();
      setUpdateStatus(updateStatus + 1);
      setLocationStatus(true);
      get_Name_Count('');
      setIsAdult(false);
      setPossessionID(''); setPossenSinglData([]);
    }
    else {
      if (isCad) {
        navigate(`/cad/dispatcher?IncId=${stringToBase64(IncID)}&IncNo=${IncNo}&IncSta=${IncSta}&NameID=${0}&MasterNameID=${0}&NameStatus=${false}`)
      } else {
        navigate(`/Name-Home?IncId=${stringToBase64(IncID)}&IncNo=${IncNo}&IncSta=${IncSta}&NameID=${0}&MasterNameID=${0}&NameStatus=${false}`)
      }
      setMasterNameID('');
      setNameID('');
      setClickedRow(null); Reset();
      setUpdateStatus(updateStatus + 1);
      setLocationStatus(true);
      get_Name_Count('');
      setIsAdult(false);
      setPossessionID(''); setPossenSinglData([]);
    }


  }

  const columns = [
    {
      name: 'MNI',
      selector: (row) => row.NameIDNumber,
      sortable: true
    },
    {
      name: 'Name',
      selector: (row) => row.FullName,
      sortable: true
    },
    {
      name: 'Gender',
      selector: (row) => row.Gender,
      sortable: true
    },
    {
      name: 'DOB',
      selector: (row) => row.DateOfBirth ? getShowingWithOutTime(row.DateOfBirth) : " ",
      sortable: true
    },
    // {
    //   name: 'Race',
    //   selector: (row) => row.Description_Race,
    //   sortable: true
    // },
    {
      name: 'SSN',
      selector: (row) => row.SSN,
      sortable: true
    },
    {
      name: 'Reason Code',
      selector: (row) => row?.NameReasonCode || '',
      format: (row) => (
        <>{row?.NameReasonCode ? row?.NameReasonCode.substring(0, 50) : ''}{row?.NameReasonCode?.length > 40 ? '  . . .' : null} </>
      ),
      // selector: (row) => <>{row?.NameReasonCode ? row?.NameReasonCode.substring(0, 50) : ''}{row?.NameReasonCode?.length > 40 ? '  . . .' : null} </>,
      sortable: true
    },

  ]

  const GetReasonIdDrp = (loginAgencyID, id) => {
    const val = { AgencyID: loginAgencyID, CategoryID: id, }
    fetchPostData('NameReasonCode/GetDataDropDown_NameReasonCode', val).then((data) => {
      if (data) {
        setReasonIdDrp(Comman_changeArrayFormat(data, 'NameReasonCodeID', 'Description'))
        if (openPageName === 'Victim') {
          const id = data?.filter((val) => { if (val?.ReasonCode === "VIC") return val });
          if (id?.length > 0) {
            setMultiSelected({
              optionSelected: id ? fourColArray(id, 'NameReasonCodeID', 'Description', 'IsVictimName', 'IsOffenderName') : '',
            });
            let finalValueList = id?.map((item) => item?.NameReasonCodeID);
            setValue({ ...value, ['NameReasonCodeID']: finalValueList })
          }
        } else if (openPageName === 'Offender') {
          const id = data?.filter((val) => { if (val?.ReasonCode === "OFF") return val });
          if (id?.length > 0) {
            setMultiSelected({
              optionSelected: id ? fourColArray(id, 'NameReasonCodeID', 'Description', 'IsVictimName', 'IsOffenderName') : '',
            });
            let finalValueList = id?.map((item) => item?.NameReasonCodeID);
            setValue({ ...value, ['NameReasonCodeID']: finalValueList })
          }
        }
      } else {
        setReasonIdDrp([]);
      }
    })
  }

  const GetBusinessTypeDrp = (loginAgencyID) => {
    const val = { AgencyID: loginAgencyID, }
    fetchPostData('NameBusinessType/GetDataDropDown_NameBusinessType', val).then((data) => {
      if (data) {
        setBusinessTypeDrp(Comman_changeArrayFormat(data, 'NameBusinessTypeID', 'Description'))
      } else {
        setBusinessTypeDrp([]);
      }
    })
  };

  const ChangeNameType = (e, name) => {
    setStatesChangeStatus(true)
    if (e) {
      if (name === 'NameTypeID') {
        setValue({
          ...value,
          [name]: e.value,
          'NameIDNumber': 'Auto Generated', 'BusinessTypeID': '', 'SuffixID': '', 'VerifyID': '', 'SexID': '', 'RaceID': '', 'PhoneTypeID': '', 'NameReasonCodeID': [], 'CertifiedByID': '', 'AgeUnitID': '',
          'IsVerify': true, 'IsUnListedPhNo': '', 'LastName': '', 'FirstName': '', 'MiddleName': '', 'SSN': '', 'WeightFrom': '', 'WeightTo': '', 'HeightFrom': '', 'HeightTo': '', 'Address': '', 'Contact': '', 'DateOfBirth': '', 'CertifiedDtTm': null, 'AgeFrom': '', 'AgeTo': '', 'Years': '', 'DLStateID': '',
          'IsJuvenile': e.id === 'B' ? null : value.IsJuvenile
        });
        setErrors({
          ...value, 'NameTypeIDError': '', 'LastNameError': '', 'FirstNameError': '', 'MiddleNameError': '', 'NameReasonCodeIDError': '', 'CertifiedByIDError': '', 'ContactError': 'true', 'WeightError': 'true', 'HeightError': 'true', 'AgeError': 'true', 'DateOfBirthError': '', 'RaceIDError': '', 'SexIDError': '', 'AddressError': 'true', 'SSN': '', 'DLError': 'true',
        })
        setLocationStatus(true);
        setMultiSelected({ optionSelected: [] }); setPhoneTypeCode(''); setNameTypeCode(e.id); setChangesStatus(true);
        if (e.id === 'B') { setIsBusinessName(true); GetBusinessTypeDrp(loginAgencyID); get_Arrestee_Drp_Data(mainIncidentID); } else { setIsBusinessName(false); }


      } else {
        setStatesChangeStatus(true)
        setChangesStatus(true)
        setValue({
          ...value,
          [name]: e.value,
          'IsJuvenile': e.id === 'B' ? null : value.IsJuvenile
        })

      }
    } else {
      setStatesChangeStatus(true)
      setChangesStatus(true)
      setValue({
        ...value,
        [name]: null
      }); setNameTypeCode(''); setIsBusinessName(false); setPhoneTypeCode('')
    }
  }

  const ChangePhoneType = (e, name) => {
    setStatesChangeStatus(true)
    if (e) {
      if (name === 'PhoneTypeID') {
        setPhoneTypeCode(e.id)
        setChangesStatus(true)
        setValue({
          ...value,
          [name]: e.value, ['Contact']: "", ['IsUnListedPhNo']: false
        })
      }
      setChangesStatus(true)
    } else if (e === null) {
      if (name === 'PhoneTypeID') {
        setChangesStatus(true);
        setValue({ ...value, ['PhoneTypeID']: "", ['Contact']: "", ['IsUnListedPhNo']: false });

        setErrors({ ...errors, ['ContactError']: 'true', ['NameTypeIDError']: '' });
        setPhoneTypeCode('')
      }
    } else {
      setChangesStatus(true)
      setValue({
        ...value,
        [name]: null,
        ['IsUnListedPhNo']: false
      });

      setPhoneTypeCode('')
    }
  }

  const ChangeDropDown = (e, name) => {
    setStatesChangeStatus(true); setChangesStatus(true)

    if (e) {
      if (name === 'DLStateID') {
        setDlNumber(true);
        setValue({ ...value, [name]: e.value, ['DLNumber']: '', ['VerifyID']: '' });
      } else if (name === 'OwnerNameID') {
        setValue({ ...value, [name]: e.value })
        setPossessionID(e.value);
        setPossenSinglData([]);
      } else {
        setValue({ ...value, [name]: e.value });
      }
    } else {
      if (name === 'DLStateID') {
        setValue({ ...value, [name]: null, ['DLNumber']: '', ['VerifyID']: '' });
      } else {
        setValue({ ...value, [name]: null, });
        setErrors({ ...errors, ['DLError']: '', });
        setDlNumber(false); setPossessionID(''); setPossenSinglData([]);
      }
    }
  };


  const HandleChange = (e) => {
    setStatesChangeStatus(true);
    if (e.target.name === 'IsVerify' || e.target.name === 'IsUnListedPhNo' || e.target.name === 'IsUnknown') {
      if (e.target.name === 'IsVerify') {
        if (e.target.checked && addVerifySingleData.length > 0) {
          setModalStatus(false);
          setLocationStatus(true); setAddVerifySingleData([]);
          setValue(pre => { return { ...pre, ['Address']: '', [e.target.name]: e.target.checked, } });

        } else {
          setValue(pre => { return { ...pre, [e.target.name]: e.target.checked, } });
          setModalStatus(true);
          setLocationStatus(false);
        }
      } else {
        setChangesStatus(true)
        setValue({ ...value, [e.target.name]: e.target.checked });
      }
      if (e.target.name === 'IsUnknown') {
        if (e.target.checked === true) {
          setValue(pre => {
            return {
              ...pre, ['FirstName']: '', ['MiddleName']: '', ['SSN']: '', ['DateOfBirth']: '', ['DLNumber']: '', ['DLStateID']: '',
              ['AgeFrom']: '', ['AgeTo']: '', [e.target.name]: e.target.checked, ['LastName']: 'Unknown', ['VerifyID']: '', ['SexID']: '', ['RaceID']: '',
            }
          });
          setErrors({ ...errors, ['DLError']: '', });
          setDobDate('');
        } else {
          setValue(pre => { return { ...pre, ['LastName']: '', [e.target.name]: e.target.checked, } });
        }
      }

    }
    else if (e.target.name === 'Contact') {
      if (phoneTypeCode === 'E') {
        setChangesStatus(true)
        setValue({ ...value, [e.target.name]: e.target.value });
      } else {
        let ele = e.target.value.replace(/\D/g, '');
        if (ele.length === 10) {
          setValue(pre => { return { ...pre, ['IsUnListedPhNo']: 'true', } });
          const cleaned = ('' + ele).replace(/\D/g, '');
          const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
          if (match) {
            setChangesStatus(true)
            setValue({
              ...value,
              [e.target.name]: match[1] + '-' + match[2] + '-' + match[3]
            })
          }
        } else {
          ele = e.target.value.split('-').join('').replace(/\D/g, '');
          setChangesStatus(true)
          setValue({
            ...value,
            [e.target.name]: ele,
            ['IsUnListedPhNo']: ele === '' ? false : value['IsUnListedPhNo'],
          })
        }
      }
    }
    else if (e.target.name === 'DLNumber') {
      const normalizedValue = e.target.value.trim();
      setChangesStatus(true);
      setValue({
        ...value,
        [e.target.name]: normalizedValue,
      });

    }
    else if (e.target.name === 'OwnerPhoneNumber') {
      let ele = e.target.value.replace(/\D/g, '');
      if (ele.length <= 10) {
        const cleaned = ('' + ele).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          setChangesStatus(true);
          setValue({
            ...value,
            [e.target.name]: match[1] + '-' + match[2] + '-' + match[3],
          });
        } else {
          setChangesStatus(true);
          setValue({
            ...value,
            [e.target.name]: ele,
          });
        }
      }
    }
    else if (e.target.name === 'OwnerFaxNumber') {
      let ele = e.target.value.replace(/\D/g, '');
      if (ele.length <= 10) {
        const cleaned = ('' + ele).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          setChangesStatus(true);
          setValue({
            ...value,
            [e.target.name]: match[1] + '-' + match[2] + '-' + match[3],
          });
        } else {
          setChangesStatus(true);
          setValue({
            ...value,
            [e.target.name]: ele,
          });
        }
      }
    }
    else if (e.target.name === 'SSN') {
      let ele = e.target.value.replace(/[^0-9\s]/g, "")
      if (ele.length === 9) {
        const cleaned = ('' + ele).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);
        if (match) {
          setChangesStatus(true)
          setValue({
            ...value,
            [e.target.name]: match[1] + '-' + match[2] + '-' + match[3]
          });
          getNameSearchPopup(loginAgencyID, value?.NameTypeID, null, null, null, null, match[1] + '-' + match[2] + '-' + match[3])
        }
      } else {
        ele = e.target.value.split('-').join('').replace(/[^0-9\s]/g, "");
        setChangesStatus(true)
        setValue({
          ...value,
          [e.target.name]: ele
        });
      }
    }
    else if (e.target.name === 'WeightTo' || e.target.name === 'WeightFrom') {
      const checkNumber = e.target.value.replace(/[^0-9\s]/g, "");
      setChangesStatus(true)
      if (e.target.name === 'WeightFrom') {
        if (checkNumber === '' || checkNumber === '0') {
          setValue({ ...value, WeightFrom: checkNumber, WeightTo: '' });
        } else {
          setValue({ ...value, [e.target.name]: checkNumber });
        }
      } else {
        setValue({ ...value, [e.target.name]: checkNumber });
      }
      // setValue({ ...value, [e.target.name]: checkNumber })
    } else if (e.target.name === 'HeightFrom') {
      let ele = e.target.value.replace(/[^0-9\s]/g, "");
      setValue({
        ...value,
        [e.target.name]: ele, HeightTo: ''
      })

    } else if (e.target.name === 'HeightTo') {
      let ele = e.target.value.replace(/[^0-9\s]/g, "");
      setValue({
        ...value,
        [e.target.name]: ele,
      })

    }
    else if (e.target.name === 'AgeFrom') {
      const checkNumber = e.target.value.replace(/[^0-9]/g, "");
      setChangesStatus(true);
      setDobDate('');
      //  setValue({ ...value, ['Years']: 0, ['DateOfBirth']: 0 })
      setValue({ ...value, AgeFrom: checkNumber, AgeTo: '', AgeUnitID: '', ['Years']: 0, ['DateOfBirth']: null });
    }
    else if (e.target.name === 'AgeTo') {
      const checkNumber = e.target.value.replace(/[^0-9]/g, "");
      setChangesStatus(true);
      setDobDate('');
      //  setValue({ ...value, ['Years']: 0, ['DateOfBirth']: 0 })
      setValue({ ...value, [e.target.name]: checkNumber, ['Years']: 0, ['DateOfBirth']: null });
    }
    else setValue({ ...value, [e.target.name]: e.target.value })
  };

  const InsertName = () => {

    const AgencyID = loginAgencyID;
    const IncidentID = mainIncidentID;
    const CreatedByUserFK = loginPinID;
    const { NameTypeID, BusinessTypeID, IsMaster, NameIDNumber, IsUnListedPhNo, IsVerify, IsCurrentPh, SuffixID, VerifyID, SexID, RaceID, PhoneTypeID, NameReasonCodeID, CertifiedByID, EthnicityID, AgeUnitID, IsJuvenile, LastName, FirstName, MiddleName, SSN, WeightFrom, WeightTo, HeightFrom, HeightTo, Address, Contact, OwnerNameID, OwnerPhoneNumber, OwnerFaxNumber, DateOfBirth, CertifiedDtTm, AgeFrom, AgeTo, Years, ModifiedByUserFK, MasterNameID, NameID, ArrestID, WarrantID, TicketID, checkVictem, EventType,
      checkOffender, checkArrest, NameLocationID, DLNumber, DLStateID, IsUnknown
    } = value;

    const trimmedFirstName = FirstName?.trim();
    const trimmedMiddleName = MiddleName?.trim();

    const val = {
      'AgencyID': AgencyID, 'NameIDNumber': NameIDNumber, 'NameTypeID': NameTypeID, 'EventType': EventType, 'IsMaster': IsMaster, 'IsUnListedPhNo': IsUnListedPhNo, 'IsVerify': IsVerify, 'PhoneTypeID': PhoneTypeID, 'OwnerFaxNumber': OwnerFaxNumber, 'IsCurrentPh': IsCurrentPh, 'BusinessTypeID': BusinessTypeID, 'SuffixID': SuffixID, 'DLVerifyID': VerifyID, 'SexID': SexID, 'RaceID': RaceID, 'PhoneTypeID': PhoneTypeID, 'NameReasonCodeID': NameReasonCodeID, 'CertifiedByID': CertifiedByID, 'EthnicityID': EthnicityID, 'AgeUnitID': AgeUnitID, 'IsJuvenile': IsJuvenile, 'LastName': LastName ? LastName : null, 'FirstName': trimmedFirstName ? trimmedFirstName : null, 'MiddleName': trimmedMiddleName ? trimmedMiddleName : null, 'SSN': SSN, 'WeightFrom': WeightFrom, 'WeightTo': WeightTo, 'HeightFrom': HeightFrom, 'HeightTo': HeightTo, 'Address': Address, 'Contact': Contact, 'OwnerNameID': OwnerNameID, 'OwnerPhoneNumber': OwnerPhoneNumber,
      'OwnerFaxNumber': OwnerFaxNumber, 'DateOfBirth': DateOfBirth, 'CertifiedDtTm': CertifiedDtTm, 'AgeFrom': AgeFrom, 'AgeTo': AgeTo, 'Years': Years, 'ModifiedByUserFK': ModifiedByUserFK, 'MasterNameID': MasterNameID, 'NameID': NameID, 'ArrestID': ArrestID, 'WarrantID': WarrantID, 'TicketID': TicketID, 'checkVictem': checkVictem, 'checkOffender': checkOffender, 'checkArrest': checkArrest, 'CreatedByUserFK': CreatedByUserFK, 'IncidentID': IncidentID, 'NameLocationID': NameLocationID, 'DLNumber': DLNumber, 'DLStateID': DLStateID, 'IsUnknown': IsUnknown
    };



    const fetchParams = MstPage === "MST-Name-Dash" ?
      { "MasterNameID": masterNameID, "SSN": SSN, 'NameID': NameID,'AgencyID' : AgencyID } :
      { "SSN": SSN, "IncidentID": mainIncidentID, "MasterNameID": masterNameID, 'NameID': NameID,'AgencyID' : AgencyID };

    fetchPostData("MasterName/GetData_EventNameExists", fetchParams).then((data) => {
      setErrors({ ...errors, ['ContactError']: 'true', ['NameTypeIDError']: '', });
      if (data) {

        if (data[0]?.Total === 0) {
          setsaveValue(true);
          AddDeleteUpadate('MasterName/Insert_MasterName', val).then((res) => {
            if (res.success) {
              if (MstPage === "MST-Name-Dash") {
                if (isCad) {
                  navigate(`/cad/dispatcher?page=MST-Name-Dash&NameID=${stringToBase64(res?.NameID)}&MasterNameID=${stringToBase64(res?.MasterNameID)}&ModNo=${res?.NameNumber}&NameStatus=${true}`);

                } else {
                  navigate(`/Name-Home?page=MST-Name-Dash&NameID=${stringToBase64(res?.NameID)}&MasterNameID=${stringToBase64(res?.MasterNameID)}&ModNo=${res?.NameNumber}&NameStatus=${true}`);
                }
              }
              else {
                if (isCad) {
                  navigate(`/cad/dispatcher?IncId=${stringToBase64(IncID)}&IncNo=${IncNo}&IncSta=${IncSta}&NameID=${stringToBase64(res?.NameID)}&MasterNameID=${stringToBase64(res?.MasterNameID)}&NameStatus=${true}`)
                } else {
                  navigate(`/Name-Home?IncId=${stringToBase64(IncID)}&IncNo=${IncNo}&IncSta=${IncSta}&NameID=${stringToBase64(res?.NameID)}&MasterNameID=${stringToBase64(res?.MasterNameID)}&NameStatus=${true}`)
                }
              }
              toastifySuccess(res.Message);
              setsaveValue(false);
              setValue({
                ...value,
                'NameIDNumber': 'Auto Generated',
                // DropDown
                'BusinessTypeID': '', 'SuffixID': '', 'VerifyID': '', 'SexID': '',
                'RaceID': '', 'PhoneTypeID': '', 'NameReasonCodeID': [], 'CertifiedByID': '', 'AgeUnitID': '',
                'IsVerify': true, 'IsUnListedPhNo': '', 'LastName': '', 'FirstName': '', 'MiddleName': '', 'SSN': '',
                'WeightFrom': '', 'WeightTo': '', 'HeightFrom': '', 'HeightTo': '', 'Address': '',
                'Contact': '', 'OwnerPhoneNumber': '', 'OwnerFaxNumber': '', 'OwnerNameID': '',
                'DateOfBirth': '', 'CertifiedDtTm': null, 'AgeFrom': '', 'AgeTo': '', 'Years': '',
                'MasterNameID': '', 'NameID': '', 'EthnicityID': '',
                'DLNumber': "",
                'DLStateID': '',
                'IsUnknown': '', 'IsJuvenile': '',
              })
              get_NameTypeData(loginAgencyID);
              get_Data_Name(mainIncidentID, MstPage === "MST-Name-Dash" ? true : false);
              //  Reset()
              setChangesStatus(false);
              setLocationStatus(true);
              setUpdateStatus(updateStatus + 1);
              setIsAdult(false);
              setStatesChangeStatus(false);
              get_Incident_Count(mainIncidentID);
              if (uploadImgFiles?.length > 0) {
                upload_Image_File(res?.NameID, res?.MasterNameID)
                setuploadImgFiles('')
              }
              // setTimeout(() => {
              //   if (firstNameInputRef.current) {
              //     firstNameInputRef.current.focus();
              //     firstNameInputRef.current.setSelectionRange(0, 0); 
              //   }
              // }, 0);
              setErrors({ ...errors, ['AddressError']: 'true', ['WeightError']: 'true', ['AgeError']: 'true', ['ContactError']: 'true', ['NameTypeIDError']: '', });
            } else {
              toastifyError(res.Message); setErrors({ ...errors, ['NameTypeIDError']: '', ['ContactError']: '', });
              setChangesStatus(false)
            }
          })
        } else {
          toastifyError('SSN Already exist ');
        }
      }
    })

  }

  const Update_Name = () => {
    const AgencyID = loginAgencyID;
    const IncidentID = mainIncidentID;
    const CreatedByUserFK = loginPinID;
    const { NameTypeID, BusinessTypeID, SuffixID, IsUnListedPhNo, IsMaster, IsVerify, IsCurrentPh, VerifyID, NameIDNumber, SexID, RaceID, PhoneTypeID, NameReasonCodeID, CertifiedByID, EthnicityID, AgeUnitID, IsJuvenile, LastName, FirstName, MiddleName, SSN, WeightFrom, WeightTo, HeightFrom, HeightTo, Address, Contact, OwnerNameID, OwnerPhoneNumber, OwnerFaxNumber, DateOfBirth, CertifiedDtTm, AgeFrom, AgeTo, Years, ModifiedByUserFK, MasterNameID, NameID, ArrestID, WarrantID, TicketID, checkVictem, EventType,
      checkOffender, checkArrest, NameLocationID, DLNumber, DLStateID, IsUnknown
    } = value;

    const trimmedFirstName = FirstName?.trim();
    const trimmedMiddleName = MiddleName?.trim();
    const val = {
      'AgencyID': AgencyID, 'NameIDNumber': NameIDNumber, 'NameTypeID': NameTypeID, 'EventType': EventType, 'IsMaster': IsMaster, 'IsVerify': IsVerify, 'IsUnListedPhNo': IsUnListedPhNo, 'PhoneTypeID': PhoneTypeID, 'OwnerFaxNumber': OwnerFaxNumber, 'IsCurrentPh': IsCurrentPh, 'BusinessTypeID': BusinessTypeID, 'SuffixID': SuffixID, 'DLVerifyID': VerifyID, 'SexID': SexID, 'RaceID': RaceID, 'PhoneTypeID': PhoneTypeID, 'NameReasonCodeID': NameReasonCodeID, 'CertifiedByID': CertifiedByID, 'EthnicityID': EthnicityID, 'AgeUnitID': AgeUnitID, 'IsJuvenile': IsJuvenile, 'LastName': LastName ? LastName : null, 'FirstName': trimmedFirstName ? trimmedFirstName : null, 'MiddleName': trimmedMiddleName ? trimmedMiddleName : null, 'SSN': SSN, 'WeightFrom': WeightFrom, 'WeightTo': WeightTo, 'HeightFrom': HeightFrom, 'HeightTo': HeightTo, 'Address': Address, 'Contact': Contact, 'OwnerNameID': OwnerNameID, 'OwnerPhoneNumber': OwnerPhoneNumber,
      'OwnerFaxNumber': OwnerFaxNumber, 'DateOfBirth': DateOfBirth, 'CertifiedDtTm': CertifiedDtTm, 'AgeFrom': AgeFrom, 'AgeTo': AgeTo, 'Years': Years, 'ModifiedByUserFK': ModifiedByUserFK, 'MasterNameID': MasterNameID, 'NameID': NameID, 'ArrestID': ArrestID, 'WarrantID': WarrantID, 'TicketID': TicketID, 'checkVictem': checkVictem, 'checkOffender': checkOffender, 'checkArrest': checkArrest, 'CreatedByUserFK': CreatedByUserFK, 'IncidentID': IncidentID, 'NameLocationID': NameLocationID, 'DLNumber': DLNumber, 'DLStateID': DLStateID, 'IsUnknown': IsUnknown
    };
    fetchPostData("MasterName/GetData_EventNameExists", {
      "SSN": SSN, "IncidentID": MstPage === "MST-Name-Dash" ? '' : mainIncidentID, 'masterNameID': masterNameID, 'NameID': NameID,'AgencyID' : AgencyID
    }).then((data) => {
      setErrors({ ...errors, ['ContactError']: 'true', ['NameTypeIDError']: '', });
      if (data) {
        if (data[0]?.Total === 0) {
          AddDeleteUpadate('MasterName/Update_MasterName', val).then((res) => {
            if (res.success) {
              const parseData = JSON.parse(res.data);
              toastifySuccess(parseData?.Table[0].Message);
              if (MstPage === "MST-Name-Dash") {
                if (isCad) {
                  navigate(`/cad/dispatcher?page=MST-Name-Dash&MasterNameID=${stringToBase64(MasterNameID)}&ModNo=${ModNo}&NameStatus=${true}`);
                } else {
                  navigate(`/Name-Home?page=MST-Name-Dash&MasterNameID=${stringToBase64(MasterNameID)}&ModNo=${ModNo}&NameStatus=${true}`);
                }
              }
              setChangesStatus(false);
              setValue({
                ...value,
                'NameIDNumber': 'Auto Generated',
                // DropDown
                'BusinessTypeID': '', 'SuffixID': '', 'VerifyID': '', 'SexID': '',
                'RaceID': '', 'PhoneTypeID': '', 'NameReasonCodeID': [], 'CertifiedByID': '', 'AgeUnitID': '',
                'IsVerify': true, 'IsUnListedPhNo': '', 'LastName': '', 'FirstName': '', 'MiddleName': '', 'SSN': '',
                'WeightFrom': '', 'WeightTo': '', 'HeightFrom': '', 'HeightTo': '', 'Address': '',
                'Contact': '', 'OwnerPhoneNumber': '', 'OwnerFaxNumber': '', 'OwnerNameID': '',
                'DateOfBirth': '', 'CertifiedDtTm': null, 'AgeFrom': '', 'AgeTo': '', 'Years': '',
                'EthnicityID': '',
                'DLNumber': "",
                'DLStateID': '',
                'IsUnknown': '', 'IsJuvenile': '',
              })
              GetSingleData(nameID, masterNameID);
              get_List(nameID, masterNameID);
              get_Name_Count(nameID, masterNameID, MstPage === "MST-Name-Dash" ? true : false);
              get_Data_Name(mainIncidentID, MstPage === "MST-Name-Dash" ? true : false);
              setStatesChangeStatus(false);
              if (uploadImgFiles?.length > 0) {
                upload_Image_File()
                setuploadImgFiles('')
              }
              // Reset()
              setErrors({ ...errors, ['ContactError']: 'true', ['NameTypeIDError']: '', });
            } else {
              setChangesStatus(false); toastifyError(res.Message); setErrors({ ...errors, ['NameTypeIDError']: '', });
            }
          })
        } else {
          toastifyError('SSN Already Exists');
        }
      }
    })
  }




  const Reset = () => {
    // setcountAppear(false);
    setIsAdult(false);
    setcalled(false);
    setShowOffender(false); setShowVictim(false); setshowWarrant(false); setDobDate(''); setAvailableAlert([])
    setStatesChangeStatus(false);
    setOnSelectLocation(true);
    setDlNumber(false);
    setErrors({
      ...value, 'NameTypeIDError': '', 'LastNameError': '', 'FirstNameError': '', 'MiddleNameError': '', 'NameReasonCodeIDError': '', 'CertifiedByIDError': '', 'ContactError': 'true', 'WeightError': 'true', 'HeightError': 'true', 'AgeError': 'true', 'DateOfBirthError': '', 'RaceIDError': '', 'SexIDError': '', 'AddressError': 'true', 'SSN': '', 'DLError': 'true', "CrimeLocationError": '',
    })
    setPhoneTypeCode(''); setMultiSelected({ optionSelected: [] });
    //  setNameStatus(false);
    const Id = nameTypeData?.filter((val) => { if (val.id === "I") return val })
    if (Id.length > 0) {
      setValue({
        ...value,
        ['NameTypeID']: Id[0]?.value,
        'NameIDNumber': 'Auto Generated',
        // DropDown
        'BusinessTypeID': '', 'SuffixID': '', 'VerifyID': '', 'SexID': '',
        'RaceID': '', 'PhoneTypeID': '', 'NameReasonCodeID': [], 'CertifiedByID': '', 'AgeUnitID': '',
        'IsVerify': true, 'IsUnListedPhNo': '', 'LastName': '', 'FirstName': '', 'MiddleName': '', 'SSN': '',
        'WeightFrom': '', 'WeightTo': '', 'HeightFrom': '', 'HeightTo': '', 'Address': '',
        'Contact': '', 'OwnerPhoneNumber': '', 'OwnerFaxNumber': '', 'OwnerNameID': '',
        'DateOfBirth': '', 'CertifiedDtTm': null, 'AgeFrom': '', 'AgeTo': '', 'Years': '',
        'MasterNameID': '', 'NameID': '', 'EthnicityID': '',
        'DLNumber': "",
        'DLStateID': '',
        'IsUnknown': '', 'IsJuvenile': '',
      })
      setglobalname('')
      setglobalnameto('')
      setLocationStatus(true);
      setUpdateStatus(updateStatus + 1);
      setNameTypeCode(Id[0].id);
      setIsBusinessName(false);
      setcountAppear(false);
      setcountStatus(false);

    }
    setNameMultiImg('');
    setuploadImgFiles('')
  }

  const ResetSearch = () => {
    setShowOffender(false); setShowVictim(false); setshowWarrant(false); setDobDate(''); setAvailableAlert([])
    setDlNumber(false);
    setErrors({
      ...value, 'NameTypeIDError': '', 'LastNameError': '', 'FirstNameError': '', 'MiddleNameError': '', 'NameReasonCodeIDError': '', 'CertifiedByIDError': '', 'ContactError': 'true', 'WeightError': 'true', 'HeightError': 'true', 'AgeError': 'true', 'DateOfBirthError': '', 'RaceIDError': '', 'SexIDError': '', 'AddressError': 'true', 'SSN': '', 'DLError': 'true',
    })
    setPhoneTypeCode(''); setMultiSelected({ optionSelected: [] });
    //  setNameStatus(false);
    const Id = nameTypeData?.filter((val) => { if (val.id === "I") return val })
    if (Id.length > 0) {
      setValue({
        ...value,
        // ['NameTypeID']: Id[0]?.value,
        'NameIDNumber': 'Auto Generated',
        // DropDown
        'BusinessTypeID': '', 'SuffixID': '', 'VerifyID': '', 'SexID': '',
        'RaceID': '', 'PhoneTypeID': '', 'NameReasonCodeID': [], 'CertifiedByID': '', 'AgeUnitID': '',
        'IsVerify': true, 'IsUnListedPhNo': '', 'LastName': '', 'FirstName': '', 'MiddleName': '', 'SSN': '',
        'WeightFrom': '', 'WeightTo': '', 'HeightFrom': '', 'HeightTo': '', 'Address': '',
        'Contact': '', 'OwnerPhoneNumber': '', 'OwnerFaxNumber': '', 'OwnerNameID': '',
        'DateOfBirth': '', 'CertifiedDtTm': null, 'AgeFrom': '', 'AgeTo': '', 'Years': '',
        'MasterNameID': '', 'NameID': '', 'EthnicityID': '',
        'DLNumber': "",
        'DLStateID': '',
        'IsUnknown': '', 'IsJuvenile': '',
      })
      setglobalname('')
      setglobalnameto('')
      setLocationStatus(true);
      setUpdateStatus(updateStatus + 1);
      // setNameTypeCode(Id[0].id); 
      setIsBusinessName(false);
    }
    setNameMultiImg('');
    setuploadImgFiles('')
  }

  const OnChangeSelectedReason = (data, name) => {
    setStatesChangeStatus(true)
    // <---------------Both function are to resturn true or false ----------------> 
    let adult = data.some(function (item) { return item.label === "Adult Arrest" });
    if (!adult) { setErrors({ ...errors, ['DateOfBirthError']: 'true', ['RaceIDError']: 'true', ['SexIDError']: 'true', ['NameTypeIDError']: '', }); }
    setIsAdult(adult);
    const newArray = [...data]
    if (value.checkOffender === 1 && value.checkVictem === 1) {
      multiSelected.optionSelected?.map(val => {
        if (val.checkVictem) {
          if (data.length > 0) {
            return data?.filter(item => {
              if (item.value === val.value) return newArray.push(val)
              else newArray.push(val)
            })
          } else return newArray.push(val)
        }
        if (val.checkOff) {
          if (data.length > 0) {
            return data?.filter(item => {
              if (item.value === val.value) return newArray.push(val)
              else newArray.push(val)
            })
          } else return newArray.push(val)
        }
      })
      let finalValueList = newArray.filter((item, index) => newArray.indexOf(item) === index)?.map((item) => item.value);
      setChangesStatus(true);
      setValue({
        ...value,
        [name]: finalValueList
      });
      setMultiSelected({
        optionSelected: newArray.filter((item, index) => newArray.indexOf(item) === index)
      });
    }
    else if (value.checkOffender === 1) {
      multiSelected.optionSelected?.map(val => {
        if (val.checkOff) {
          if (data.length > 0) {
            return data?.filter(item => {
              if (item.value === val.value) return newArray.push(val)
              else newArray.push(val)
            })
          } else return newArray.push(val)
        }
      })
      let finalValueList = newArray.filter((item, index) => newArray.indexOf(item) === index)?.map((item) => item.value);
      setChangesStatus(true);
      setValue({
        ...value,
        [name]: finalValueList
      })
      setMultiSelected({
        optionSelected: newArray.filter((item, index) => newArray.indexOf(item) === index)
      });
    }
    else if (value.checkVictem === 1) {
      multiSelected.optionSelected?.map(val => {
        if (val.checkVictem) {
          if (data.length > 0) {
            return data?.filter(item => {
              if (item.value === val.value) return newArray.push(val)
              else newArray.push(val)
            })
          } else return newArray.push(val)
        }
      })
      let finalValueList = newArray.filter((item, index) => newArray.indexOf(item) === index)?.map((item) => item.value);
      setChangesStatus(true);
      setValue({
        ...value,
        [name]: finalValueList
      })
      setMultiSelected({
        optionSelected: newArray.filter((item, index) => newArray.indexOf(item) === index)
      });
    } else {
      let finalValueList = newArray?.map((item) => item.value);
      setChangesStatus(true);
      setValue({
        ...value,
        [name]: finalValueList
      })
      setMultiSelected({
        optionSelected: newArray
      });
    }
  };




  const handleDOBChange = (date, e) => {
    setStatesChangeStatus(true)
    if (date) {
      setValue(pre => { return { ...pre, ['AgeFrom']: '', ['AgeTo']: '' } })
      setDobDate(date);
      const res = getShowingWithOutTime(date).split("/")
      let age = calculateAge(`${res[0]} ${res[1]} ${res[2]}`);
      setValue({ ...value, ['AgeFrom']: age, ['AgeTo']: '', ['Years']: age, ['DateOfBirth']: date ? getShowingWithOutTime(date) : null })
    } else if (date === null) {
      setDobDate(''); setValue({ ...value, ['AgeFrom']: '', ['AgeTo']: '', ['DateOfBirth']: null, ['AgeUnitID']: null, ['IsJuvenile']: false });
      calculateAge(null)
    } else {
      setDobDate(''); setValue({ ...value, ['AgeFrom']: null, ['AgeTo']: '', ['DateOfBirth']: null, ['AgeUnitID']: null, ['IsJuvenile']: false });
      calculateAge(null)
    }
    if (!nameID && !e?.target?.value?.length) {
      if (value?.LastName) {
        getNameSearchPopup(loginAgencyID, value?.NameTypeID, value.LastName, value.FirstName, value.MiddleName, getShowingWithOutTime(date), value.SSN, false)
      }
      // getNameSearch(loginAgencyID, value?.NameTypeID, value.LastName, value.FirstName, value.MiddleName, getShowingWithOutTime(date), value.SSN, false)
    } else if (e?.target?.value?.length) {
      if (e?.target?.value?.length === 10) {
        if (value?.LastName) {
          getNameSearchPopup(loginAgencyID, value?.NameTypeID, value.LastName, value.FirstName, value.MiddleName, getShowingWithOutTime(date), value.SSN, false)
        }
        // getNameSearch(loginAgencyID, value?.NameTypeID, value.LastName, value.FirstName, value.MiddleName, getShowingWithOutTime(date), value.SSN, false)
      }
    }
  };


  function calculateAge(birthday) {
    const today = MstPage === "MST-Name-Dash" ? new Date() : new Date(incReportedDate);
    const birthDate = new Date(birthday);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }

    return age;
  }

  useEffect(() => {

    if (yearsVal < 18 || parseInt(value.AgeFrom) < 18 || value.AgeFrom === '' || value.AgeFrom === null) {
      setJuvinile(true)
      setValue({ ...value, ['IsJuvenile']: true })
    } else {
      setJuvinile(false);
      setValue({ ...value, ['IsJuvenile']: false })
    }
    if (value.DateOfBirth) {
      const id = ageUnitDrpData?.filter((val) => { if (val.id === "Y") return val })
      if (id.length > 0) { setValue(prevValues => { return { ...prevValues, ['AgeUnitID']: id[0].value } }) }
    }
  }, [value.DateOfBirth, value.AgeFrom]);

  const AgeFromOnBlur = () => {
    if (value.AgeFrom < 18) {
      setValue({ ...value, ['IsJuvenile']: true })
    }
  }

  const HeightFromOnBlur = (e) => {
    setStatesChangeStatus(true); setChangesStatus(true)
    const heightstates = e.target.value;
    var len = heightstates.length;

    let heights = "";
    var oldvalue = heightstates.substring(len - 1, len);
    if (oldvalue != "\"") {
      if (len == 0) {
        heights = '';
      }
      else if (len == 1) {
        heights = heightstates.substring(0, len) + "'00\"";
      }
      else if (len == 2) {
        heights = heightstates.substring(0, len - 1) + "'0" + heightstates.substring(len - 1) + "\"";
      }
      else {
        var lengthstate = heightstates.substring(len - 2)

        heights = heightstates.substring(0, len - 2) + "'" + heightstates.substring(len - 2) + "\"";
        if (heightstates.substring(len - 2, len - 1) == 0) {
          heights = heightstates.substring(0, len - 2) + "'" + heightstates.substring(len - 2) + "\"";
        }
        if (lengthstate > 11) {

          heights = '';

          toastifyError('invalid');

        }
      }


    }
    else {
      heights = heightstates;
    }
    const globalname_Fromarray = globalnameto.replace("\"", "").replace("'", "");
    const globalname_Toarray = heights.replace("\"", "").replace("'", "");

    if ((parseInt(globalname_Fromarray) < parseInt(globalname_Toarray))) {
      toastifyError('height should be less');
      // heights = '';
    }
    if (parseInt(heights.replace("\"", "").replace("'", "")) < 101) {
      toastifyError('Height should be greater than or equal to 1\'01"');
      heights = '';
    }
    if (heights != '') {
      setglobalname(heights);
    }

    setValue({
      ...value,
      ['HeightFrom']: heights,
    })
  }

  const HeightOnChange = (e) => {
    setStatesChangeStatus(true)
    const heightstates = e.target.value;
    var len = heightstates.length;
    let heights = "";
    var oldvalue = heightstates.substring(len - 1, len);

    if (oldvalue != "\"") {

      if (len == 0) {
        heights = '';
      }
      else if (len == 1) {
        heights = heightstates.substring(0, len) + "'00\"";
      }
      else if (len == 2) {
        heights = heightstates.substring(0, len - 1) + "'0" + heightstates.substring(len - 1) + "\"";
      }
      else {
        heights = heightstates.substring(0, len - 2) + "'" + heightstates.substring(len - 2) + "\"";
        if (heightstates.substring(len - 2, len - 1) == 0) {
          heights = heightstates.substring(0, len - 2) + "'" + heightstates.substring(len - 2) + "\"";
        }
        var lengthstate = heightstates.substring(len - 2)
        if (lengthstate > 11) {
          heights = '';
          // alert("invalid");
          toastifyError('invalid');
        }
      }

    }
    else {
      heights = heightstates;
    }

    const globalname_Fromarray = globalname.replace("\"", "").replace("'", "");
    const globalname_Toarray = heights.replace("\"", "").replace("'", "");

    if ((parseInt(globalname_Fromarray) > parseInt(globalname_Toarray))) {
      toastifyError('height should be greater');
      // heights = '';
    }
    if (parseInt(heights.replace("\"", "").replace("'", "")) < 101) {
      toastifyError('Height should be greater than or equal to 1\'01"');
      heights = '';
    }

    if (heights != '') {
      setglobalnameto(heights)
    }

    setValue({
      ...value,
      ['HeightTo']: heights,
    })

  }

  // <---------------------Verify SingleData ------------------->
  const get_Add_Single_Data = (NameLocationID) => {
    fetchPostData('MasterLocation/GetSingleData_MasterLocation', { 'LocationID': NameLocationID, }).then((res) => {
      if (res.length > 0) {

        setAddVerifySingleData(res)
      } else {
        setAddVerifySingleData([])
      }
    })
  }

  //---------------------------------------- Image Insert ------------------------------------------------
  const get_Name_MultiImage = (nameID, masterNameID) => {
    const val = { 'NameID': nameID, 'MasterNameID': 0, }
    const val1 = { 'NameID': 0, 'MasterNameID': masterNameID }
    fetchPostData('MasterName/GetData_MasterNamePhoto', openPageName == 'MST-Name-Dash' ? val1 : val)
      .then((res) => {
        if (res) {
          setNameMultiImg(res);
        }
        else { setNameMultiImg(); }
      })

  }

  // to update image data
  const update_Name_MultiImage = () => {
    const val = { "ModifiedByUserFK": loginPinID, "AgencyID": loginAgencyID, "PictureTypeID": imgData?.PictureTypeID, "ImageViewID": imgData?.ImageViewID, "ImgDtTm": imgData?.ImgDtTm, "OfficerID": imgData?.OfficerID, "Comments": imgData?.Comments, "DocumentID": imgData?.DocumentID }
    AddDeleteUpadate('PropertyVehicle/Update_PropertyVehiclePhotoDetail', val)
      .then((res) => {
        if (res.success) {
          const parsedData = JSON.parse(res.data);
          const message = parsedData.Table[0].Message;
          toastifySuccess(message);
          // toastifySuccess(res.Message);
          // get_Name_MultiImage(nameID, masterNameID);
        }
        else {
          toastifyError(res?.Message);
        }
      })
  }

  const upload_Image_File = async (namID, namMID) => {
    const formdata = new FormData();
    const EncFormdata = new FormData();
    const newData = [];
    const EncDocs = [];

    for (let i = 0; i < uploadImgFiles.length; i++) {
      const { file, imgData } = uploadImgFiles[i];

      const val = {
        'NameID': nameID ? nameID : namID, 'MasterNameID': masterNameID ? masterNameID : namMID, 'CreatedByUserFK': loginPinID, 'Comments': imgData?.Comments,
        'PictureTypeID': imgData?.PictureTypeID, 'ImageViewID': imgData?.ImageViewID, 'ImgDtTm': imgData?.ImgDtTm, 'OfficerID': imgData?.OfficerID,
      }
      const val1 = {
        'NameID': 0, 'MasterNameID': masterNameID ? masterNameID : namMID, 'CreatedByUserFK': loginPinID,
        'PictureTypeID': imgData?.PictureTypeID, 'ImageViewID': imgData?.ImageViewID, 'ImgDtTm': imgData?.ImgDtTm,
        'OfficerID': imgData?.OfficerID, 'Comments': imgData?.Comments
      }

      const values = JSON.stringify(openPageName === 'MST-Name-Dash' ? val1 : val);
      newData.push(values);

      const EncPostData = await Aes256Encrypt(JSON.stringify([JSON.stringify(openPageName === 'MST-Name-Dash' ? val1 : val)]));
      EncDocs.push(EncPostData);

      formdata.append("file", file);
      EncFormdata.append("file", file);

    }

    formdata.append("Data", JSON.stringify(newData));
    EncFormdata.append("Data", EncDocs);

    AddDelete_Img('MasterName/MasterName_Photo', formdata, EncFormdata).then((res) => {
      if (res.success) {
        get_Name_MultiImage(nameID ? nameID : namID, masterNameID ? masterNameID : namMID);
        setuploadImgFiles('')
      }
    })
      .catch(err => console.log(err))
  }

  const delete_Image_File = (e) => {
    e?.preventDefault()
    const value = { 'PhotoID': imageId, 'DeletedByUserFK': loginPinID }
    AddDeleteUpadate('MasterName/Delete_Photo', value).then((data) => {
      if (data.success) {
        const parsedData = JSON.parse(data.data);
        const message = parsedData.Table[0].Message;
        toastifySuccess(message);
        // toastifySuccess(data?.Message);
        get_Name_MultiImage(nameID, masterNameID);
        setModalStatus(false);
        setImageId('');
        if (openPageName === 'MST-Name-Dash') { GetMasterSingleData(masterNameID) } else { GetSingleData(nameID, masterNameID); }
      } else {
        toastifyError(data?.Message);
      }
    });
  }

  const conditionalRowStyles = [
    {
      when: row => row.NameID === nameID,
      style: {
        backgroundColor: '#001f3fbd',
        color: 'white',
        cursor: 'pointer',
      },
    },
  ];

  // Custom Style
  const colourStyles = {
    control: (styles) => ({
      ...styles, backgroundColor: "#fce9bf",
      height: 20,
      minHeight: 33,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  };

  const colourStylesReason = {
    control: (styles) => ({
      ...styles, backgroundColor: "#fce9bf",
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
      minHeight: 33,
    }),
  };
  const colourStylesMasterReason = {
    control: (styles) => ({
      ...styles,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
      minHeight: 33,
    }),
  };

  // custuom style withoutColor
  const customStylesWithOutColor = {
    control: base => ({
      ...base,
      height: 20,
      minHeight: 35,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  };





  const customWithOutColor = {
    control: base => ({
      ...base,
      height: 20,
      minHeight: 33,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
      width: 130,
    }),
  };

  const startRef = React.useRef();
  const startRef1 = React.useRef();

  const onKeyDown = (e) => {
    if (e.keyCode === 9 || e.which === 9) {
      startRef1.current.setOpen(false);
    }
  };

  const onMasterPropClose = () => {
    get_Name_Count('');
    navigate('/dashboard-page');
    if (!changesStatus) {
    }
  }


  const handleWeightFromBlur = () => {
    const weightFrom = Number(value?.WeightFrom);
    const weightTo = Number(value?.WeightTo);
    if (weightFrom && weightTo) {
      if (weightFrom > weightTo) {
        toastifyError('WeightFrom should be less than WeightTo');
      }
    }

  };

  const handleWeightToBlur = () => {
    const weightFrom = Number(value?.WeightFrom);
    const weightTo = Number(value?.WeightTo);
    if (weightFrom && weightTo) {
      if (weightTo < weightFrom) {
        toastifyError('WeightTo should be greater than WeightFrom');
      }
    }

  };






  return (
    <>
      <div className="row bb">
        <div className="col-12 col-md-12 col-lg-12 ">
          <div className="row">
            <div className="col-2 col-md-2 col-lg-1 mt-2">
              <label htmlFor="" className='label-name '>Name Type{errors.NameTypeIDError !== 'true' ? (
                <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.NameTypeIDError}</p>
              ) : null}
              </label>
            </div>
            <div className="col-3 col-md-3 col-lg-2  mt-1">
              <Select
                name='NameTypeID'
                value={nameTypeData?.filter((obj) => obj.value === value?.NameTypeID)}
                options={nameTypeData}
                onChange={(e) => ChangeNameType(e, 'NameTypeID')}
                // isClearable
                placeholder="Select..."
                isDisabled={nameID || masterNameID ? true : false}
                styles={colourStyles}
              />
            </div>
            <div className="col-2 col-md-2 col-lg-1 mt-2">
              <label htmlFor="" className='label-name '>MNI</label>
            </div>
            <div className="col-2 col-md-3 col-lg-2 text-field mt-1">
              <input type="text" className='readonlyColor' value={value?.NameIDNumber} name='nameid' required readOnly />
            </div>
            <div className="col-3 col-md-2 col-lg-1 mt-2">
              <div className="form-check ">
                {/* {
                  value.DateOfBirth ?
                    <input className="form-check-input" type="checkbox" name='IsJuvenile' value={value?.IsJuvenile} checked={value?.IsJuvenile} id="flexCheckDefault" disabled={nameTypeCode === "B" ? true : false} />
                    :
                    <input className="form-check-input" type="checkbox" name='IsJuvenile' value={value?.IsJuvenile} checked={value?.IsJuvenile} id="flexCheckDefault" disabled={nameTypeCode === "B" ? true : false} />
                } */}
                {
                  !(nameTypeCode === "B") && (
                    value.DateOfBirth || value.AgeFrom ? (
                      <>
                        <input className="form-check-input" type="checkbox" name="IsJuvenile" value={value?.IsJuvenile} checked={value?.IsJuvenile} id="flexCheckDefault" disabled={nameTypeCode === "B"} />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                          Juvenile
                        </label>
                      </>
                    ) : (
                      <>
                        <input className="form-check-input" type="checkbox" name="IsJuvenile" value={value?.IsJuvenile} checked={false} id="flexCheckDefault" disabled={nameTypeCode === "B"} />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                          Juvenile
                        </label>
                      </>
                    )
                  )
                }
              </div>
            </div>

            <div className="col-2 col-md-2 col-lg-1 mt-2 pt-1">
              <label htmlFor="" className='label-name '>Reason Code
                {errors.NameReasonCodeIDError !== 'true' ? (
                  <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.NameReasonCodeIDError}</p>
                ) : null}</label>
            </div>
            <div className="col-10 col-md-10 col-lg-4 mt-1 mb-1" >
              <SelectBox
                styles={MstPage === "MST-Name-Dash" ? colourStylesMasterReason : colourStylesReason}
                options={reasonIdDrp}
                menuPlacement="bottom"
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={true}
                isClearable={false}
                // allowSelectAll={true} 
                allowSelectAll={false}
                value={multiSelected.optionSelected}
                components={{ MultiValue }}
                onChange={(e) => value.checkVictem === 1 || value.checkVictem === 0 && value.checkOffender === 1 || value.checkOffender === 0 ? OnChangeSelectedReason(e, 'NameReasonCodeID') : ''}
              />
            </div>


          </div>
        </div >
      </div>
      {
        nameTypeCode === "B" ?
          <div className="col-12 col-md-12 col-lg-12">
            <div className="row ">
              <div className="col-1 col-md-1 col-lg-1 mt-2 px-0">
                <label htmlFor="" className='label-name'>Business Name
                  {errors.LastNameError !== 'true' && nameTypeCode === 'B' ? (
                    <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.LastNameError}</p>
                  ) : null}</label>
              </div>
              <div className="col-2 col-md-2 col-lg-4 text-field mt-1">
                <input type="text" name='LastName' className={'requiredColor'} value={value?.LastName} onChange={HandleChange} required />
              </div>
              {
                !nameID &&
                <div className="col-12 col-md-3 col-lg-1 name-box text-center mt-1 pt-1 " >
                  <button type="button" data-toggle="modal" data-target="#SearchModal" className="btn btn-sm btn-success" onClick={() => getNameSearch(loginAgencyID, value?.NameTypeID, value.LastName, value.FirstName, value.MiddleName, value.DateOfBirth, value.SSN, value.HeightFrom, value.HeightTo, value.WeightFrom, value.WeightTo, value.EthnicityID, value.RaceID, value.SexID, value.PhoneTypeID, value.Contact, true)}>Search</button>
                </div>
              }
              <div className="col-1 col-md-1 col-lg-1 mt-2">
                <label htmlFor="" className='label-name '>Business Type</label>
              </div>
              <div className="col-2 col-md-2 col-lg-5  mt-1">
                <Select
                  name='BusinessTypeID'
                  value={businessTypeDrp?.filter((obj) => obj.value === value?.BusinessTypeID)}
                  options={businessTypeDrp}
                  onChange={(e) => ChangeDropDown(e, 'BusinessTypeID')}
                  isClearable
                  placeholder="Select..."
                  styles={customStylesWithOutColor}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-1 col-md-1 col-lg-1 mt-2">
                <label htmlFor="" className='label-name '>Owner Name</label>
              </div>
              <div className="col-2 col-md-2 col-lg-3  mt-1">
                {
                  MstPage === "MST-Name-Dash" ?
                    <Select
                      name='OwnerNameID'
                      styles={customStylesWithOutColor}
                      options={mastersNameDrpData}
                      value={mastersNameDrpData?.filter((obj) => obj.value === value?.OwnerNameID)}
                      isClearable={value?.OwnerNameID ? true : false}
                      onChange={(e) => ChangeDropDown(e, 'OwnerNameID')}
                      placeholder="Select..."
                    />
                    :
                    <Select
                      name='OwnerNameID'
                      styles={customStylesWithOutColor}
                      options={ownerNameData}
                      value={ownerNameData?.filter((obj) => obj.value === value?.OwnerNameID)}
                      isClearable={value?.OwnerNameID ? true : false}
                      onChange={(e) => ChangeDropDown(e, 'OwnerNameID')}
                      placeholder="Select..."
                    />
                }

              </div>
              <div className="col-1 mt-1" data-toggle="modal" data-target="#MasterModal"  >
                <button onClick={() => {
                  if (possessionID) { GetSingleDataPassion(possessionID); } setNameModalStatus(true);
                }} className=" btn btn-sm bg-green text-white py-1" >
                  <i className="fa fa-plus" >
                  </i>
                </button>
              </div>

              <div className="col-1 col-md-1 col-lg-1 mt-2 ">
                <label htmlFor="" className='label-name '>Owner&nbsp;Phone&nbsp;No.{errors.OwnerPhoneNumberError !== 'true' ? (
                  <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.OwnerPhoneNumberError}</p>
                ) : null}</label>
              </div>
              <div className="col-2 col-md-2 col-lg-2 text-field mt-1">
                <input type="text" name='OwnerPhoneNumber' maxLength={11} className={''} value={value?.OwnerPhoneNumber} onChange={HandleChange} required />

              </div>

              <div className="col-1 col-md-1 col-lg-2 px-0 mt-2">
                <label htmlFor="" className='label-name px-0'>Business Fax No.{errors.OwnerFaxNumberError !== 'true' ? (
                  <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.OwnerFaxNumberError}</p>
                ) : null}</label>
              </div>
              <div className="col-2 col-md-2 col-lg-2 text-field mt-1">
                <input type="text" name='OwnerFaxNumber' className={''} value={value?.OwnerFaxNumber} onChange={HandleChange} required />
              </div>
            </div>
          </div>
          :
          <div className="row mt-1 px-0">
            <div className="col-12 col-md-12 col-lg-12  ">
              <div className="row">
                <div className="col-2 col-md-2 col-lg-1 mt-2">
                  <label htmlFor="" className='label-name '>Last Name
                    {errors.LastNameError !== 'true' && nameTypeCode !== 'B' ? (
                      <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.LastNameError}</p>
                    ) : null}</label>
                </div>
                <div className="col-10 col-md-10 col-lg-2 text-field mt-1">
                  <input type="text" name='LastName' maxLength={100} onBlur={(e) => { e.relatedTarget !== saveButtonRef.current && e.relatedTarget !== closeButtonRef.current && LastFirstNameOnBlur(e) }} className={nameTypeCode === "B" ? 'readonlyColor' : 'requiredColor'} value={value?.LastName} onClick={() => { setChangesStatus(true); }} onChange={HandleChange} required disabled={nameTypeCode === "B" ? true : false} readOnly={nameTypeCode === "B" ? true : false} autoComplete='off' />

                </div>
                <div className="col-2 col-md-2 col-lg-1 mt-2">
                  <label htmlFor="" className='label-name '>First Name
                    {errors.FirstNameError !== 'true' && nameTypeCode !== 'B' ? (
                      <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.FirstNameError}</p>
                    ) : null}
                  </label>
                </div>
                <div className="col-2 col-md-4 col-lg-2 text-field mt-1">
                  <input type="text" maxLength={50} ref={firstNameInputRef} name='FirstName'
                    onBlur={(e) => { e.relatedTarget !== saveButtonRef.current && LastFirstNameOnBlur(e) }}
                    className={(nameTypeCode === "B" || value?.IsUnknown === 'true' || value?.IsUnknown === true) ? 'readonlyColor' : ''} value={value?.FirstName} onChange={HandleChange} required disabled={nameTypeCode === "B" ? true : false} readOnly={(nameTypeCode === "B" || value?.IsUnknown === 'true' || value?.IsUnknown === true) ? true : false} onClick={() => { setChangesStatus(true); }} autoComplete='off' />
                </div>
                <div className="col-2 col-md-2 col-lg-1 mt-2 px-0">
                  <label htmlFor="" className='label-name '>Middle Name
                    {errors.MiddleNameError !== 'true' && nameTypeCode !== 'B' ? (
                      <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.MiddleNameError}</p>
                    ) : null}
                  </label>
                </div>
                <div className="col-2 col-md-4 col-lg-2 text-field mt-1">
                  <input type="text" name='MiddleName' maxLength={50} value={value?.MiddleName} className={(nameTypeCode === "B" || value?.IsUnknown === 'true' || value?.IsUnknown === true) ? 'readonlyColor' : ''} onChange={HandleChange} required disabled={nameTypeCode === "B" ? true : false} readOnly={(nameTypeCode === "B" || value?.IsUnknown === 'true' || value?.IsUnknown === true) ? true : false} onClick={() => { setChangesStatus(true); }} autoComplete='off' />
                </div>
                <div className="col-12 col-md-12 col-lg-3 d-flex mt-1 ">
                  <div className="col-2 col-md-2 col-lg-2 mt-2 ml-4 ml-md-0">
                    <label htmlFor="" className='label-name'>Suffix</label>
                  </div>
                  <div className="col-8 col-md-8 col-lg-6 ">
                    <Select
                      name='SuffixID'
                      value={suffixIdDrp?.filter((obj) => obj.value === value?.SuffixID)}
                      options={suffixIdDrp}
                      onChange={(e) => ChangeDropDown(e, 'SuffixID')}
                      isClearable
                      placeholder="Select..."
                      isDisabled={nameTypeCode === "B" ? true : false}
                      styles={customStylesWithOutColor}
                    />
                  </div>
                  <div className="col-4 col-md-2 col-lg-4">
                    <div className="form-check pt-2 ">
                      <input className="form-check-input " type="checkbox" name='IsUnknown' value={value?.IsUnknown} checked={value?.IsUnknown} onChange={HandleChange} id="flexCheckDefault1" disabled={nameTypeCode === "B" ? true : false} readOnly={nameTypeCode === "B" ? true : false} />
                      <label className="form-check-label label-name  pr-md-2" htmlFor="flexCheckDefault1">
                        Unknown
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      }
      {
        nameTypeCode === "B" ?
          <>
          </>
          :
          <>
            <div className="row ">
              <div className="col-12 col-md-12 col-lg-6">
                <div className="row">
                  <div className="col-1 col-md-2 col-lg-2 mt-3">
                    <label htmlFor="" className='label-name '>DOB
                      {errors.DateOfBirthError !== 'true' ? (
                        <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.DateOfBirthError}</p>
                      ) : null}</label>

                  </div>
                  <div className="col-2 col-md-3 col-lg-4 mt-0">
                    <DatePicker
                      id='DateOfBirth'
                      name='DateOfBirth'
                      ref={startRef}
                      onKeyDown={(e) => {
                        if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Delete' || e.key === ':' || e.key === '/' || e.key === ' ' || e.key === 'F5')) {
                          e.preventDefault();
                        } else {
                          onKeyDown(e);
                        }
                      }
                      }
                      onChange={(date, e) => { handleDOBChange(date, e); setChangesStatus(true); setStatesChangeStatus(true); }}
                      dateFormat="MM/dd/yyyy"
                      isClearable={value.DateOfBirth ? true : false}
                      selected={dobDate}
                      placeholderText={value.DateOfBirth ? value.DateOfBirth : 'Select...'}
                      // peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      autoComplete='Off'
                      // maxDate={new Date()}
                      // maxDate={new Date(incReportedDate)}
                      maxDate={MstPage === "MST-Name-Dash" ? new Date() : new Date(incReportedDate)}
                      disabled={nameTypeCode === "B" ? true : false}
                      className={(nameTypeCode === "B" || value?.IsUnknown === 'true' || value?.IsUnknown === true) ? 'readonlyColor' : '' || isAdult ? 'requiredColor' : ''}
                      readOnly={(nameTypeCode === "B" || value?.IsUnknown === 'true' || value?.IsUnknown === true) ? true : false}


                    />
                  </div>
                  <div className="col-12 col-md-7 col-lg-3 d-flex " >
                    <div className="col-1 col-md-1 col-lg-3 mt-2 ">
                      <label htmlFor="" className='label-name'>Age</label>
                    </div>
                    <div className="col-2 col-md-3 col-lg-5 mt-1  text-field px-0" >
                      <input type="text" name='AgeFrom' maxLength={3}
                        className={value.DateOfBirth ? 'readonlyColor' : ''}
                        value={value?.AgeFrom}
                        // onBlur={AgeFromOnBlur}
                        onBlur={(e) => AgeFromOnBlur(e)}
                        onChange={HandleChange} required
                        disabled={value.DateOfBirth ? true : false}
                        readOnly={value.DateOfBirth ? true : false} placeholder='From' autoComplete='off' />
                    </div>
                    <span className='dash-name mt-1'>_</span>
                    <div className="col-2 col-md-2 col-lg-4 mt-1  text-field " >
                      <input type="text" name='AgeTo' maxLength={3} value={value?.AgeTo} onChange={HandleChange} required className={value.DateOfBirth || !value?.AgeFrom ? 'readonlyColor' : ''} disabled={value.DateOfBirth ? true : false || !value?.AgeFrom} readOnly={value.DateOfBirth ? true : false || !value?.AgeFrom} placeholder='To' autoComplete='off' />
                    </div>
                    <div className="col-4 col-md-4 col-lg-12  mt-1 px-0" >
                      <Select
                        name='AgeUnitID'
                        value={ageUnitDrpData?.filter((obj) => obj.value === value?.AgeUnitID)}
                        options={ageUnitDrpData}
                        onChange={(e) => ChangeDropDown(e, 'AgeUnitID')}
                        isClearable
                        placeholder="Age Unit..."
                        styles={customStylesWithOutColor}
                        isDisabled={value.DateOfBirth ? true : false || !value?.AgeFrom}
                      // className={value.DateOfBirth || !value?.AgeFrom ? 'readonlyColor' : ''}
                      />
                    </div>
                  </div>
                  {/* <span className='dash-name mt-1' style={{ marginRight: '-10px' }}>_</span> */}
                  {/* <div className="col-3 col-md-1 col-lg-1 px-3 text-field mt-1" style={{ marginRight: '-10px' }}>
                    <input type="text" name='AgeTo' maxLength={3} value={value?.AgeTo} onChange={HandleChange} required className={value.DateOfBirth ? 'readonlyColor' : ''} disabled={value.DateOfBirth ? true : false} readOnly={value.DateOfBirth ? true : false} placeholder='To' autoComplete='off' />
                  </div> */}
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <div className="row">
                  {/* <div className="col-6 col-md-6 col-lg-5 d-flex "> */}
                    <div className="col-2 col-md-2 col-lg-2 mt-2 ">
                      {/* <label htmlFor="" className='label-name '>Gender
                        {errors.SexIDError !== 'true' ? (
                          <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.SexIDError}</p>
                        ) : null}</label> */}
                      <span data-toggle="modal" onClick={() => { setOpenPage('Gender') }} data-target="#ListModel" className='new-link px-0'>
                        Gender {errors.SexIDError !== 'true' ? (
                          <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.SexIDError}</p>
                        ) : null}
                      </span>
                    </div>
                    <div className="col-10 col-md-10 col-lg-4 mt-1 ">
                      <Select
                        styles={isAdult ? colourStyles : customStylesWithOutColor}
                        name='SexID'
                        value={sexIdDrp?.filter((obj) => obj.value === value?.SexID)}
                        options={sexIdDrp}
                        onChange={(e) => ChangeDropDown(e, 'SexID')}
                        isClearable
                        placeholder="Select..."
                        isDisabled={nameTypeCode === "B" || value?.IsUnknown === 'true' || value?.IsUnknown === true ? true : false}
                      />
                    </div>
                  {/* </div> */}
                  {/* <div className="col-6 col-md-6 col-lg-5 d-flex "> */}
                  <div className="col-2 col-md-2 col-lg-1 mt-2 px-0">
                    {/* <label htmlFor="" className='label-name '>Race
                      {errors.RaceIDError !== 'true' ? (
                        <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.RaceIDError}</p>
                      ) : null}</label> */}
                    <span data-toggle="modal" onClick={() => { setOpenPage('Race') }} data-target="#ListModel" className='new-link px-0'>
                      Race{errors.RaceIDError !== 'true' ? (
                        <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.RaceIDError}</p>
                      ) : null}
                    </span>
                  </div>
                  <div className="col-10 col-md-10 col-lg-5 mt-1">
                    <Select
                      name='RaceID'
                      value={raceIdDrp?.filter((obj) => obj.value === value?.RaceID)}
                      options={raceIdDrp}
                      onChange={(e) => ChangeDropDown(e, 'RaceID')}
                      isClearable
                      placeholder="Select..."
                      isDisabled={nameTypeCode === "B" || value?.IsUnknown === 'true' || value?.IsUnknown === true ? true : false}
                      styles={isAdult ? colourStyles : customStylesWithOutColor}
                    />
                  </div>
                </div>
                {/* </div> */}

              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-3 d-flex ">
                    <div className="col-2 col-md-2 col-lg-4 mt-2 ">
                      {/* <label htmlFor="" className='label-name '>Ethnicity</label> */}
                      <span data-toggle="modal" onClick={() => { setOpenPage('Ethnicity') }} data-target="#ListModel" className='new-link px-0'>
                        Ethnicity
                      </span>
                    </div>
                    <div className="col-10 col-md-10 col-lg-8 mt-1 mb-0">
                      <Select
                        name='EthnicityID'
                        value={ethinicityDrpData?.filter((obj) => obj.value === value?.EthnicityID)}
                        options={ethinicityDrpData}
                        onChange={(e) => ChangeDropDown(e, 'EthnicityID')}
                        isClearable
                        placeholder="Select..."
                        styles={customStylesWithOutColor}
                      />
                    </div>
                  </div>
                  <div className="col-1 col-md-1 col-lg-1 mt-2">
                    <label htmlFor="" className='label-name '>Weight
                      <p className='text-center mb-0' style={{ fontWeight: 'bold', fontSize: '10px' }}>(LBS)</p>
                      {errors.WeightError !== 'true' ? (
                        <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.WeightError}</p>
                      ) : null}
                    </label>
                  </div>
                  <div className="col-2 col-md-2 col-lg-1 text-field mt-1" >
                    <input type="text" name='WeightFrom' ref={crossButtonRef} onBlur={(e) => {
                      if (e.target.name === 'WeightFrom' &&
                        e.relatedTarget !== crossButtonRef.current &&
                        e.relatedTarget?.name !== 'HeightFrom' &&
                        e.relatedTarget?.name !== 'HeightTo') {
                        handleWeightFromBlur(e);
                      }
                    }} value={value?.WeightFrom} maxLength={3} onKeyDown={handleKeyDown} onChange={HandleChange} required disabled={nameTypeCode === "B" ? true : false} readOnly={nameTypeCode === "B" ? true : false} className={nameTypeCode === "B" ? 'readonlyColor' : ''} placeholder='From' autoComplete='off' />
                  </div>
                  <span className='dash-name mt-1' style={{ marginRight: '-10px' }}>__</span>
                  <div className="col-3 col-md-2 col-lg-1 ">
                    <div className="text-field px-2 mt-1">
                      <input type="text" name='WeightTo' ref={crossButtonRef} onBlur={(e) => {
                        if (e.target.name === 'WeightTo' &&
                          e.relatedTarget !== crossButtonRef.current &&
                          e.relatedTarget?.name !== 'HeightFrom' &&
                          e.relatedTarget?.name !== 'HeightTo') {
                          handleWeightToBlur(e);
                        }
                      }} value={value?.WeightTo} maxLength={3} onChange={HandleChange} required className={(nameTypeCode === "B" || !value?.WeightFrom || value.WeightFrom === '0' || value.WeightFrom === '00' || value.WeightFrom === '000') ? 'readonlyColor' : ''} disabled={(nameTypeCode === "B" || !value?.WeightFrom || value.WeightFrom === '0' || value.WeightFrom === '00' || value.WeightFrom === '000') ? true : false} readOnly={nameTypeCode === "B" ? true : false} placeholder='To' autoComplete='off' />
                    </div>
                  </div>
                  <div className="col-1 col-md-2 col-lg-1 mt-2">
                    <label htmlFor="" className='label-name '>Height

                      <p className='text-center mb-0' style={{ fontWeight: 'bold', fontSize: '10px' }}>(FT)</p>
                      {errors.HeightError !== 'true' ? (
                        <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.HeightError}</p>
                      ) : null}
                    </label>
                  </div>
                  <div className="col-2 col-md-2 col-lg-1 text-field mt-1" >
                    <input type="text" name='HeightFrom' maxLength={3} value={value?.HeightFrom}
                      onBlur={(e) => {
                        if (e.target.name === 'HeightFrom' && e.relatedTarget !== crossButtonRef.current &&
                          e.relatedTarget?.name !== 'WeightFrom' &&
                          e.relatedTarget?.name !== 'WeightTo') {
                          HeightFromOnBlur(e);
                        }
                      }}
                      onChange={HandleChange}
                      required
                      onKeyDown={handleKeyDown} disabled={nameTypeCode === "B" ? true : false} readOnly={nameTypeCode === "B" ? true : false} className={nameTypeCode === "B" ? 'readonlyColor' : ''} placeholder='From' autoComplete='off' />
                  </div>
                  <span className='dash-name mt-1' style={{ marginRight: '-10px' }}>__</span>
                  <div className="col-3 col-md-2 col-lg-1 ">
                    <div className="text-field px-2 mt-1">
                      <input type="text" name='HeightTo' maxLength={3} value={value?.HeightTo} onBlur={(e) => {
                        if (e.target.name === 'HeightTo' && e.relatedTarget !== crossButtonRef.current &&
                          e.relatedTarget?.name !== 'WeightFrom' &&
                          e.relatedTarget?.name !== 'WeightTo') {
                          HeightOnChange(e);
                        }
                      }}
                        onChange={HandleChange} required className={nameTypeCode === "B" || !value.HeightFrom ? 'readonlyColor' : ''} disabled={nameTypeCode === "B" || !value.HeightFrom ? true : false} readOnly={nameTypeCode === "B" ? true : false} placeholder='To' autoComplete='off' />
                    </div>
                  </div>


                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12 ">
                <fieldset>
                  <legend>SSN/DL Info</legend>
                  <div className="row px-2">
                    <div className="col-2 col-md-2 col-lg-1 mt-2">
                      <label htmlFor="" className='label-name '>SSN
                        {errors.SSN !== 'true' ? (
                          <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.SSN}</p>
                        ) : null}</label>
                    </div>
                    <div className="col-3 col-md-3 col-lg-2 text-field mt-1" >
                      <input type="text"
                        readOnly={(nameTypeCode === "B" || value?.IsUnknown === 'true' || value?.IsUnknown === true) ? true : false}
                        className={(nameTypeCode === "B" || value?.IsUnknown === 'true' || value?.IsUnknown === true) ? 'readonlyColor' : ''} maxLength={10} name='SSN' value={value?.SSN}
                        onChange={HandleChange} required autoComplete='off' />
                    </div>
                    <div className="col-3 col-md-6 col-lg-5 d-flex " >

                      <div className="col-2 col-md-2 col-lg-2 mt-2 pl-2">
                        <label htmlFor="" className='label-name '>State/DL#</label>
                      </div>
                      <div className="col-3 col-md-5 col-lg-6  mt-1" >
                        <Select
                          name='DLStateID'
                          value={stateList?.filter((obj) => obj.value === value?.DLStateID)}
                          options={stateList}
                          onChange={(e) => ChangeDropDown(e, 'DLStateID')}
                          isClearable
                          placeholder="State"
                          styles={customStylesWithOutColor}
                          className={(nameTypeCode === "B" || value?.IsUnknown === 'true' || value?.IsUnknown === true) ? 'readonlyColor' : ''}
                          isDisabled={(nameTypeCode === "B" || value?.IsUnknown === 'true' || value?.IsUnknown === true) ? true : false}
                        />
                      </div>
                      <span className='dash-name mt-1' >__
                        {errors.DLError !== 'true' ? (
                          <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.DLError}</p>
                        ) : null}
                      </span>
                      <div className="col-3 col-md-5 col-lg-4 text-field mt-1" >
                        <input
                          type="text"
                          className={value?.DLStateID ? 'requiredColor' : 'readonlyColor'}
                          style={{ textTransform: "uppercase" }}
                          // value={value.DLNumber}
                          value={value?.DLNumber ? value.DLNumber.replace(/[^\w\s]/g, '') : ''}
                          maxLength={21}
                          disabled={value?.DLStateID ? false : true}
                          onChange={HandleChange}
                          name="DLNumber"
                          required
                          autoComplete='off'
                        />

                      </div>
                    </div>
                    <div className="col-3 col-md-6 col-lg-4 d-flex " >
                      <div className="col-2 col-md-2 col-lg-6 mt-2">
                        {/* <label htmlFor="" className='label-name '>How Verify</label> */}
                        <span data-toggle="modal" onClick={() => { setOpenPage('Verify') }} data-target="#ListModel" className='new-link px-0'>
                          How Verify
                        </span>
                      </div>
                      <div className="col-10 col-md-10 col-lg-7 mt-1">
                        <Select
                          name='VerifyID'
                          value={verifyIdDrp?.filter((obj) => obj.value === value?.VerifyID)}
                          options={verifyIdDrp}
                          onChange={(e) => ChangeDropDown(e, 'VerifyID')}
                          isClearable
                          placeholder="Verify ID"
                          styles={customStylesWithOutColor}
                          isDisabled={!value?.DLStateID}
                        />
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </>
      }
      {/* Alert Master */}
      <div className=' mb-1'>
        {/* <AlertTable availableAlert={availableAlert} masterPropertyID={masterNameID} ProSta={NameStatus} /> */}
        <AlertTable availableAlert={availableAlert} masterPropertyID={masterNameID ? masterNameID : ''} ProSta={NameStatus} />
      </div>
      <div className="row">
        <div className="col-12 col-md-12 col-lg-11 ">
          <fieldset>
            <legend>Address/Contact Info</legend>
            <div className="row">
              <div className="col-3 col-md-2 col-lg-1 mt-2">
                <label htmlFor="" className='label-name '>Address
                  {errors.CrimeLocationError !== 'true' ? (
                    <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.CrimeLocationError}</p>
                  ) : null}</label>
              </div>
              <div className="col-7  col-md-7 col-lg-9 mt-2   text-field" >
                {/* <Location {...{ value, setValue, locationStatus, setLocationStatus, updateStatus }} col='Address' locationID='NameLocationID' check={isAdult ? true : false} verify={value.IsVerify} page='Name' /> */}
                <Location {...{ value, setValue, setChangesStatus, locationStatus, setLocationStatus, updateStatus, setOnSelectLocation, setStatesChangeStatus }} col='Address' locationID='NameLocationID' check={isAdult ? false : false} verify={value.IsVerify} page='Name' />

              </div>
              <div className="col-3 col-md-3 col-lg-2 mt-2">
                <div className="form-check ">
                  <input className="form-check-input" type="checkbox" name='IsVerify' value={value?.IsVerify} checked={(value?.IsVerify || !value?.NameLocationID)} onChange={HandleChange} data-toggle="modal" data-target="#NameVerifyModal" id="flexCheckDefault3" />
                  <label className="form-check-label mr-2" htmlFor="flexCheckDefault3">
                    Verify
                  </label>
                  {
                    !value?.IsVerify && addVerifySingleData.length > 0 ?
                      <i className="fa fa-edit " onKeyDown={''} onClick={() => { if (value.NameLocationID) { get_Add_Single_Data(value.NameLocationID); setModalStatus(true); } }} data-toggle="modal" data-target="#NameVerifyModal" style={{ cursor: 'pointer', backgroundColor: '' }} > Edit </i>
                      :
                      <>
                      </>
                  }
                </div>
              </div>
              <div className="col-2 col-md-2 col-lg-1 mt-2 px-0">
                <label htmlFor="" className='label-name px-0'>Contact Type</label>
              </div>
              <div className="col-3 col-md-3 col-lg-3 mt-1">
                <Select
                  name='PhoneTypeID'
                  value={phoneTypeIdDrp?.filter((obj) => obj.value === value?.PhoneTypeID)}
                  options={phoneTypeIdDrp}
                  onChange={(e) => ChangePhoneType(e, 'PhoneTypeID')}
                  isClearable
                  placeholder="Select..."

                  disabled={phoneTypeCode ? false : true}
                  styles={customStylesWithOutColor}
                />
              </div>
              <div className="col-1 col-md-2 col-lg-1 mt-2">
                <label htmlFor="" className='label-name '>Contact

                </label>
              </div>
              <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                <input type="text"
                  maxLength={phoneTypeCode !== 'E' ? 10 : ''} className={value?.PhoneTypeID ? 'requiredColor' : 'readonlyColor'}
                  name='Contact' value={value?.Contact} onChange={HandleChange} required disabled={value?.PhoneTypeID ? false : true} autoComplete='off' />
                {errors.ContactError !== 'true' ? (
                  <span style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.ContactError}</span>
                ) : null}
              </div>
              <div className="col-3 col-md-1 col-lg-2  pt-1 ">
                {
                  phoneTypeCode !== 'E' ?
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" name='IsUnListedPhNo' value={value?.IsUnListedPhNo} disabled={!value?.Contact ? true : false} checked={value?.IsUnListedPhNo} onChange={HandleChange} id="flexCheckDefault2" />
                      <label className="form-check-label" htmlFor="flexCheckDefault2">
                        Unlisted
                      </label>
                    </div> : <></>
                }
              </div>

              {/* <div className="col-2 col-md-2 col-lg-1 mt-3">
                <label htmlFor="" className='label-name '>Certified By
                  {errors.CertifiedByIDError !== 'true' ? (
                    <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.CertifiedByIDError}</p>
                  ) : null}</label>
              </div>
              <div className="col-6 col-md-4 col-lg-4 mt-2">
                <Select
                  name='CertifiedByID'
                  menuPlacement='top'
                  value={certifiedByIdDrp?.filter((obj) => obj.value === value?.CertifiedByID)}
                  options={certifiedByIdDrp}
                  onChange={(e) => ChangeDropDown(e, 'CertifiedByID')}
                  isClearable
                  placeholder="Certified By"
                  styles={colourStyles}
                />
              </div>
              <div className="col-2 col-md-3 col-lg-2 mt-3">
                <label htmlFor="" className='label-name '>Certified Date/Time</label>
              </div>
              <div className="col-4 col-md-3 col-lg-3 mt-1">
                <DatePicker
                  id='CertifiedDtTm'
                  name='CertifiedDtTm'
                  ref={startRef1}
                  onKeyDown={onKeyDown}
                  onChange={(date) => { setChangesStatus(true); setValue({ ...value, ['CertifiedDtTm']: date ? getShowingMonthDateYear(date) : null }) }}
                  selected={value?.CertifiedDtTm && new Date(value?.CertifiedDtTm)}
                  className=''
                  dateFormat="MM/dd/yyyy HH:mm"
                  timeInputLabel
                  autoComplete="nope"
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                  isClearable={value?.CertifiedDtTm ? true : false}
                  placeholderText={value?.CertifiedDtTm ? value.CertifiedDtTm : 'Select...'}
                  showTimeSelect
                  timeIntervals={1}
                  timeCaption="Time"
                  maxDate={new Date()}
                  filterTime={filterPassedTime}
                />
              </div> */}
              <div className="col-12 col-md-12 col-lg-3 text-right" >
                <div className=" mt-1 text-md-right " >
                  <button type="button" ref={crossButtonRef} className="btn btn-sm btn-success  mr-1" onClick={() => {
                    setStatusFalse(); 
                  }}>New</button>
                  {/* <button type="button" className="btn btn-sm btn-success  mr-1" onClick={() => { check_Validation_Error(); }}> {masterNameID || nameID ? 'Update' : 'Save'}</button> */}
                  {/* {
                    nameID || masterNameID ?
                      effectiveScreenPermission ?
                        effectiveScreenPermission[0]?.Changeok ?
                          <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); }} disabled={isLoading || nameSearchStatus}>Update</button>
                          :
                          <>
                          </>
                        :
                        <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); }} disabled={isLoading || nameSearchStatus}>Update</button>
                      :
                      effectiveScreenPermission ?
                        effectiveScreenPermission[0]?.AddOK ?
                          <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); }} disabled={isLoading || nameSearchStatus}>Save</button>
                          :
                          <>
                          </>
                        :
                        <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); }} disabled={isLoading || nameSearchStatus}>Save</button>
                  } */}

                  {/* {
                    (masterNameID && MstPage === "MST-Name-Dash") || (nameID) ? (
                      effectiveScreenPermission ?
                        effectiveScreenPermission[0]?.Changeok ?
                          <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); }} disabled={isLoading || nameSearchStatus}>Update</button>
                          :
                          <>
                          </>
                        :
                        <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); }} disabled={isLoading || nameSearchStatus}>Update</button>
                    ) :
                      (
                        effectiveScreenPermission ?
                          effectiveScreenPermission[0]?.AddOK ?
                            <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); }} disabled={isLoading || nameSearchStatus}>Save</button>
                            :
                            <>
                            </>
                          :
                          <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); }} disabled={isLoading || nameSearchStatus}>Save</button>
                      )
                  } */}

                  {
                    (masterNameID && MstPage === "MST-Name-Dash") || (nameID) ? (
                      effectiveScreenPermission ?
                        effectiveScreenPermission[0]?.Changeok ?
                          <>
                            <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); setcalled(true) }} ref={saveButtonRef} disabled={isLoading || nameSearchStatus || !statesChangeStatus}>Update</button>
                            {/* {nameTypeCode !== "B" && (
                              <button type="button" data-toggle="modal" data-target="#SearchModal" className="btn btn-sm btn-success mr-1" onClick={() => getNameSearch(loginAgencyID, value?.NameTypeID, value.LastName, value.FirstName, value.MiddleName, value.DateOfBirth, value.SSN, value.HeightFrom, value.HeightTo, value.WeightFrom, value.WeightTo, value.EthnicityID, value.RaceID, value.SexID, value.PhoneTypeID, value.Contact, true)}>Search</button>
                            )} */}
                          </>
                          :
                          <>
                          </>
                        :
                        <>
                          <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); setcalled(true) }} ref={saveButtonRef} disabled={isLoading || nameSearchStatus || !statesChangeStatus}>Update</button>
                          {/* {nameTypeCode !== "B" && (
                            <button type="button" data-toggle="modal" data-target="#SearchModal" className="btn btn-sm btn-success mr-1" onClick={() => getNameSearch(loginAgencyID, value?.NameTypeID, value.LastName, value.FirstName, value.MiddleName, value.DateOfBirth, value.SSN, value.HeightFrom, value.HeightTo, value.WeightFrom, value.WeightTo, value.EthnicityID, value.RaceID, value.SexID, value.PhoneTypeID, value.Contact, true)}>Search</button>
                          )} */}
                        </>
                    ) :
                      (
                        effectiveScreenPermission ?
                          effectiveScreenPermission[0]?.AddOK ?
                            <>
                              <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); setcalled(true) }} disabled={isLoading || nameSearchStatus || saveValue} ref={saveButtonRef}>Save</button>
                              {nameTypeCode !== "B" && (
                                <button type="button" data-toggle="modal" data-target="#SearchModal" className="btn btn-sm btn-success mr-1" onClick={() => getNameSearch(loginAgencyID, value?.NameTypeID, value.LastName, value.FirstName, value.MiddleName, value.DateOfBirth, value.SSN, value.HeightFrom, value.HeightTo, value.WeightFrom, value.WeightTo, value.EthnicityID, value.RaceID, value.SexID, value.PhoneTypeID, value.Contact, true)}>Search</button>
                              )}
                            </>
                            :
                            <>
                            </>
                          :
                          <>
                            <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); setcalled(true) }} disabled={isLoading || nameSearchStatus || saveValue}>Save</button>
                            {/* {nameTypeCode !== "B" && (
                              <button type="button" data-toggle="modal" data-target="#SearchModal" className="btn btn-sm btn-success mr-1" onClick={() => getNameSearch(loginAgencyID, value?.NameTypeID, value.LastName, value.FirstName, value.MiddleName, value.DateOfBirth, value.SSN, value.HeightFrom, value.HeightTo, value.WeightFrom, value.WeightTo, value.EthnicityID, value.RaceID, value.SexID, value.PhoneTypeID, value.Contact, true)}>Search</button>
                            )} */}
                          </>
                      )
                  }




                  {
                    MstPage === "MST-Name-Dash" &&
                    <button type="button" className="btn btn-sm btn-success mx-1" ref={closeButtonRef} onClick={onMasterPropClose} data-dismiss="modal">Close</button>
                  }
                </div>
              </div>
            </div>
          </fieldset>
        </div>

        <div className="col-4 col-md-4 col-lg-1 pt-2 mb-1" >
          <div className="img-box" data-toggle="modal" data-target="#ImageModel">
            <Carousel autoPlay={true} className="carousel-style" showArrows={true} showThumbs={false} showStatus={false} >
              {
                nameMultiImg.length > 0 ?
                  nameMultiImg?.map((item) => (

                    <div key={item?.PhotoID ? item?.PhotoID : item?.imgID} onClick={() => { setImageModalStatus(true) }} data-toggle="modal" data-target="#ImageModel" className='model-img'>
                      <img src={`data:image/png;base64,${item.Photo}`} style={{ height: '90px' }} />
                    </div>
                  ))
                  :
                  <div data-toggle="modal" data-target="#ImageModel" onClick={() => { setImageModalStatus(true) }} >
                    <img src={defualtImage} alt='' />
                  </div>
              }
            </Carousel>
          </div>
        </div>
      </div>
      <div className="col-12  modal-table "  >
        {
          MstPage != "MST-Name-Dash" &&
          <DataTable
            dense
            columns={columns}
            // data={nameFilterData}
            data={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? nameFilterData : [] : nameFilterData}
            selectableRowsHighlight
            highlightOnHover
            responsive
            fixedHeader
            pagination
            paginationPerPage={'10'}
            paginationRowsPerPageOptions={[10, 15, 20, 50]}
            // showPaginationBottom={10}
            fixedHeaderScrollHeight='80px'
            customStyles={tableCustomStyles}
            conditionalRowStyles={conditionalRowStyles}
            onRowClicked={(row) => {
              setClickedRow(row);
              set_Edit_Value(row);
            }}
            persistTableHead={true}
            noDataComponent={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? "There are no data to display" : "You don’t have permission to view data" : 'There are no data to display'}
          />
        }
      </div>
      <ListModal {...{ openPage, setOpenPage }} />
      <NameSearchModal {...{ mainIncidentID, nameSearchValue,loginAgencyID, setValue, ResetSearch, setMultiSelected, value, setDobDate, get_Name_MultiImage, setIsModalActive, setUpdateStatus, updateStatus, MstPage }} />
      <DeletePopUpModal func={delete_Image_File} />
      <ChangesModal func={check_Validation_Error} />
      <VerifyLocation {...{ loginAgencyID, loginPinID, agencyName, modalStatus, setModalStatus, value, setStatesChangeStatus, setChangesStatus, setValue, addVerifySingleData, get_Add_Single_Data }} />
      <MasterNameModel {...{ value, setValue, nameModalStatus, setNameModalStatus, loginPinID, loginAgencyID, type, possessionID, setPossessionID, possenSinglData, setPossenSinglData, GetSingleDataPassion, setStatesChangeStatus }} />
      <IdentifyFieldColor />
      <ImageModel multiImage={nameMultiImg} pinID={loginPinID} setStatesChangeStatus={setStatesChangeStatus} primaryOfficerID={agencyOfficerDrpData} setMultiImage={setNameMultiImg} uploadImgFiles={uploadImgFiles} setuploadImgFiles={setuploadImgFiles} modalStatus={modalStatus} setModalStatus={setModalStatus} imageId={imageId} setImageId={setImageId} imageModalStatus={imageModalStatus} setImageModalStatus={setImageModalStatus} delete_Image_File={delete_Image_File} setImgData={setImgData} imgData={imgData} updateImage={update_Name_MultiImage} agencyID={loginAgencyID} />
      <AlertMasterModel masterID={masterNameID} setStatesChangeVich={setStatesChangeStatus} AlertType={"Name"} modelName={"Name"} loginPinID={loginPinID} agencyID={loginAgencyID} getAlertData={setAvailableAlert} />
    </>
  )
}

export default Home;

const Get_PhoneType_Code = (data, dropDownData) => {
  const result = data?.map((sponsor) => (sponsor.PhoneTypeID));
  const result2 = dropDownData?.map((sponsor) => {
    if (sponsor.value === result[0]) {
      return { value: result[0], label: sponsor.label, id: sponsor.id }
    }
  })
  const val = result2.filter(function (element) {
    return element !== undefined;
  });
  return val[0]?.id
};