import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { getShowingWithOutTime, tableCustomStyles } from '../../../../Common/Utility';
import { fetchPostData } from '../../../../hooks/Api';

const History = (props) => {

    const { DecNameID, DecMasterNameID, } = props

    const [HistoryData, setHistoryData] = useState([]);

    useEffect(() => {
        if (DecMasterNameID || DecNameID) {
            Get_HistoryData();
        }
    }, [DecMasterNameID, DecNameID]);

    const Get_HistoryData = () => {
        const val = {
            'MasterNameID': DecMasterNameID,
            // 'IsMaster': IsMaster
        }
        fetchPostData('MasterName/GetData_NameHistory', val).then((res) => {
            if (res) {

                setHistoryData(res)
            } else {
                setHistoryData();
            }
        })
    }

    const columns = [
        {
            width: '200px',
            name: 'Name',
            selector: (row) => row['FullName'],
            sortable: true
        },
        {
            width: '100px',
            name: 'DOB',
            selector: (row) => row['Date of Birth'] ? getShowingWithOutTime(row['Date of Birth']) : '',

            sortable: true
        },
        {
            width: '200px',
            name: 'Age From',
            selector: (row) => row['Age From'],
            sortable: true
        },
        {
            width: '200px',
            name: 'Age To',
            selector: (row) => row['Age To'],
            sortable: true
        },
        {
            width: '200px',
            name: 'Weight From',
            selector: (row) => row['Weight From'],
            sortable: true
        },
        {
            width: '200px',
            name: 'Weight To',
            selector: (row) => row['Weight To'],
            sortable: true
        },

        {
            width: '200px',
            name: 'Height From',
            selector: (row) => row['Height From'],
            sortable: true
        },
        {
            width: '200px',
            name: 'Height To',
            selector: (row) => row['Height To'],
            sortable: true
        },
        {
            width: '130px',
            name: 'Hair Color',
            selector: (row) => row['Hair Color'],
            sortable: true
        },
        {
            width: '130px',
            name: 'Eye Color',
            selector: (row) => row['Eye Color'],
            sortable: true
        },
    ]
    return (
        <>

            <div className="col-md-12 mt-2">
                <div className="col-12 mt-2">
                    <DataTable
                        dense
                        columns={columns}
                        data={HistoryData}
                        pagination
                        selectableRowsHighlight
                        highlightOnHover
                        responsive
                        showPaginationBottom={10}
                        customStyles={tableCustomStyles}
                        // onRowClicked={(row) => {
                        //     setClickedRow(row);
                        //     set_IncidentId(row);
                        // }}
                        fixedHeader
                        persistTableHead={true}
                        fixedHeaderScrollHeight='330px'
                    />
                </div>
            </div>
        </>
    )
}

export default History