import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { useQuery } from 'react-query';
import Select from "react-select";
import Dropdown from 'react-bootstrap/Dropdown';
import moment from 'moment';
import '../section.css';
import { coloredStyle_Select } from '../../Utility/CustomStylesForReact';
import { Filter, SendIcon } from '../../../Components/Filter/Filter';
import { getShowingWithOutTime, tableCustomStyles } from '../../../Components/Common/Utility';
import useObjState from '../../../CADHook/useObjState';
import {
  compareStrings,
  isEmpty
} from "../../../CADUtils/functions/common";
import { useSelector } from 'react-redux';
import MasterTableListServices from "../../../CADServices/APIs/masterTableList";
import ResourcesStatusServices from "../../../CADServices/APIs/resourcesStatus";
import { toastifySuccess } from '../../../Components/Common/AlertMsg';
import CADConfirmModal from '../../Common/CADConfirmModal';

const HospitalNameCodeSection = () => {
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const [filterTypeIdOption, setFilterTypeIdOption] = useState('Contains');
  const [pageStatus, setPageStatus] = useState("1");
  const [filterTypeDescOption, setFilterTypeDescOption] = useState('Contains');
  const [filterListData, setFilterListData] = useState([]);
  const [listData, setListData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isActive, setIsActive] = useState('');
  const [loginPinID, setLoginPinID] = useState('');
  const [activeInactiveData, setActiveInactiveData] = useState({})
  const [confirmType, setConfirmType] = useState('');
  const [searchValue1, setSearchValue1] = useState('');
  const [searchValue2, setSearchValue2] = useState('');
  const [hospitalStatusCodeOption, setHospitalStatusCodeOption] = useState([]);
  const [
    hospitalNameCodeState,
    setHospitalNameCodeState,
    handleHospitalNameCodeState,
    clearHospitalNameCodeState,
  ] = useObjState({
    HospitalNameCodeID: "",
    hospitalnamecode: "",
    hospitalname: "",
    hospitalstatuscode: [],
    statusexpirationdate: "",
  });

  const [
    errorHospitalNameCodeState,
    _setErrorHospitalStatusCodeState,
    handleErrorHospitalNameCodeState,
    clearErrorHospitalNameCodeState, ,
  ] = useObjState({
    hospitalnamecode: false,
    hospitalname: false,
    hospitalstatuscode: false,
  });

  useEffect(() => {
    if (localStoreData) {
      setLoginPinID(localStoreData?.PINID);
    }
  }, [localStoreData]);

  //Hospital Status Code Dropdown
  const getHospitalStatusCodeKey = `/CAD/MasterHospitalStatusCode/HospitalStatusCode`;
  const { data: getHospitalStatusCodeData, isSuccess: isFetchHospitalStatusCode } = useQuery(
    [getHospitalStatusCodeKey, { Action: "GET", isactive: 1 },],
    MasterTableListServices.getHospitalStatusCode,
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (getHospitalStatusCodeData && isFetchHospitalStatusCode) {
      const data = JSON.parse(getHospitalStatusCodeData?.data?.data);
      setHospitalStatusCodeOption(data?.Table)
    }
  }, [getHospitalStatusCodeData, isFetchHospitalStatusCode])


  // List Table
  const getHospitalNameCodeKey = `/CAD/MasterHospitalNamecode/InsertMasterHospitalNamecode`;
  const { data: hospitalNameCodeData, isSuccess: isFetchHospitalNameCode, refetch } = useQuery(
    [getHospitalNameCodeKey, { Action: "GET", isactive: parseInt(pageStatus) },],
    ResourcesStatusServices.getHospitalNameCode,
    {
      refetchOnWindowFocus: false,
      retry: 0,
    }
  );

  useEffect(() => {
    if (hospitalNameCodeData && isFetchHospitalNameCode) {
      const data = JSON.parse(hospitalNameCodeData?.data?.data);
      setFilterListData(data?.Table)
      setListData(data?.Table)
    } else {
      setFilterListData([])
      setListData([])
    }
  }, [hospitalNameCodeData, isFetchHospitalNameCode])

  async function handelActiveInactive() {
    const data = {
      HospitalNameCodeID: activeInactiveData?.HospitalNameCodeID,
      DeletedByUserFK: loginPinID,
      IsActive: isActive,
    }
    const response = await ResourcesStatusServices.changeStatusHospitalNameCode(data);
    if (response?.status === 200) {
      const data = JSON.parse(response?.data?.data)?.Table?.[0];
      toastifySuccess(data?.Message);
      refetch();
    }
    setShowModal(false);
    onCancel()

  }
  const validateNameCodeForm = () => {
    let isError = false;
    const keys = Object.keys(errorHospitalNameCodeState);
    keys.map((field) => {
      if (
        field === "hospitalnamecode" &&
        isEmpty(hospitalNameCodeState[field])) {
        handleErrorHospitalNameCodeState(field, true);
        isError = true;
      } else if (field === "hospitalname" && isEmpty(hospitalNameCodeState[field])) {
        handleErrorHospitalNameCodeState(field, true);
        isError = true;
      } else if (field === "hospitalstatuscode" && isEmpty(hospitalNameCodeState[field])) {
        handleErrorHospitalNameCodeState(field, true);
        isError = true;
      } else {
        handleErrorHospitalNameCodeState(field, false);
      }
    });
    return !isError;
  };
  async function handelSave() {
    if (!validateNameCodeForm()) return
    const isUpdate = !!hospitalNameCodeState?.HospitalNameCodeID;
    const data = {
      Action: isUpdate ? "UPDATE" : "ADD",
      HospitalNameCodeID: isUpdate ? hospitalNameCodeState?.HospitalNameCodeID : undefined,
      hospitalnamecode: hospitalNameCodeState?.hospitalnamecode,
      hospitalname: hospitalNameCodeState?.hospitalname,
      hospitalstatuscode: hospitalNameCodeState?.hospitalstatuscode,
      statusexpirationdate: hospitalNameCodeState?.statusexpirationdate ? moment(hospitalNameCodeState?.statusexpirationdate).format("DD/MM/YYYY") : "",
      isactive: 1

    }

    const response = await ResourcesStatusServices.insertHospitalNameCode(data);

    if (response?.status === 200) {
      toastifySuccess(isUpdate ? "Data Updated Successfully" : "Data Saved Successfully");
      onCancel()
      refetch();
    }
  }

  function onCancel() {
    clearHospitalNameCodeState();
    clearErrorHospitalNameCodeState();
  }

  function handelSetEditData(data) {
    setHospitalNameCodeState({
      HospitalNameCodeID: data?.HospitalNameCodeID || "",
      hospitalnamecode: data?.hospitalnamecode || "",
      hospitalname: data?.hospitalname || "",
      hospitalstatuscode: data?.hospitalstatuscode || "",
      statusexpirationdate: data?.statusexpirationdate ? moment(data?.statusexpirationdate).format("YYYY-MM-DD") : "",
      isActive: data?.isactive ? 1 : 0,
    })
  }

  const columns = [
    {
      name: 'Hospital Name Code',
      selector: row => row.hospitalnamecode,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.hospitalnamecode, rowB.hospitalnamecode),
      style: {
        position: "static",
      },
    },
    {
      name: 'Hospital Name',
      selector: row => row.hospitalname,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.hospitalname, rowB.hospitalname),
      width: "25%",
      style: {
        position: "static",
      },
    },
    {
      name: 'Hospital Status Code',
      selector: row => hospitalStatusCodeOption.find((i) => i?.HospitalStatusID === row.hospitalstatuscode)?.hospitalstatuscode,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.hospitalstatuscode, rowB.hospitalstatuscode),
      width: "25%",
      style: {
        position: "static",
      },
    },
    {
      name: 'Expire Date',
      selector: row => row.statusexpirationdate ? getShowingWithOutTime(row.statusexpirationdate) : "",
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.statusexpirationdate, rowB.statusexpirationdate),
      width: "20%",
      style: {
        position: "static",
      },
    },
    {
      name:
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          {'Status'}
        </div>,
      cell: (row, index) =>
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <span
            className="btn btn-sm text-white px-1 py-0 mr-1"
            style={{ background: "#ddd", cursor: "pointer" }}
          >
            {row?.isactive ? (
              <i className="fa fa-toggle-on" style={{ color: "green" }} aria-hidden="true" onClick={(e) => { setShowModal(true); setActiveInactiveData(row); setIsActive(0); setConfirmType("InActive") }}></i>
            ) : (
              <i className="fa fa-toggle-off" style={{ color: "red" }} aria-hidden="true" onClick={(e) => { setShowModal(true); setActiveInactiveData(row); setIsActive(1); setConfirmType("Active"); }}></i>
            )}
          </span>
        </div>,
      width: "70px",
      style: {
        position: "static",
      },
    },
  ];

  return (
    <>
      <div className='utilities-tab-content-main-container'>
        <div className='utilities-tab-content-form-container'>
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12 incident-tab ">
              <ul className="nav nav-tabs mb-1 pl-2 " id="myTab" role="tablist">
                <span className={`nav-item ${pageStatus === '1' ? 'active' : ''}`} onKeyDown={''} onClick={() => { setPageStatus("1"); setSearchValue1(""); setSearchValue2(""); onCancel() }} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true" style={{ color: pageStatus === '1' ? 'Red' : '' }}>Active</span>
                <span className={`nav-item ${pageStatus === '0' ? 'active' : ''}`} onKeyDown={''} onClick={() => { setPageStatus("0"); setSearchValue1(""); setSearchValue2(""); onCancel() }} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true" style={{ color: pageStatus === '0' ? 'Red' : '' }}>InActive</span>
              </ul>
            </div>
            {
              pageStatus === '1' ?
                <>
                  <div className='utilities-tab-content-form-main'>
                    {/* line 1 */}
                    <div className="row">
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Hospital Name Code {errorHospitalNameCodeState.hospitalnamecode && isEmpty(hospitalNameCodeState?.hospitalnamecode) && (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Enter Hospital Name Code"}</p>
                          )}
                        </label>
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <input
                          name="hospitalnamecode"
                          type="text"
                          className="form-control py-1 new-input requiredColor"
                          placeholder='Hospital Name Code'
                          value={hospitalNameCodeState?.hospitalnamecode}
                          onChange={(e) => handleHospitalNameCodeState("hospitalnamecode", e.target.value)}
                        />
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Hospital Name {errorHospitalNameCodeState.hospitalname && isEmpty(hospitalNameCodeState?.hospitalname) && (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Enter Hospital Name"}</p>
                          )}
                        </label>
                      </div>
                      <div className="col-4 d-flex align-self-center justify-content-end">
                        <input
                          name="hospitalname"
                          type="text"
                          className="form-control py-1 new-input requiredColor"
                          placeholder='Hospital Name'
                          value={hospitalNameCodeState?.hospitalname}
                          onChange={(e) => handleHospitalNameCodeState("hospitalname", e.target.value)}

                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Hospital Status Code {errorHospitalNameCodeState.hospitalstatuscode && isEmpty(hospitalNameCodeState?.hospitalstatuscode) && (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select Hospital Status Code"}</p>
                          )}
                        </label>
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <Select
                          styles={coloredStyle_Select}
                          placeholder="Select"
                          isSearchable
                          options={hospitalStatusCodeOption}
                          getOptionLabel={(i) => i.hospitalstatuscode}
                          getOptionValue={(i) => i.HospitalStatusID}
                          // value={hospitalNameCodeState?.HospitalStatusID}
                          value={
                            !isEmpty(hospitalNameCodeState?.hospitalstatuscode)
                              ? hospitalStatusCodeOption?.find((i) => i?.HospitalStatusID === hospitalNameCodeState?.hospitalstatuscode)
                              : []
                          }
                          onChange={(e) => { handleHospitalNameCodeState("hospitalstatuscode", e?.HospitalStatusID) }}
                        />
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Status Expiration Date
                        </label>
                      </div>
                      <div className="col-4 d-flex align-self-center justify-content-end">
                        <input
                          type="date"
                          className="form-control py-1 new-input"
                          placeholder="placeholder"
                          value={hospitalNameCodeState?.statusexpirationdate}
                          onChange={(e) => { handleHospitalNameCodeState("statusexpirationdate", e.target.value) }}
                          min={new Date().toISOString().split("T")[0]}
                        />
                      </div>
                    </div>
                  </div>
                </>
                :
                <>
                </>
            }
          </div>
        </div>
        <div className='utilities-tab-content-table-container'>
          <div className="row mb-2">
            <div className="col-5 d-flex align-self-center justify-content-end">
              <input
                type="text"
                className="form-control py-1 new-input"
                placeholder='Search By Hospital Name Code...'
                value={searchValue1}
                onChange={(e) => {
                  setSearchValue1(e.target.value);
                  const result = Filter(listData, e.target.value, searchValue2, filterTypeIdOption, 'hospitalnamecode', 'hospitalname')
                  setFilterListData(result)
                }}
              />
            </div>
            <div className="col-1 d-flex align-self-center justify-content-end" style={{ zIndex: '2', }}>
              <Dropdown className='w-100'>
                <Dropdown.Toggle id="dropdown-basic" className='cad-sort-dropdown'>
                  <img src={SendIcon(filterTypeIdOption)} alt="" className='filter-icon mr-1' />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('Contains')}>Contains</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('is equal to')}>is equal to</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('is not equal to')}>is not equal to </Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('Starts With')}>Starts With</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('End with')}>End with</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-5 d-flex align-self-center justify-content-end">
              <input
                type="text"
                className="form-control py-1 new-input"
                placeholder='Search By Hospital Name...'
                value={searchValue2}
                onChange={(e) => {
                  setSearchValue2(e.target.value);
                  const result = Filter(listData, searchValue1, e.target.value, filterTypeDescOption, 'hospitalnamecode', 'hospitalname')
                  setFilterListData(result)
                }}
              />
            </div>

            <div className="col-1 d-flex align-self-center justify-content-end" style={{ zIndex: '1', }}>
              <Dropdown className='w-100'>
                <Dropdown.Toggle id="dropdown-basic" className='cad-sort-dropdown'>
                  <img src={SendIcon(filterTypeDescOption)} alt="" className='filter-icon mr-1' />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('Contains')}>Contains</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('is equal to')}>is equal to</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('is not equal to')}>is not equal to </Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('Starts With')}>Starts With</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('End with')}>End with</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div className="table-responsive">
            <DataTable
              dense
              columns={columns}
              data={filterListData}
              customStyles={tableCustomStyles}
              pagination
              responsive
              striped
              highlightOnHover
              fixedHeader
              onRowClicked={(row) => {
                handelSetEditData(row);
              }}
            />
          </div>
          <div className="utilities-tab-content-button-container bb pb-2" >
            <button type="button" className="btn btn-sm btn-success" onClick={() => onCancel()}>Cancel</button>
            <button type="button" className="btn btn-sm btn-success" onClick={() => handelSave()}>Save</button>
          </div>
        </div>
      </div>
      <CADConfirmModal showModal={showModal} setShowModal={setShowModal} handleConfirm={handelActiveInactive} confirmType={confirmType} />
    </>
  );
};

export default HospitalNameCodeSection;